import React from 'react';
import { Container, Box } from '@mui/material';
import MuMapImage from "../../images/mu-maps.jpg"

const style = {
  MuMap: {
    backgroundImage: `url(${MuMapImage})`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
};

const Home = () => {
  return (
    <Container>
      <Box style={style.MuMap} sx={{ height: 450 }}></Box>
    </Container>
  );
}

export default Home