import React from 'react';
import { CssBaseline, Container, Typography, Stack, Box, Paper } from '@mui/material';
import MuChars from "../../images/mu-chars.jpg"
import MgBg from "../../images/mg-bg.jpg"

const style = {
  MuChars: {
    backgroundImage: `url(${MuChars})`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  BoxBg: {
    backgroundImage: `url(${MgBg})`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
};

const Home = () => {
  return (
    <>
      <Container style={{ padding: 0 }}>
        <CssBaseline />
        <Box style={style.MuChars} sx={{ height: 350 }}>
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={2}
            sx={{ height: 350 }}
          >
            <Paper sx={{ backgroundColor: 'rgba(0,0,0,0.7)' }}>
              <Typography
                variant="h3"
                align='center'
              >
                O seu app de informações sobre Mu Online!
              </Typography>
            </Paper>
          </Stack>
        </Box>
        <Box p={2}>
          <Paper sx={{ backgroundColor: 'rgba(0,0,0,0.7)' }}>
            <Typography
              variant="h5"
              align='center'
            >
              Estamos desenvolvendo um lugar para conteúdos, dicas e ferramentas para te auxiliar em sua gameplay.
            </Typography>
          </Paper>
        </Box>
        <Box style={style.BoxBg} sx={{ height: 350 }}>

        </Box>
      </Container>
    </>
  );
}

export default Home