import React from "react"

import MuMap from '.';
import imageMap from '../../images/Mu/Data/Interface/GFx/NaviMap/Navimap134.png';

import hsDark from '../../images/markers/hs-dark.png';
import hsFire from '../../images/markers/hs-fire.png';
import hsWater from '../../images/markers/hs-water.png';
import hsWind from '../../images/markers/hs-wind.png';
import hsEarth from '../../images/markers/hs-earth.png';

import subDark from '../../images/markers/sub-dark.png';
import subEarth from '../../images/markers/sub-earth.png';
import subFire from '../../images/markers/sub-fire.png';
import subWater from '../../images/markers/sub-water.png';
import subWind from '../../images/markers/sub-wind.png';

import spotDark from '../../images/markers/spot-dark.png';
import spotEarth from '../../images/markers/spot-earth.png';
import spotFire from '../../images/markers/spot-fire.png';
import spotWater from '../../images/markers/spot-water.png';
import spotWind from '../../images/markers/spot-wind.png';

const mapConfigs = {
  name: "Temple of Arnil",
  url: imageMap,
  markers: [
    {
      imageSrc: spotWater,
      coordinates: [
        { x: 91, y: 120, quantityMobs: 6, mobLevels:{min: 0}, name: 'Temple Ogre / Temple Gargoyle / Temple Gremlin' },
        { x: 125, y: 120, quantityMobs: 5, mobLevels:{min: 0}, name: 'Temple Ogre / Temple Gargoyle' },
        { x: 148, y: 120, quantityMobs: 6, mobLevels:{min: 0}, name: 'Temple Ogre / Temple Gargoyle' },
      ]
    },
    {
      imageSrc: subWater,
      coordinates: [
        { x: 194, y: 113, quantityMobs: 7, mobLevels:{min: 0}, name: 'Temple Ogre / Temple Gargoyle' },
      ]
    },
    {
      imageSrc: hsWater,
      coordinates: [
        { x: 167, y: 152, quantityMobs: 8, mobLevels:{min: 0}, name: 'Temple Ogre / Temple Gargoyle / Temple Gremlin' },
        { x: 35, y: 133, quantityMobs: 9, mobLevels:{min: 0}, name: 'Temple Ogre / Temple Gargoyle / Temple Gremlin' },
        { x: 213, y: 132, quantityMobs: 9, mobLevels:{min: 0}, name: 'Temple Ogre / Temple Gargoyle / Temple Gremlin' },
      ]
    },
    {
      imageSrc: spotFire,
      coordinates: [
        { x: 93, y: 175, quantityMobs: 5, mobLevels:{min: 0}, name: 'Temple Ogre / Temple Gargoyle' },
        { x: 57, y: 229, quantityMobs: 6, mobLevels:{min: 0}, name: 'Temple Ogre / Temple Gargoyle' },
      ]
    },
    {
      imageSrc: subFire,
      coordinates: [
        { x: 89, y: 229, quantityMobs: 7, mobLevels:{min: 0}, name: 'Temple Ogre / Temple Gargoyle / Temple Gremlin' },
        { x: 35, y: 228, quantityMobs: 7, mobLevels:{min: 0}, name: 'Temple Ogre / Temple Gargoyle / Temple Gremlin' },
      ]
    },
    {
      imageSrc: hsFire,
      coordinates: [
        { x: 26, y: 187, quantityMobs: 8, mobLevels:{min: 0}, name: 'Temple Ogre / Temple Gargoyle / Temple Gremlin' },
        { x: 50, y: 160, quantityMobs: 8, mobLevels:{min: 0}, name: 'Temple Ogre / Temple Gargoyle / Temple Gremlin' },
        { x: 106, y: 156, quantityMobs: 8, mobLevels:{min: 0}, name: 'Temple Ogre / Temple Gargoyle / Temple Gremlin' },
      ]
    },
    {
      imageSrc: spotWind,
      coordinates: [
        { x: 159, y: 174, quantityMobs: 5, mobLevels:{min: 0}, name: 'Temple Ogre / Temple Gargoyle' },
        { x: 182, y: 230, quantityMobs: 5, mobLevels:{min: 0}, name: 'Temple Ogre / Temple Gargoyle' },
      ]
    },
    {
      imageSrc: subWind,
      coordinates: [
        { x: 172, y: 197, quantityMobs: 7, mobLevels:{min: 0}, name: 'Temple Ogre / Temple Gargoyle / Temple Gremlin' },
      ]
    },
    {
      imageSrc: hsWind,
      coordinates: [
        { x: 195, y: 155, quantityMobs: 8, mobLevels:{min: 0}, name: 'Temple Ogre / Temple Gargoyle / Temple Gremlin' },
        { x: 236, y: 187, quantityMobs: 9, mobLevels:{min: 0}, name: 'Temple Ogre / Temple Gargoyle / Temple Gremlin' },
        { x: 215, y: 231, quantityMobs: 8, mobLevels:{min: 0}, name: 'Temple Ogre / Temple Gargoyle / Temple Gremlin' },
        { x: 127, y: 220, quantityMobs: 9, mobLevels:{min: 0}, name: 'Temple Ogre / Temple Gargoyle / Temple Gremlin' },
      ]
    },
    {
      imageSrc: spotDark,
      coordinates: [
        { x: 100, y: 60, quantityMobs: 5, mobLevels:{min: 0}, name: 'Temple Ogre / Temple Gargoyle' },
        { x: 99, y: 17, quantityMobs: 6, mobLevels:{min: 0}, name: 'Temple Ogre / Temple Gargoyle' },
        { x: 66, y: 16, quantityMobs: 6, mobLevels:{min: 0}, name: 'Temple Ogre / Temple Gargoyle' },
      ]
    },
    {
      imageSrc: subDark,
      coordinates: [
        { x: 59, y: 57, quantityMobs: 7, mobLevels:{min: 0}, name: 'Temple Ogre / Temple Gargoyle / Temple Gremlin' },
      ]
    },
    {
      imageSrc: hsDark,
      coordinates: [
        { x: 27, y: 70, quantityMobs: 9, mobLevels:{min: 0}, name: 'Temple Ogre / Temple Gargoyle / Temple Gremlin' },
        { x: 57, y: 79, quantityMobs: 8, mobLevels:{min: 0}, name: 'Temple Ogre / Temple Gargoyle / Temple Gremlin' },
        { x: 114, y: 83, quantityMobs: 10, mobLevels:{min: 0}, name: 'Temple Ogre / Temple Gargoyle / Temple Gremlin' },
      ]
    },
    {
      imageSrc: spotEarth,
      coordinates: [
        { x: 168, y: 51, quantityMobs: 6, mobLevels:{min: 0}, name: 'Temple Ogre / Temple Gargoyle' },
        { x: 200, y: 15, quantityMobs: 6, mobLevels:{min: 0}, name: 'Temple Ogre / Temple Gargoyle' },
      ]
    },
    {
      imageSrc: subEarth,
      coordinates: [
        { x: 174, y: 13, quantityMobs: 7, mobLevels:{min: 0}, name: 'Temple Ogre / Temple Gargoyle / Temple Gremlin' },
        { x: 231, y: 93, quantityMobs: 7, mobLevels:{min: 0}, name: 'Temple Ogre / Temple Gargoyle / Temple Gremlin' },
      ]
    },
    {
      imageSrc: hsEarth,
      coordinates: [
        { x: 231, y: 18, quantityMobs: 8, mobLevels:{min: 0}, name: 'Temple Ogre / Temple Gargoyle / Temple Gremlin' },
        { x: 239, y: 45, quantityMobs: 9, mobLevels:{min: 0}, name: 'Temple Ogre / Temple Gargoyle / Temple Gremlin' },
        { x: 161, y: 92, quantityMobs: 9, mobLevels:{min: 0}, name: 'Temple Ogre / Temple Gargoyle / Temple Gremlin' },
      ]
    },
  ],
}

const TempleOfArnil = () => <MuMap {...mapConfigs} />

export default TempleOfArnil