import React from "react"

import MuMap from '.';
import imageMap from '../../images/Mu/Data/Interface/GFx/NaviMap/Navimap132.png';

import hsDark from '../../images/markers/hs-dark.png';
import hsFire from '../../images/markers/hs-fire.png';
import hsWater from '../../images/markers/hs-water.png';
import hsWind from '../../images/markers/hs-wind.png';
import hsEarth from '../../images/markers/hs-earth.png';

import subDark from '../../images/markers/sub-dark.png';
import subEarth from '../../images/markers/sub-earth.png';
import subFire from '../../images/markers/sub-fire.png';
import subWater from '../../images/markers/sub-water.png';
import subWind from '../../images/markers/sub-wind.png';

import spotDark from '../../images/markers/spot-dark.png';
import spotEarth from '../../images/markers/spot-earth.png';
import spotFire from '../../images/markers/spot-fire.png';
import spotWater from '../../images/markers/spot-water.png';
import spotWind from '../../images/markers/spot-wind.png';

const mapConfigs = {
  name: "Scorched Canyon",
  url: imageMap,
  markers: [
    {
      imageSrc: spotWater,
      coordinates: [
        { x: 178, y: 24, quantityMobs: 5, mobLevels:{min: 0}, name: 'Scorched Warrior / Scorched Wizard' },
        { x: 157, y: 34, quantityMobs: 6, mobLevels:{min: 0}, name: 'Scorched Warrior / Scorched Wizard' },
        { x: 134, y: 51, quantityMobs: 6, mobLevels:{min: 0}, name: 'Scorched Warrior / Scorched Wizard' },
        { x: 128, y: 13, quantityMobs: 5, mobLevels:{min: 0}, name: 'Scorched Warrior / Scorched Wizard' },
        { x: 106, y: 13, quantityMobs: 6, mobLevels:{min: 0}, name: 'Scorched Warrior / Scorched Wizard' },
        { x: 90, y: 38, quantityMobs: 6, mobLevels:{min: 0}, name: 'Scorched Assassin / Scorched Wizard' },
      ]
    },
    {
      imageSrc: subWater,
      coordinates: [
        { x: 112, y: 57, quantityMobs: 8, mobLevels:{min: 0}, name: 'Scorched Warrior / Scorched Wizard' },
        { x: 75, y: 45, quantityMobs: 7, mobLevels:{min: 0}, name: 'Scorched Assassin / Scorched Wizard' },
        { x: 84, y: 14, quantityMobs: 7, mobLevels:{min: 0}, name: 'Scorched Assassin / Scorched Wizard' },
        { x: 68, y: 14, quantityMobs: 7, mobLevels:{min: 0}, name: 'Scorched Assassin / Scorched Wizard' },
      ]
    },
    {
      imageSrc: hsWater,
      coordinates: [
        { x: 27, y: 46, quantityMobs: 9, mobLevels:{min: 0}, name: 'Scorched Warrior / Scorched Wizard' },
        { x: 38, y: 17, quantityMobs: 9, mobLevels:{min: 0}, name: 'Scorched Assassin / Scorched Wizard' },
      ]
    },
    {
      imageSrc: spotFire,
      coordinates: [
        { x: 142, y: 170, quantityMobs: 5, mobLevels:{min: 0}, name: 'Scorched Warrior / Scorched Wizard' },
        { x: 155, y: 189, quantityMobs: 5, mobLevels:{min: 0}, name: 'Scorched Warrior / Scorched Wizard' },
        { x: 147, y: 228, quantityMobs: 6, mobLevels:{min: 0}, name: 'Scorched Warrior / Scorched Wizard' },
        { x: 174, y: 235, quantityMobs: 6, mobLevels:{min: 0}, name: 'Scorched Warrior / Scorched Wizard' },
        { x: 217, y: 204, quantityMobs: 6, mobLevels:{min: 0}, name: 'Scorched Assassin / Scorched Wizard' },
        { x: 200, y: 163, quantityMobs: 6, mobLevels:{min: 0}, name: 'Scorched Assassin / Scorched Wizard' },
      ]
    },
    {
      imageSrc: subFire,
      coordinates: [
        { x: 156, y: 148, quantityMobs: 7, mobLevels:{min: 0}, name: 'Scorched Warrior / Scorched Wizard' },
        { x: 187, y: 192, quantityMobs: 8, mobLevels:{min: 0}, name: 'Scorched Warrior / Scorched Wizard' },
        { x: 194, y: 225, quantityMobs: 8, mobLevels:{min: 0}, name: 'Scorched Assassin / Scorched Wizard' },
        { x: 231, y: 181, quantityMobs: 7, mobLevels:{min: 0}, name: 'Scorched Warrior / Scorched Wizard' },
      ]
    },
    {
      imageSrc: hsFire,
      coordinates: [
        { x: 215, y: 229, quantityMobs: 9, mobLevels:{min: 0}, name: 'Scorched Assassin / Scorched Wizard' },
        { x: 240, y: 202, quantityMobs: 9, mobLevels:{min: 0}, name: 'Scorched Assassin / Scorched Wizard' },
      ]
    },
    {
      imageSrc: spotWind,
      coordinates: [
        { x: 69, y: 62, quantityMobs: 6, mobLevels:{min: 0}, name: 'Scorched Warrior / Scorched Wizard' },
        { x: 94, y: 159, quantityMobs: 6, mobLevels:{min: 0}, name: 'Scorched Warrior / Scorched Wizard' },
        { x: 125, y: 97, quantityMobs: 5, mobLevels:{min: 0}, name: 'Scorched Warrior / Scorched Wizard' },
        { x: 99, y: 123, quantityMobs: 5, mobLevels:{min: 0}, name: 'Scorched Warrior / Scorched Wizard' },
        { x: 37, y: 134, quantityMobs: 6, mobLevels:{min: 0}, name: 'Scorched Assassin / Scorched Wizard' },
        { x: 55, y: 155, quantityMobs: 6, mobLevels:{min: 0}, name: 'Scorched Assassin / Scorched Wizard' },
        { x: 104, y: 102, quantityMobs: 6, mobLevels:{min: 0}, name: 'Scorched Warrior / Scorched Wizard' },
      ]
    },
    {
      imageSrc: subWind,
      coordinates: [
        { x: 46, y: 78, quantityMobs: 7, mobLevels:{min: 0}, name: 'Scorched Assassin / Scorched Wizard' },
        { x: 15, y: 133, quantityMobs: 8, mobLevels:{min: 0}, name: 'Scorched Warrior / Scorched Wizard / Scorched Assassin' },
        { x: 75, y: 124, quantityMobs: 7, mobLevels:{min: 0}, name: 'Scorched Assassin / Scorched Wizard' },
      ]
    },
    {
      imageSrc: hsWind,
      coordinates: [
        { x: 128, y: 133, quantityMobs: 9, mobLevels:{min: 0}, name: 'Scorched Warrior / Scorched Wizard' },
        { x: 23, y: 96, quantityMobs: 9, mobLevels:{min: 0}, name: 'Scorched Assassin / Scorched Wizard' },
      ]
    },
    {
      imageSrc: spotDark,
      coordinates: [
        { x: 125, y: 196, quantityMobs: 6, mobLevels:{min: 0}, name: 'Scorched Warrior / Scorched Wizard' },
        { x: 127, y: 232, quantityMobs: 6, mobLevels:{min: 0}, name: 'Scorched Warrior / Scorched Wizard' },
        { x: 101, y: 231, quantityMobs: 6, mobLevels:{min: 0}, name: 'Scorched Warrior / Scorched Wizard' },
        { x: 104, y: 198, quantityMobs: 5, mobLevels:{min: 0}, name: 'Scorched Warrior / Scorched Wizard' },
        { x: 115, y: 178, quantityMobs: 4, mobLevels:{min: 0}, name: 'Scorched Warrior / Scorched Wizard' },
        { x: 61, y: 235, quantityMobs: 6, mobLevels:{min: 0}, name: 'Scorched Assassin / Scorched Wizard' },
        { x: 38, y: 215, quantityMobs: 6, mobLevels:{min: 0}, name: 'Scorched Assassin / Scorched Wizard' },
        { x: 81, y: 175, quantityMobs: 6, mobLevels:{min: 0}, name: 'Scorched Assassin / Scorched Wizard' },
      ]
    },
    {
      imageSrc: subDark,
      coordinates: [
        { x: 31, y: 177, quantityMobs: 7, mobLevels:{min: 0}, name: 'Scorched Assassin / Scorched Wizard' },
        { x: 50, y: 182, quantityMobs: 7, mobLevels:{min: 0}, name: 'Scorched Assassin / Scorched Wizard' },
      ]
    },
    {
      imageSrc: hsDark,
      coordinates: [
        { x: 78, y: 216, quantityMobs: 9, mobLevels:{min: 0}, name: 'Scorched Warrior / Scorched Wizard' },
        { x: 17, y: 221, quantityMobs: 9, mobLevels:{min: 0}, name: 'Scorched Warrior / Scorched Wizard / Scorched Assassin' },
      ]
    },
    {
      imageSrc: spotEarth,
      coordinates: [
        { x: 180, y: 83, quantityMobs: 5, mobLevels:{min: 0}, name: 'Scorched Warrior / Scorched Wizard' },
        { x: 238, y: 69, quantityMobs: 5, mobLevels:{min: 0}, name: 'Scorched Warrior / Scorched Wizard' },
        { x: 222, y: 62, quantityMobs: 6, mobLevels:{min: 0}, name: 'Scorched Warrior / Scorched Wizard' },
        { x: 194, y: 73, quantityMobs: 6, mobLevels:{min: 0}, name: 'Scorched Warrior / Scorched Wizard' },
        { x: 169, y: 113, quantityMobs: 6, mobLevels:{min: 0}, name: 'Scorched Assassin / Scorched Wizard' },
        { x: 174, y: 133, quantityMobs: 5, mobLevels:{min: 0}, name: 'Scorched Assassin / Scorched Wizard' },
      ]
    },
    {
      imageSrc: subEarth,
      coordinates: [
        { x: 203, y: 111, quantityMobs: 7, mobLevels:{min: 0}, name: 'Scorched Assassin / Scorched Wizard' },
        { x: 213, y: 93, quantityMobs: 7, mobLevels:{min: 0}, name: 'Scorched Warrior / Scorched Wizard' },
        { x: 228, y: 131, quantityMobs: 7, mobLevels:{min: 0}, name: 'Scorched Assassin / Scorched Wizard' },
        { x: 203, y: 147, quantityMobs: 8, mobLevels:{min: 0}, name: 'Scorched Assassin / Scorched Wizard' },
      ]
    },
    {
      imageSrc: hsEarth,
      coordinates: [
        { x: 238, y: 109, quantityMobs: 9, mobLevels:{min: 0}, name: 'Scorched Warrior / Scorched Wizard' },
        { x: 230, y: 152, quantityMobs: 9, mobLevels:{min: 0}, name: 'Scorched Warrior / Scorched Wizard / Scorched Assassin' },
      ]
    },
  ],
}

const ScorchedCanyon = () => <MuMap {...mapConfigs} />

export default ScorchedCanyon