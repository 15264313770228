import React from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import {
  FormControl,
  InputLabel,
  Select as MUISelect,
  MenuItem,
} from "@mui/material";


const Select = ({ label, options, fullWidth, ...props }) => {
  const [field] = useField(props);

  return (
    <FormControl fullWidth={fullWidth}>
      <InputLabel id={`select-bar-${label}`}>{label}</InputLabel>
      <MUISelect
        label={label}
        labelId={`select-bar-${label}`}
        {...field}
        {...props}
      >
        {options.map(({ name, value }) => <MenuItem key={name} value={value}>{name}</MenuItem>)}
      </MUISelect>
    </FormControl>
  );
};

Select.propTypes = {
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.any.isRequired,
  })).isRequired,
  fullWidth: PropTypes.bool,
  props: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }),
};

export default Select
