import React from "react"

import MuMap from '.';
import imageMap from '../../images/Mu/Data/Interface/GFx/NaviMap/Navimap130.png';

import hsDark from '../../images/markers/hs-dark.png';
import hsFire from '../../images/markers/hs-fire.png';
import hsWater from '../../images/markers/hs-water.png';
import hsWind from '../../images/markers/hs-wind.png';
import hsEarth from '../../images/markers/hs-earth.png';

import subDark from '../../images/markers/sub-dark.png';
import subEarth from '../../images/markers/sub-earth.png';
import subFire from '../../images/markers/sub-fire.png';
import subWater from '../../images/markers/sub-water.png';
import subWind from '../../images/markers/sub-wind.png';

import spotDark from '../../images/markers/spot-dark.png';
import spotEarth from '../../images/markers/spot-earth.png';
import spotFire from '../../images/markers/spot-fire.png';
import spotWater from '../../images/markers/spot-water.png';
import spotWind from '../../images/markers/spot-wind.png';

const mapConfigs = {
  name: "Atlans Abyss 2",
  url: imageMap,
  markers: [
    {
      imageSrc: spotWater,
      coordinates: [
        { x: 67, y: 106, quantityMobs: 5, mobLevels:{min: 435}, name: 'Large Bahamut of Abyss / Silver Valkyrie of Abyss' },
        { x: 100, y: 121, quantityMobs: 5, mobLevels:{min: 435}, name: 'Large Bahamut of Abyss / Silver Valkyrie of Abyss' },
        { x: 56, y: 80, quantityMobs: 5, mobLevels:{min: 435}, name: 'Large Bahamut of Abyss / Silver Valkyrie of Abyss' },
        { x: 102, y: 96, quantityMobs: 5, mobLevels:{min: 435}, name: 'Large Bahamut of Abyss / Silver Valkyrie of Abyss' },
        { x: 110, y: 21, quantityMobs: 5, mobLevels:{min: 435}, name: 'Large Bahamut of Abyss / Silver Valkyrie of Abyss' },
      ]
    },
    {
      imageSrc: subWater,
      coordinates: [
        { x: 28, y: 92, quantityMobs: 6, mobLevels:{min: 435}, name: 'Large Bahamut of Abyss / Silver Valkyrie of Abyss' },
      ]
    },
    {
      imageSrc: hsWater,
      coordinates: [
        { x: 38, y: 72, quantityMobs: 9, mobLevels:{min: 435}, name: 'Large Bahamut of Abyss / Silver Valkyrie of Abyss' },
      ]
    },
    {
      imageSrc: spotFire,
      coordinates: [
        { x: 182, y: 215, quantityMobs: 5, mobLevels:{min: 435}, name: 'Large Bahamut of Abyss / Silver Valkyrie of Abyss' },
        { x: 161, y: 140, quantityMobs: 5, mobLevels:{min: 435}, name: 'Large Bahamut of Abyss / Silver Valkyrie of Abyss' },
        { x: 165, y: 110, quantityMobs: 5, mobLevels:{min: 435}, name: 'Large Bahamut of Abyss / Silver Valkyrie of Abyss' },
        { x: 188, y: 121, quantityMobs: 5, mobLevels:{min: 435}, name: 'Large Bahamut of Abyss / Silver Valkyrie of Abyss' },
        { x: 211, y: 138, quantityMobs: 5, mobLevels:{min: 435}, name: 'Large Bahamut of Abyss / Silver Valkyrie of Abyss' },
      ]
    },
    {
      imageSrc: subFire,
      coordinates: [
        { x: 231, y: 104, quantityMobs: 6, mobLevels:{min: 435}, name: 'Large Bahamut of Abyss / Silver Valkyrie of Abyss' },
      ]
    },
    {
      imageSrc: hsFire,
      coordinates: [
        { x: 229, y: 130, quantityMobs: 9, mobLevels:{min: 435}, name: 'Large Bahamut of Abyss / Silver Valkyrie of Abyss' },
      ]
    },
    {
      imageSrc: spotWind,
      coordinates: [
        { x: 115, y: 88, quantityMobs: 5, mobLevels:{min: 435}, name: 'Large Bahamut of Abyss / Silver Valkyrie of Abyss' },
        { x: 132, y: 65, quantityMobs: 5, mobLevels:{min: 435}, name: 'Large Bahamut of Abyss / Silver Valkyrie of Abyss' },
        { x: 152, y: 90, quantityMobs: 5, mobLevels:{min: 435}, name: 'Large Bahamut of Abyss / Silver Valkyrie of Abyss' },
        { x: 164, y: 51, quantityMobs: 5, mobLevels:{min: 435}, name: 'Large Bahamut of Abyss / Silver Valkyrie of Abyss' },
        { x: 206, y: 47, quantityMobs: 5, mobLevels:{min: 435}, name: 'Large Bahamut of Abyss / Silver Valkyrie of Abyss' },
        { x: 226, y: 78, quantityMobs: 5, mobLevels:{min: 435}, name: 'Large Bahamut of Abyss / Silver Valkyrie of Abyss' },
      ]
    },
    {
      imageSrc: subWind,
      coordinates: [
        { x: 137, y: 25, quantityMobs: 6, mobLevels:{min: 435}, name: 'Large Bahamut of Abyss / Silver Valkyrie of Abyss' },
      ]
    },
    {
      imageSrc: hsWind,
      coordinates: [
        { x: 167, y: 27, quantityMobs: 9, mobLevels:{min: 435}, name: 'Large Bahamut of Abyss / Silver Valkyrie of Abyss' },
      ]
    },
    {
      imageSrc: spotDark,
      coordinates: [
        { x: 52, y: 176, quantityMobs: 5, mobLevels:{min: 435}, name: 'Large Bahamut of Abyss / Silver Valkyrie of Abyss' },
        { x: 82, y: 178, quantityMobs: 5, mobLevels:{min: 435}, name: 'Large Bahamut of Abyss / Silver Valkyrie of Abyss' },
        { x: 25, y: 115, quantityMobs: 5, mobLevels:{min: 435}, name: 'Large Bahamut of Abyss / Silver Valkyrie of Abyss' },
        { x: 92, y: 141, quantityMobs: 5, mobLevels:{min: 435}, name: 'Large Bahamut of Abyss / Silver Valkyrie of Abyss' },
        { x: 112, y: 160, quantityMobs: 5, mobLevels:{min: 435}, name: 'Large Bahamut of Abyss / Silver Valkyrie of Abyss' },
      ]
    },
    {
      imageSrc: subDark,
      coordinates: [
        { x: 50, y: 208, quantityMobs: 9, mobLevels:{min: 435}, name: 'Large Bahamut of Abyss / Silver Valkyrie of Abyss' },
      ]
    },
    {
      imageSrc: hsDark,
      coordinates: [
        { x: 37, y: 189, quantityMobs: 9, mobLevels:{min: 435}, name: 'Large Bahamut of Abyss / Silver Valkyrie of Abyss' },
      ]
    },
    {
      imageSrc: spotEarth,
      coordinates: [
        { x: 69, y: 219, quantityMobs: 5, mobLevels:{min: 435}, name: 'Large Bahamut of Abyss / Silver Valkyrie of Abyss' },
        { x: 139, y: 210, quantityMobs: 5, mobLevels:{min: 435}, name: 'Large Bahamut of Abyss / Silver Valkyrie of Abyss' },
        { x: 124, y: 169, quantityMobs: 5, mobLevels:{min: 435}, name: 'Large Bahamut of Abyss / Silver Valkyrie of Abyss' },
        { x: 156, y: 185, quantityMobs: 5, mobLevels:{min: 435}, name: 'Large Bahamut of Abyss / Silver Valkyrie of Abyss' },
        { x: 149, y: 151, quantityMobs: 5, mobLevels:{min: 435}, name: 'Large Bahamut of Abyss / Silver Valkyrie of Abyss' },
      ]
    },
    {
      imageSrc: subEarth,
      coordinates: [
        { x: 164, y: 228, quantityMobs: 6, mobLevels:{min: 435}, name: 'Large Bahamut of Abyss / Silver Valkyrie of Abyss' },
      ]
    },
    {
      imageSrc: hsEarth,
      coordinates: [
        { x: 141, y: 232, quantityMobs: 9, mobLevels:{min: 435}, name: 'Large Bahamut of Abyss / Silver Valkyrie of Abyss' },
      ]
    },
  ],
}

const AtlansAbyss2 = () => <MuMap {...mapConfigs} />

export default AtlansAbyss2