import React from "react";
import { Button, Stack } from "@mui/material";
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { TextField, Select } from "../../components/form"

const initialValues = {
  levelFrom: 0,
  levelTo: 0,
  experiencePerSecond: 0,
  bar: 0,
};

const validationSchema = yup.object({
  levelFrom: yup
    .number("Level de")
    .required("O campo 'Level de' é obrigatório")
    .min(1, "O valor mínimo do campo 'Level de' é 1")
    .max(1449, "O valor máximo do campo 'Level de' é 1449")
    .positive("O valor do campo 'Level de' precisa ser positivo")
    .integer(),
  levelTo: yup
    .number("Level para")
    .required("O campo 'Level para' é obrigatório")
    .min(1, "O valor mínimo do campo 'Level de' é 1")
    .max(1450, "O valor máximo do campo 'Level para' é 1450")
    .positive("O valor do campo 'Level para' precisa ser positivo")
    .integer()
    .when(["levelFrom"],
      ([levelFrom], schema) => {
        const min = levelFrom + 1;
        return schema.min(min, `O valor mínimo do campo 'Level de' é ${min}`)
      }
    ),
  experiencePerSecond: yup
    .number("Experiencia / s")
    .required("O campo 'Experiencia / s' é obrigatório")
    .min(1, "O valor mínimo do campo 'Experiencia / s' é 1")
    .positive("O valor do campo 'Experiencia / s' precisa ser positivo")
    .integer(),
  bar: yup
    .number("Barra")
    .min(0)
    .integer(),
});

const CalculatorForm = ({ onSubmit }) => (
  <Formik
    initialValues={initialValues}
    validationSchema={validationSchema}
    onSubmit={onSubmit}
  >
    <Form>
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="flex-start"
        spacing={2}
        p={0}
      >
        <TextField
          fullWidth
          id="levelFrom"
          name="levelFrom"
          label="Level de"
          type="number"
        />
        <TextField
          fullWidth
          id="levelTo"
          name="levelTo"
          label="Level para"
          type="number"
        />
        <TextField
          fullWidth
          id="experiencePerSecond"
          name="experiencePerSecond"
          label="Experiência / s"
          type="number"
        />
        <Select
          fullWidth
          id="bar"
          name="bar"
          label="Barra"
          options={[
            { name: "0", value: 0 },
            { name: "1", value: 1 },
            { name: "2", value: 2 },
            { name: "3", value: 3 },
            { name: "4", value: 4 },
            { name: "5", value: 5 },
            { name: "6", value: 6 },
            { name: "7", value: 7 },
            { name: "8", value: 8 },
            { name: "9", value: 9 },
          ]}
        />

        <Button variant="secondary" fullWidth type="reset">
          Limpar
        </Button>
        <Button color="primary" variant="contained" fullWidth type="submit">
          Gerar
        </Button>
      </Stack>
    </Form>
  </Formik>
);


export default CalculatorForm;
