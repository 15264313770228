import React from "react"

import MuMap from '.';
import imageMap from '../../images/Mu/Data/Interface/GFx/NaviMap/Navimap135.png';

import hsDark from '../../images/markers/hs-dark.png';
import hsFire from '../../images/markers/hs-fire.png';
import hsWater from '../../images/markers/hs-water.png';
import hsWind from '../../images/markers/hs-wind.png';
import hsEarth from '../../images/markers/hs-earth.png';

import subDark from '../../images/markers/sub-dark.png';
import subEarth from '../../images/markers/sub-earth.png';
import subFire from '../../images/markers/sub-fire.png';
import subWater from '../../images/markers/sub-water.png';
import subWind from '../../images/markers/sub-wind.png';

import spotDark from '../../images/markers/spot-dark.png';
import spotEarth from '../../images/markers/spot-earth.png';
import spotFire from '../../images/markers/spot-fire.png';
import spotWater from '../../images/markers/spot-water.png';
import spotWind from '../../images/markers/spot-wind.png';

const mapConfigs = {
  name: "Gray Aida",
  center: [150, 106],
  url: imageMap,
  markers: [
    {
      imageSrc: spotWater,
      coordinates: [
        { x: 116, y: 62, quantityMobs: 5, mobLevels:{min: 0}, name: 'Gray Forest Orc / Gray Death Tree / Gray Witch Queen' },
        { x: 111, y: 92, quantityMobs: 6, mobLevels:{min: 0}, name: 'Gray Forest Orc / Gray Death Tree / Gray Witch Queen' },
        { x: 88, y: 72, quantityMobs: 6, mobLevels:{min: 0}, name: 'Gray Forest Orc / Gray Death Tree / Gray Witch Queen' },
      ]
    },
    {
      imageSrc: subWater,
      coordinates: [
        { x: 89, y: 117, quantityMobs: 7, mobLevels:{min: 0}, name: 'Gray Forest Orc / Gray Death Tree / Gray Witch Queen' },
        { x: 70, y: 88, quantityMobs: 7, mobLevels:{min: 0}, name: 'Gray Forest Orc / Gray Death Tree / Gray Witch Queen' },
      ]
    },
    {
      imageSrc: hsWater,
      coordinates: [
        { x: 67, y: 140, quantityMobs: 9, mobLevels:{min: 0}, name: 'Gray Forest Orc / Gray Death Tree / Gray Witch Queen' },
        { x: 47, y: 108, quantityMobs: 9, mobLevels:{min: 0}, name: 'Gray Forest Orc / Gray Death Tree / Gray Witch Queen' },
      ]
    },
    {
      imageSrc: spotFire,
      coordinates: [
        { x: 75, y: 19, quantityMobs: 5, mobLevels:{min: 0}, name: 'Gray Forest Orc / Gray Death Tree / Gray Witch Queen' },
        { x: 80, y: 43, quantityMobs: 6, mobLevels:{min: 0}, name: 'Gray Forest Orc / Gray Death Tree / Gray Witch Queen' },
        { x: 48, y: 41, quantityMobs: 6, mobLevels:{min: 0}, name: 'Gray Forest Orc / Gray Death Tree / Gray Witch Queen' },
      ]
    },
    {
      imageSrc: subFire,
      coordinates: [
        { x: 68, y: 57, quantityMobs: 7, mobLevels:{min: 0}, name: 'Gray Forest Orc / Gray Death Tree / Gray Witch Queen' },
        { x: 51, y: 67, quantityMobs: 7, mobLevels:{min: 0}, name: 'Gray Forest Orc / Gray Death Tree / Gray Witch Queen' },
      ]
    },
    {
      imageSrc: hsFire,
      coordinates: [
        { x: 33, y: 82, quantityMobs: 9, mobLevels:{min: 0}, name: 'Gray Forest Orc / Gray Death Tree / Gray Witch Queen' },
        { x: 20, y: 59, quantityMobs: 9, mobLevels:{min: 0}, name: 'Gray Forest Orc / Gray Death Tree / Gray Witch Queen' },
      ]
    },
    {
      imageSrc: spotWind,
      coordinates: [
        { x: 144, y: 74, quantityMobs: 5, mobLevels:{min: 0}, name: 'Gray Forest Orc / Gray Death Tree / Gray Witch Queen' },
        { x: 128, y: 83, quantityMobs: 6, mobLevels:{min: 0}, name: 'Gray Forest Orc / Gray Death Tree / Gray Witch Queen' },
        { x: 146, y: 133, quantityMobs: 6, mobLevels:{min: 0}, name: 'Gray Forest Orc / Gray Death Tree / Gray Witch Queen' },
      ]
    },
    {
      imageSrc: subWind,
      coordinates: [
        { x: 150, y: 103, quantityMobs: 7, mobLevels:{min: 0}, name: 'Gray Forest Orc / Gray Death Tree / Gray Witch Queen' },
        { x: 119, y: 114, quantityMobs: 7, mobLevels:{min: 0}, name: 'Gray Forest Orc / Gray Death Tree / Gray Witch Queen' },
      ]
    },
    {
      imageSrc: hsWind,
      coordinates: [
        { x: 133, y: 167, quantityMobs: 9, mobLevels:{min: 0}, name: 'Gray Forest Orc / Gray Death Tree / Gray Witch Queen' },
        { x: 98, y: 160, quantityMobs: 9, mobLevels:{min: 0}, name: 'Gray Forest Orc / Gray Death Tree / Gray Witch Queen' },
      ]
    },
    {
      imageSrc: spotDark,
      coordinates: [
        { x: 180, y: 19, quantityMobs: 5, mobLevels:{min: 0}, name: 'Gray Forest Orc / Gray Death Tree / Gray Witch Queen' },
        { x: 192, y: 53, quantityMobs: 6, mobLevels:{min: 0}, name: 'Gray Forest Orc / Gray Death Tree / Gray Witch Queen' },
        { x: 221, y: 84, quantityMobs: 6, mobLevels:{min: 0}, name: 'Gray Forest Orc / Gray Death Tree / Gray Witch Queen' },
      ]
    },
    {
      imageSrc: subDark,
      coordinates: [
        { x: 214, y: 27, quantityMobs: 7, mobLevels:{min: 0}, name: 'Gray Forest Orc / Gray Death Tree / Gray Witch Queen' },
        { x: 243, y: 104, quantityMobs: 7, mobLevels:{min: 0}, name: 'Gray Forest Orc / Gray Death Tree / Gray Witch Queen' },
      ]
    },
    {
      imageSrc: hsDark,
      coordinates: [
        { x: 239, y: 52, quantityMobs: 9, mobLevels:{min: 0}, name: 'Gray Forest Orc / Gray Death Tree / Gray Witch Queen' },
        { x: 226, y: 129, quantityMobs: 9, mobLevels:{min: 0}, name: 'Gray Forest Orc / Gray Death Tree / Gray Witch Queen' },
      ]
    },
    {
      imageSrc: spotEarth,
      coordinates: [
        { x: 170, y: 57, quantityMobs: 5, mobLevels:{min: 0}, name: 'Gray Forest Orc / Gray Death Tree / Gray Witch Queen' },
        { x: 187, y: 84, quantityMobs: 6, mobLevels:{min: 0}, name: 'Gray Forest Orc / Gray Death Tree / Gray Witch Queen' },
        { x: 168, y: 110, quantityMobs: 6, mobLevels:{min: 0}, name: 'Gray Forest Orc / Gray Death Tree / Gray Witch Queen' },
      ]
    },
    {
      imageSrc: subEarth,
      coordinates: [
        { x: 198, y: 131, quantityMobs: 7, mobLevels:{min: 0}, name: 'Gray Forest Orc / Gray Death Tree / Gray Witch Queen' },
        { x: 172, y: 148, quantityMobs: 7, mobLevels:{min: 0}, name: 'Gray Forest Orc / Gray Death Tree / Gray Witch Queen' },
      ]
    },
    {
      imageSrc: hsEarth,
      coordinates: [
        { x: 203, y: 157, quantityMobs: 9, mobLevels:{min: 0}, name: 'Gray Forest Orc / Gray Death Tree / Gray Witch Queen' },
        { x: 169, y: 166, quantityMobs: 9, mobLevels:{min: 0}, name: 'Gray Forest Orc / Gray Death Tree / Gray Witch Queen' },
      ]
    },
  ],
}

const GrayAida = () => <MuMap {...mapConfigs} />

export default GrayAida