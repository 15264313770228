import React from "react"

import MuMap from '.';
import imageMap from '../../images/Mu/Data/Interface/GFx/NaviMap/Navimap82.png';

import hsNormal from '../../images/markers/hs-normal.png';
import subNormal from '../../images/markers/sub-normal.png';
import spotNormal from '../../images/markers/spot-normal.png';

const mapConfigs = {
  name: "Karutan 2",
  url: imageMap,
  markers: [
    {
      imageSrc: spotNormal,
      coordinates: [
        { x: 104, y: 60, quantityMobs: 3, mobLevels:{min: 117, max: 120}, name: 'Condra / Narcondra' },
        { x: 126, y: 85, quantityMobs: 3, mobLevels:{min: 117, max: 120}, name: 'Condra / Narcondra' },
        { x: 132, y: 57, quantityMobs: 3, mobLevels:{min: 117, max: 120}, name: 'Condra / Narcondra' },
        { x: 138, y: 152, quantityMobs: 3, mobLevels:{min: 105, max: 108}, name: 'Orcus / Gollock' },
        { x: 200, y: 80, quantityMobs: 3, mobLevels:{min: 117, max: 120}, name: 'Condra / Narcondra' },
        { x: 205, y: 103, quantityMobs: 3, mobLevels:{min: 117, max: 120}, name: 'Condra / Narcondra' },
      ]
    },
    {
      imageSrc: subNormal,
      coordinates: [
        { x: 77, y: 104, quantityMobs: 4, mobLevels:{min: 117, max: 120}, name: 'Condra / Narcondra' },
        { x: 81, y: 48, quantityMobs: 4, mobLevels:{min: 117, max: 120}, name: 'Condra / Narcondra' },
        { x: 141, y: 111, quantityMobs: 4, mobLevels:{min: 108, max: 120}, name: 'Gollock / Condra / Narcondra' },
        { x: 165, y: 34, quantityMobs: 4, mobLevels:{min: 117, max: 120}, name: 'Condra / Narcondra' },
        { x: 183, y: 64, quantityMobs: 4, mobLevels:{min: 117, max: 120}, name: 'Condra / Narcondra' },
        { x: 200, y: 61, quantityMobs: 4, mobLevels:{min: 117, max: 120}, name: 'Condra / Narcondra' },
        { x: 208, y: 169, quantityMobs: 4, mobLevels:{min: 105, max: 108}, name: 'Orcus / Gollock' },
      ]
    },
    {
      imageSrc: hsNormal,
      coordinates: [
        { x: 64, y: 171, quantityMobs: 6, mobLevels:{min: 105, max: 108}, name: 'Orcus / Gollock' },
        { x: 61, y: 136, quantityMobs: 5, mobLevels:{min: 108, max: 120}, name: 'Gollock / Condra / Narcondra' },
        { x: 99, y: 86, quantityMobs: 5, mobLevels:{min: 117, max: 120}, name: 'Condra / Narcondra' },
        { x: 139, y: 141, quantityMobs: 5, mobLevels:{min: 105, max: 108}, name: 'Orcus / Gollock' },
        { x: 211, y: 129, quantityMobs: 6, mobLevels:{min: 105, max: 120}, name: 'Orcus / Gollock / Condra / Narcondra' },
        { x: 205, y: 159, quantityMobs: 7, mobLevels:{min: 105, max: 108}, name: 'Orcus / Gollock' },
      ]
    },
  ],
}

const Karutan2 = () => <MuMap {...mapConfigs} />

export default Karutan2