import React from 'react';
import { Routes, Route, useRoutes } from "react-router-dom";
import Home from './Home';

import { mapRoutes } from './mapConfigs';


const MapRoutes = () => {
  const routes = useRoutes(mapRoutes);
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
      </Routes>
      {routes}
    </>
  )
}

export default MapRoutes