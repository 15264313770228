import React from "react"

import MuMap from '.';
import imageMap from '../../images/Mu/Data/Interface/GFx/NaviMap/Navimap25.png';

import subNormal from '../../images/markers/sub-normal.png';
import spotNormal from '../../images/markers/spot-normal.png';

const mapConfigs = {
  name: "Kalima",
  center: [64, 64],
  zoom: 1.5,
  zoomMobile: 1,
  url: imageMap,
  markers: [
    {
      imageSrc: spotNormal,
      coordinates: [
        { x: 37, y: 17, quantityMobs: 3, name: 'Aegis' },
        { x: 80, y: 79, quantityMobs: 3, name: 'Necron / Death Centurion' },
        { x: 84, y: 14, quantityMobs: 3, name: 'Lord Centurion' },
        { x: 90, y: 89, quantityMobs: 3, name: 'Necron / Death Centurion' },
        { x: 97, y: 13, quantityMobs: 3, name: 'Lord Centurion' },
        { x: 113, y: 71, quantityMobs: 3, name: 'Death Angel' },
        { x: 117, y: 36, quantityMobs: 3, name: 'Bloody Soldier' },
      ]
    },
    {
      imageSrc: subNormal,
      coordinates: [
        { x: 51, y: 17, quantityMobs: 4, name: 'Aegis' },
        { x: 62, y: 74, quantityMobs: 4, name: 'Death Centurion' },
      ]
    },
  ],
}

const Kalima = () => <MuMap {...mapConfigs} />

export default Kalima