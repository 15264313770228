import React from "react"

import MuMap from '.';
import imageMap from '../../images/Mu/Data/Interface/GFx/NaviMap/Navimap11.png';

import hsNormal from '../../images/markers/hs-normal.png';
import subNormal from '../../images/markers/sub-normal.png';
import spotNormal from '../../images/markers/spot-normal.png';

const mapConfigs = {
  name: "Icarus",
  center: [64, 128],
  url: imageMap,
  markers: [
    {
      imageSrc: spotNormal,
      coordinates: [
        { x: 37, y: 92, quantityMobs: 3, mobLevels:{min: 92}, name: 'Alpha Crust' },
        { x: 82, y: 77, quantityMobs: 3, mobLevels:{min: 82}, name: 'Queen Rainer' },
        { x: 92, y: 55, quantityMobs: 3, mobLevels:{min: 82}, name: 'Queen Rainer' },
      ]
    },
    {
      imageSrc: subNormal,
      coordinates: [
        { x: 18, y: 36, quantityMobs: 4, mobLevels:{min: 75}, name: 'Alquamos' },
        { x: 35, y: 72, quantityMobs: 4, mobLevels:{min: 86}, name: 'Drakan' },
        { x: 52, y: 157, quantityMobs: 4, mobLevels:{min: 100}, name: 'Great Drakan' },
        { x: 63, y: 74, quantityMobs: 4, mobLevels:{min: 82}, name: 'Queen Rainer' },
        { x: 90, y: 41, quantityMobs: 4, mobLevels:{min: 75, max: 82}, name: 'Alquamos / Mega Crust / Queen Rainer' },
      ]
    },
    {
      imageSrc: hsNormal,
      coordinates: [
        { x: 33, y: 34, quantityMobs: 5, mobLevels:{min: 75}, name: 'Alquamos' },
        { x: 48, y: 33, quantityMobs: 5, mobLevels:{min: 75, max: 78}, name: 'Alquamos / Mega Crust' },
        { x: 60, y: 34, quantityMobs: 5, mobLevels:{min: 75, max: 78}, name: 'Alquamos / Mega Crust' },
        { x: 75, y: 36, quantityMobs: 5, mobLevels:{min: 75, max: 82}, name: 'Alquamos / Mega Crust / Queen Rainer' },
      ]
    },
  ],
}

const Icarus = () => <MuMap {...mapConfigs} />

export default Icarus