import { CssBaseline } from '@mui/material';
import Core from './core';
import { BrowserRouter } from "react-router-dom";
import mixpanel from 'mixpanel-browser';

mixpanel.init('5870a6db9e0dcbff15ed4c8be32111cc', { debug: false, track_pageview: true });
mixpanel.set_config({'persistence': 'localStorage'});

export default function App() { 
  return (
    <BrowserRouter>
      <CssBaseline enableColorScheme />
      <Core />
    </BrowserRouter>
  );
}
