import React from "react"

import MuMap from '.';
import imageMap from '../../images/Mu/Data/Interface/GFx/NaviMap/Navimap01.png';

import hsNormal from '../../images/markers/hs-normal.png';
import subNormal from '../../images/markers/sub-normal.png';
import spotNormal from '../../images/markers/spot-normal.png';

const mapConfigs = {
  name: "Lorencia",
  url: imageMap,
  markers: [
    {
      imageSrc: spotNormal,
      coordinates: [
        { x: 182, y: 58, quantityMobs: 4, mobLevels:{min: 6} },
        { x: 193, y: 127, quantityMobs: 3, mobLevels:{min: 2} },
      ]
    },
    {
      imageSrc: subNormal,
      coordinates: [
        { x: 33, y: 101, quantityMobs: 5, mobLevels:{min: 12} },
        { x: 40, y: 56, quantityMobs: 5, mobLevels:{min: 17} },
        { x: 63, y: 199, quantityMobs: 5, mobLevels:{min: 9}},
        { x: 163, y: 230, quantityMobs: 5, mobLevels:{min: 14} },
      ]
    },
    {
      imageSrc: hsNormal,
      coordinates: [
        { x: 118, y: 211, quantityMobs: 5, mobLevels:{min: 19} },
      ]
    },
  ],
}

const Lorencia = () => <MuMap {...mapConfigs} />

export default Lorencia