import React from "react"

import MuMap from '.';
import imageMap from '../../images/Mu/Data/Interface/GFx/NaviMap/Navimap138.png';

import hsDark from '../../images/markers/hs-dark.png';
import hsFire from '../../images/markers/hs-fire.png';
import hsWater from '../../images/markers/hs-water.png';
import hsWind from '../../images/markers/hs-wind.png';
import hsEarth from '../../images/markers/hs-earth.png';

import subDark from '../../images/markers/sub-dark.png';
import subEarth from '../../images/markers/sub-earth.png';
import subFire from '../../images/markers/sub-fire.png';
import subWater from '../../images/markers/sub-water.png';
import subWind from '../../images/markers/sub-wind.png';

import spotDark from '../../images/markers/spot-dark.png';
import spotEarth from '../../images/markers/spot-earth.png';
import spotFire from '../../images/markers/spot-fire.png';
import spotWater from '../../images/markers/spot-water.png';
import spotWind from '../../images/markers/spot-wind.png';

const mapConfigs = {
  name: "Kanturu Underground",
  url: imageMap,
  markers: [
    {
      imageSrc: spotWater,
      coordinates: [
        { x: 99, y: 163, quantityMobs: 5, mobLevels:{min: 0}, name: 'Underground Persona / Underground Dreadfear / Underground Twin Tale' },
        { x: 98, y: 191, quantityMobs: 5, mobLevels:{min: 0}, name: 'Underground Persona / Underground Dreadfear / Underground Twin Tale' },
        { x: 55, y: 189, quantityMobs: 5, mobLevels:{min: 0}, name: 'Underground Persona / Underground Dreadfear / Underground Twin Tale' },
        { x: 55, y: 147, quantityMobs: 6, mobLevels:{min: 0}, name: 'Underground Persona / Underground Dreadfear / Underground Twin Tale' },
      ]
    },
    {
      imageSrc: subWater,
      coordinates: [
        { x: 19, y: 189, quantityMobs: 7, mobLevels:{min: 0}, name: 'Underground Persona / Underground Dreadfear / Underground Twin Tale' },
      ]
    },
    {
      imageSrc: hsWater,
      coordinates: [
        { x: 65, y: 239, quantityMobs: 9, mobLevels:{min: 0}, name: 'Underground Persona / Underground Dreadfear / Underground Twin Tale' },
      ]
    },
    {
      imageSrc: spotFire,
      coordinates: [
        { x: 109, y: 88, quantityMobs: 5, mobLevels:{min: 0}, name: 'Underground Persona / Underground Dreadfear / Underground Twin Tale' },
        { x: 84, y: 110, quantityMobs: 5, mobLevels:{min: 0}, name: 'Underground Persona / Underground Dreadfear / Underground Twin Tale' },
        { x: 27, y: 122, quantityMobs: 5, mobLevels:{min: 0}, name: 'Underground Persona / Underground Dreadfear / Underground Twin Tale' },
        { x: 39, y: 68, quantityMobs: 5, mobLevels:{min: 0}, name: 'Underground Persona / Underground Dreadfear / Underground Twin Tale' },
      ]
    },
    {
      imageSrc: subFire,
      coordinates: [
        { x: 55, y: 8, quantityMobs: 7, mobLevels:{min: 0}, name: 'Underground Persona / Underground Dreadfear / Underground Twin Tale' },
      ]
    },
    {
      imageSrc: hsFire,
      coordinates: [
        { x: 25, y: 90, quantityMobs: 9, mobLevels:{min: 0}, name: 'Underground Persona / Underground Dreadfear / Underground Twin Tale' },
      ]
    },
    {
      imageSrc: spotWind,
      coordinates: [
        { x: 146, y: 155, quantityMobs: 5, mobLevels:{min: 0}, name: 'Underground Persona / Underground Dreadfear / Underground Twin Tale' },
        { x: 125, y: 192, quantityMobs: 5, mobLevels:{min: 0}, name: 'Underground Persona / Underground Dreadfear / Underground Twin Tale' },
        { x: 164, y: 192, quantityMobs: 5, mobLevels:{min: 0}, name: 'Underground Persona / Underground Dreadfear / Underground Twin Tale' },
        { x: 192, y: 193, quantityMobs: 5, mobLevels:{min: 0}, name: 'Underground Persona / Underground Dreadfear / Underground Twin Tale' },
      ]
    },
    {
      imageSrc: subWind,
      coordinates: [
        { x: 180, y: 232, quantityMobs: 7, mobLevels:{min: 0}, name: 'Underground Persona / Underground Dreadfear / Underground Twin Tale' },
      ]
    },
    {
      imageSrc: hsWind,
      coordinates: [
        { x: 152, y: 232, quantityMobs: 9, mobLevels:{min: 0}, name: 'Underground Persona / Underground Dreadfear / Underground Twin Tale' },
      ]
    },
    {
      imageSrc: spotDark,
      coordinates: [
        { x: 165, y: 140, quantityMobs: 5, mobLevels:{min: 0}, name: 'Underground Persona / Underground Dreadfear / Underground Twin Tale' },
        { x: 231, y: 130, quantityMobs: 5, mobLevels:{min: 0}, name: 'Underground Persona / Underground Dreadfear / Underground Twin Tale' },
        { x: 197, y: 97, quantityMobs: 5, mobLevels:{min: 0}, name: 'Underground Persona / Underground Dreadfear / Underground Twin Tale' },
        { x: 248, y: 104, quantityMobs: 5, mobLevels:{min: 0}, name: 'Underground Persona / Underground Dreadfear / Underground Twin Tale' },
      ]
    },
    {
      imageSrc: subDark,
      coordinates: [
        { x: 246, y: 83, quantityMobs: 7, mobLevels:{min: 0}, name: 'Underground Persona / Underground Dreadfear / Underground Twin Tale' },
      ]
    },
    {
      imageSrc: hsDark,
      coordinates: [
        { x: 237, y: 170, quantityMobs: 9, mobLevels:{min: 0}, name: 'Underground Persona / Underground Dreadfear / Underground Twin Tale' },
      ]
    },
    {
      imageSrc: spotEarth,
      coordinates: [
        { x: 166, y: 97, quantityMobs: 6, mobLevels:{min: 0}, name: 'Underground Persona / Underground Dreadfear / Underground Twin Tale' },
        { x: 116, y: 36, quantityMobs: 5, mobLevels:{min: 0}, name: 'Underground Persona / Underground Dreadfear / Underground Twin Tale' },
        { x: 154, y: 8, quantityMobs: 5, mobLevels:{min: 0}, name: 'Underground Persona / Underground Dreadfear / Underground Twin Tale' },
        { x: 196, y: 52, quantityMobs: 5, mobLevels:{min: 0}, name: 'Underground Persona / Underground Dreadfear / Underground Twin Tale' },
      ]
    },
    {
      imageSrc: subEarth,
      coordinates: [
        { x: 154, y: 53, quantityMobs: 7, mobLevels:{min: 0}, name: 'Underground Persona / Underground Dreadfear / Underground Twin Tale' },
      ]
    },
    {
      imageSrc: hsEarth,
      coordinates: [
        { x: 180, y: 5, quantityMobs: 9, mobLevels:{min: 0}, name: 'Underground Persona / Underground Dreadfear / Underground Twin Tale' },
      ]
    },
  ],
}

const KanturuUnderground = () => <MuMap {...mapConfigs} />

export default KanturuUnderground