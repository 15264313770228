import Lorencia from '../../components/MuMap/Lorencia';
import Dungeon from '../../components/MuMap/Dungeon';
import Devias from '../../components/MuMap/Devias';
import Noria from '../../components/MuMap/Noria';
import LostTower from '../../components/MuMap/LostTower';
import Atlans from '../../components/MuMap/Atlans';
import Tarkan from '../../components/MuMap/Tarkan';
import Icarus from '../../components/MuMap/Icarus';
import Kalima from '../../components/MuMap/Kalima';
import LandOfTrials from '../../components/MuMap/LandOfTrials';
import Aida from '../../components/MuMap/Aida';
import Crywolf from '../../components/MuMap/Crywolf';
import Kanturu from '../../components/MuMap/Kanturu';
import KanturuRelics from '../../components/MuMap/KanturuRelics';
import Elbeland from '../../components/MuMap/Elbeland';
import SwampOfPeace from '../../components/MuMap/SwampOfPeace';
import Raklion from '../../components/MuMap/Raklion';
import Vulcanus from '../../components/MuMap/Vulcanus';
import Karutan1 from '../../components/MuMap/Karutan1';
import Karutan2 from '../../components/MuMap/Karutan2';
import ArcheronAlkmarUbaid from '../../components/MuMap/ArcheronAlkmarUbaid';
import ArcheronDebenter from '../../components/MuMap/ArcheronDebenter';
import ArcheronUrukMountain from '../../components/MuMap/ArcheronUrukMountain';
import ArcheronNars from '../../components/MuMap/ArcheronNars';
import Ferea from '../../components/MuMap/Ferea';
import NixiesLake from '../../components/MuMap/NixiesLake';
import DeepDungeon1 from '../../components/MuMap/DeepDungeon1';
import DeepDungeon2 from '../../components/MuMap/DeepDungeon2';
import DeepDungeon3 from '../../components/MuMap/DeepDungeon3';
import DeepDungeon4 from '../../components/MuMap/DeepDungeon4';
import DeepDungeon5 from '../../components/MuMap/DeepDungeon5';
import SwampOfDarkness from '../../components/MuMap/SwampOfDarkness';
import CuberaMines from '../../components/MuMap/CuberaMines';
import AtlansAbyss1 from '../../components/MuMap/AtlansAbyss1';
import AtlansAbyss2 from '../../components/MuMap/AtlansAbyss2';
import AtlansAbyss3 from '../../components/MuMap/AtlansAbyss3';
import ScorchedCanyon from '../../components/MuMap/ScorchedCanyon';
import CrimsonFlamesIcarus from '../../components/MuMap/CrimsonFlamesIcarus';
import TempleOfArnil from '../../components/MuMap/TempleOfArnil';
import GrayAida from '../../components/MuMap/GrayAida';
import OldKethotum from '../../components/MuMap/OldKethotum';
import BurningKethotum from '../../components/MuMap/BurningKethotum';
import KanturuUnderground from '../../components/MuMap/KanturuUnderground';

const options = [
  { entryLevel: 10, gap: '', name: 'Lorencia', path: '/lorencia/', element: <Lorencia /> },
  { entryLevel: 10, gap: '', name: 'Noria', path: '/noria/', element: <Noria /> },
  { entryLevel: 10, gap: '', name: 'Elbeland', path: '/elbeland/', element: <Elbeland /> },
  { entryLevel: 10, gap: '', name: 'Devias', path: '/devias/', element: <Devias /> },
  { entryLevel: 30, gap: '', name: 'Dungeon', path: '/dungeon/', element: <Dungeon /> },
  { entryLevel: 50, gap: '', name: 'Atlans', path: '/atlans/', element: <Atlans /> },
  { entryLevel: 50, gap: '', name: 'LostTower', path: '/lost-tower/', element: <LostTower /> },
  { entryLevel: 140, gap: '', name: 'Tarkan', path: '/tarkan/', element: <Tarkan /> },
  { entryLevel: 150, gap: '', name: 'Aida', path: '/aida/', element: <Aida /> },
  { entryLevel: 160, gap: '', name: 'Icarus', path: '/icarus/', element: <Icarus /> },
  { entryLevel: 0, gap: '', name: 'Kalima', path: '/kalima/', element: <Kalima /> },
  { entryLevel: 0, gap: '', name: 'Land of Trials', path: '/land-of-trials/', element: <LandOfTrials /> },
  { entryLevel: 0, gap: '', name: 'Crywolf', path: '/crywolf/', element: <Crywolf /> },
  { entryLevel: 160, gap: '', name: 'Kanturu', path: '/kanturu/', element: <Kanturu /> },
  { entryLevel: 280, gap: '', name: 'Karutan 1', path: '/karutan-1/', element: <Karutan1 /> },
  { entryLevel: 300, gap: '', name: 'Kanturu Relics', path: '/kanturu-relics/', element: <KanturuRelics /> },
  { entryLevel: 300, gap: '', name: 'Vulcanus', path: '/vulcanus/', element: <Vulcanus /> },
  { entryLevel: 300, gap: '', name: 'Archeron - Alkmar / Ubaid', path: '/archeron-alkmar-ubaid/', element: <ArcheronAlkmarUbaid /> },
  { entryLevel: 300, gap: '', name: 'Archeron - Debenter', path: '/archeron-debenter/', element: <ArcheronDebenter /> },
  { entryLevel: 300, gap: '', name: 'Archeron - Uruk Mountain', path: '/archeron-uruk-mountain/', element: <ArcheronUrukMountain /> },
  { entryLevel: 300, gap: '', name: 'Archeron - Nars', path: '/archeron-nars/', element: <ArcheronNars /> },
  { entryLevel: 380, gap: '', name: 'Karutan 2', path: '/karutan-2/', element: <Karutan2 /> },
  { entryLevel: 380, gap: '', name: 'Swamp of Peace', path: '/swamp-of-peace/', element: <SwampOfPeace /> },
  { entryLevel: 380, gap: '', name: 'Raklion', path: '/raklion/', element: <Raklion /> },
  { entryLevel: 400, gap: '', name: 'Ferea', path: '/ferea/', element: <Ferea /> },
  { entryLevel: 630, gap: '', name: 'Nixies Lake', path: '/nixies-lake/', element: <NixiesLake /> },
  { entryLevel: 700, gap: '', name: 'Old Kethotum', path: '/old-kethotum/', element: <OldKethotum /> },
  { entryLevel: 770, gap: '', name: 'Deep Dungeon 1', path: '/deep-dungeon-1/', element: <DeepDungeon1 /> },
  { entryLevel: 770, gap: '', name: 'Deep Dungeon 2', path: '/deep-dungeon-2/', element: <DeepDungeon2 /> },
  { entryLevel: 770, gap: '825', name: 'Deep Dungeon 3', path: '/deep-dungeon-3/', element: <DeepDungeon3 /> },
  { entryLevel: 770, gap: '850', name: 'Deep Dungeon 4', path: '/deep-dungeon-4/', element: <DeepDungeon4 /> },
  { entryLevel: 770, gap: '875', name: 'Deep Dungeon 5', path: '/deep-dungeon-5/', element: <DeepDungeon5 /> },
  { entryLevel: 800, gap: '875', name: 'Swamp of Darkness', path: '/swamp-of-darkness/', element: <SwampOfDarkness /> },
  { entryLevel: 850, gap: '925 ~ 970', name: 'Cubera Mines Fire', path: '/cubera-mines-fire/', element: <CuberaMines key="fire" element="fire" /> },
  { entryLevel: 850, gap: '925 ~ 970', name: 'Cubera Mines Dark', path: '/cubera-mines-dark/', element: <CuberaMines key="dark" element="dark" /> },
  { entryLevel: 850, gap: '925 ~ 970', name: 'Cubera Mines Earth', path: '/cubera-mines-earth/', element: <CuberaMines key="earth" element="earth" /> },
  { entryLevel: 850, gap: '925 ~ 970', name: 'Cubera Mines Water', path: '/cubera-mines-water/', element: <CuberaMines key="water" element="water" /> },
  { entryLevel: 850, gap: '925 ~ 970', name: 'Cubera Mines Wind', path: '/cubera-mines-wind/', element: <CuberaMines key="wind" element="wind" /> },
  { entryLevel: 1000, gap: '1010', name: 'Atlans Abyss 1', path: '/atlans-abyss-1/', element: <AtlansAbyss1 /> },
  { entryLevel: 1020, gap: '1035', name: 'Atlans Abyss 2', path: '/atlans-abyss-2/', element: <AtlansAbyss2 /> },
  { entryLevel: 1030, gap: '1060', name: 'Atlans Abyss 3', path: '/atlans-abyss-3/', element: <AtlansAbyss3 /> },
  { entryLevel: 1050, gap: '1070 ~ 1080', name: 'Scorched Canyon', path: '/scorched-canyon/', element: <ScorchedCanyon /> },
  { entryLevel: 1070, gap: '1100', name: 'Crimson Flame\'s Icarus', path: '/crimson-flames-icarus/', element: <CrimsonFlamesIcarus /> },
  { entryLevel: 1100, gap: '1130', name: 'Temple of Arnil', path: '/temple-of-arnil/', element: <TempleOfArnil /> },
  { entryLevel: 1130, gap: '1160', name: 'Gray Aida', path: '/gray-aida/', element: <GrayAida /> },
  { entryLevel: 1160, gap: '1190', name: 'Burning Kethotum', path: '/burning-kethotum/', element: <BurningKethotum /> },
  { entryLevel: 1190, gap: '1220', name: 'Kanturu Underground', path: '/kanturu-underground/', element: <KanturuUnderground /> },
];

export const mapOptions = options.map(({ entryLevel, gap, name, path }) => ({ entryLevel, gap, name, path }));
export const mapRoutes = options.map(({ path, element }) => ({ path, element }));