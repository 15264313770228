import React from 'react';
import { Drawer, Box, Divider } from '@mui/material';
import { Home, Map, Pattern, Calculate } from '@mui/icons-material';
import MenuHeader from './MenuHeader';
import MenuItem from './MenuItem';

const Menu = ({ title, isOpen, onClose, toggle }) => {
  return (
    <Drawer
      anchor={'left'}
      open={isOpen}
      onClose={onClose}
    >
      <Box
        sx={{ width: 300 }}
        role="presentation"
        onClick={toggle}
        onKeyDown={toggle}
      >
        <MenuHeader title={title} />
        <MenuItem name={"Home"} path={"/"} icon={<Home />} />
        <Divider />
        <MenuItem name={"Mapas"} path={"/maps"} icon={<Map />} />
        <Divider />
        <MenuItem name={"Maze"} path={"/maze-of-dimensions"} icon={<Pattern />} />
        <Divider />
        <MenuItem name={"Calculadora de experiência"} path={"/experience-calculator"} icon={<Calculate />} />
      </Box>
    </Drawer>
  );
}

export default Menu