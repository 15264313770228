import React from "react"

import MuMap from '.';
import imageMap from '../../images/Mu/Data/Interface/GFx/NaviMap/Navimap96.png';

import hsDark from '../../images/markers/hs-dark.png';
import hsFire from '../../images/markers/hs-fire.png';
import hsWater from '../../images/markers/hs-water.png';
import hsWind from '../../images/markers/hs-wind.png';
import hsEarth from '../../images/markers/hs-earth.png';

import subEarth from '../../images/markers/sub-earth.png';
import subFire from '../../images/markers/sub-fire.png';
import subWater from '../../images/markers/sub-water.png';
import subWind from '../../images/markers/sub-wind.png';

import spotDark from '../../images/markers/spot-dark.png';
import spotEarth from '../../images/markers/spot-earth.png';
import spotWater from '../../images/markers/spot-water.png';

const mapConfigs = {
  name: "Archeron - Debenter",
  center: [128, 64],
  zoom: 1,
  zoomMobile: 0.5,
  url: imageMap,
  markers: [
    {
      imageSrc: hsFire,
      coordinates: [
        { x: 194, y: 96, quantityMobs: 5, mobLevels:{min: 105, max: 107}, name: 'Debenter Elemental Beast / Debenter Devilfairy' },
      ]
    },
    {
      imageSrc: spotWater,
      coordinates: [
        { x: 210, y: 74, quantityMobs: 3, mobLevels:{min: 105, max: 109}, name: 'Debenter Elemental Knight / Debenter Devilfairy' },
      ]
    },
    {
      imageSrc: subFire,
      coordinates: [
        { x: 34, y: 59, quantityMobs: 4, mobLevels:{min: 105, max: 107}, name: 'Debenter Elemental Beast / Debenter Devilfairy' },
        { x: 169, y: 100, quantityMobs: 4, mobLevels:{min: 105, max: 109}, name: 'Debenter Elemental Beast / Debenter Elemental Knight / Debenter Devilfairy' },
      ]
    },
    {
      imageSrc: subWater,
      coordinates: [
        { x: 33, y: 23, quantityMobs: 4, mobLevels:{min: 105, max: 107}, name: 'Debenter Elemental Beast / Debenter Devilfairy' },
      ]
    },
    {
      imageSrc: subWind,
      coordinates: [
        { x: 81, y: 30, quantityMobs: 4, mobLevels:{min: 105, max: 109}, name: 'Debenter Elemental Beast / Debenter Elemental Knight / Debenter Devilfairy' },
        { x: 225, y: 15, quantityMobs: 4, mobLevels:{min: 105, max: 109}, name: 'Debenter Elemental Beast / Debenter Elemental Knight / Debenter Devilfairy' },
      ]
    },
    {
      imageSrc: hsDark,
      coordinates: [
        { x: 118, y: 13, quantityMobs: 5, mobLevels:{min: 105, max: 107}, name: 'Debenter Elemental Beast / Debenter Devilfairy' },
      ]
    },
    {
      imageSrc: spotEarth,
      coordinates: [
        { x: 78, y: 95, quantityMobs: 3, mobLevels:{min: 105, max: 107}, name: 'Debenter Elemental Beast / Debenter Devilfairy' },
      ]
    },
    {
      imageSrc: spotDark,
      coordinates: [
        { x: 178, y: 15, quantityMobs: 3, mobLevels:{min: 105, max: 109}, name: 'Debenter Elemental Beast / Debenter Elemental Knight / Debenter Devilfairy' },
        { x: 205, y: 12, quantityMobs: 3, mobLevels:{min: 105, max: 107}, name: 'Debenter Elemental Beast / Debenter Devilfairy' },
      ]
    },
    {
      imageSrc: subEarth,
      coordinates: [
        { x: 91, y: 59, quantityMobs: 4, mobLevels:{min: 105, max: 107}, name: 'Debenter Elemental Beast / Debenter Devilfairy' },
      ]
    },
    {
      imageSrc: hsEarth,
      coordinates: [
        { x: 240, y: 40, quantityMobs: 5, mobLevels:{min: 105, max: 109}, name: 'Debenter Elemental Beast / Debenter Elemental Knight / Debenter Devilfairy' },
      ]
    },
    {
      imageSrc: hsWater,
      coordinates: [
        { x: 238, y: 83, quantityMobs: 5, mobLevels:{min: 105, max: 109}, name: 'Debenter Elemental Beast / Debenter Elemental Knight / Debenter Devilfairy' },
      ]
    },
    {
      imageSrc: hsWind,
      coordinates: [
        { x: 60, y: 15, quantityMobs: 5, mobLevels:{min: 105, max: 107}, name: 'Debenter Elemental Beast / Debenter Devilfairy' },
      ]
    },
  ],
}

const ArcheronDebenter = () => <MuMap {...mapConfigs} />

export default ArcheronDebenter