import React from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { keyframes } from '@mui/system';
import bg_texture from '../images/bg_texture.png'

const spin = keyframes`
  from {
    background-position: 0 0;
  }
  to {
    background-position: 50% 50%;
  }
`;

const RotatedBox = styled(Box)({
  backgroundImage: `url(${bg_texture})`,
  backgroundColor: '#27243A',
  backgroundSize: '60px 44px',
  animation: `${spin} 45s infinite alternate`
});

const AnimatedBg = ({ children }) => <RotatedBox>{ children }</RotatedBox>

export default AnimatedBg