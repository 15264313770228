import React from "react"

import MuMap from '.';
import imageMap from '../../images/Mu/Data/Interface/GFx/NaviMap/Navimap52.png';

import hsNormal from '../../images/markers/hs-normal.png';
import subNormal from '../../images/markers/sub-normal.png';
import spotNormal from '../../images/markers/spot-normal.png';

const mapConfigs = {
  name: "Elbeland",
  url: imageMap,
  markers: [
    {
      imageSrc: spotNormal,
      coordinates: [
        // Elbeland 2
        { x: 73, y: 79, quantityMobs: 3, mobLevels:{min: 24}, name: "Werewolf" },
        { x: 118, y: 109, quantityMobs: 3, mobLevels:{min: 13, max: 19}, name: "Polluted Butterfly / Hideous Rabbit" },
        // Elbeland 3
        { x: 175, y: 174, quantityMobs: 3, mobLevels:{min: 36}, name: "Totem Golem" },
        { x: 193, y: 148, quantityMobs: 3, mobLevels:{min: 36}, name: "Totem Golem" },
        { x: 189, y: 92, quantityMobs: 3, mobLevels:{min: 43}, name: "Grizzly" },
        { x: 172, y: 88, quantityMobs: 3, mobLevels:{min: 43}, name: "Grizzly" },
        { x: 225, y: 49, quantityMobs: 3, mobLevels:{min: 48}, name: "Captain Grizzly" },
      ]
    },
    {
      imageSrc: subNormal,
      coordinates: [
        // Elbeland 2
        { x: 139, y: 143, quantityMobs: 4, mobLevels:{min: 13, max: 19}, name: "Polluted Butterfly / Hideous Rabbit" },
        // Elbeland 3
        { x: 155, y: 55, quantityMobs: 4, mobLevels:{min: 43}, name: "Grizzly" },
        { x: 171, y: 28, quantityMobs: 4, mobLevels:{min: 48}, name: "Captain Grizzly" },
      ]
    },
    {
      imageSrc: hsNormal,
      coordinates: [
        // Elbeland 1
        { x: 29, y: 155, quantityMobs: 6, mobLevels:{min: 2, max: 4}, name: "Spider / Strange Rabbit" },
        { x: 109, y: 206, quantityMobs: 5, mobLevels:{min: 2, max: 4}, name: "Spider / Strange Rabbit" },
        { x: 133, y: 210, quantityMobs: 6, mobLevels:{min: 2, max: 13}, name: "Spider / Strange Rabbit / Polluted Butterfly" },
        { x: 134, y: 196, quantityMobs: 5, mobLevels:{min: 2, max: 13}, name: "Spider / Strange Rabbit / Polluted Butterfly" },
        // Elbeland 3
        { x: 202, y: 48, quantityMobs: 6, mobLevels:{min: 48}, name: "Captain Grizzly" },
        { x: 204, y: 21, quantityMobs: 6, mobLevels:{min: 48}, name: "Captain Grizzly" },
      ]
    },
  ],
}

const Elbeland = () => <MuMap {...mapConfigs} />

export default Elbeland