import React from "react"

import MuMap from '.';
import imageMap from '../../images/Mu/Data/Interface/GFx/NaviMap/Navimap35.png';

import spotNormal from '../../images/markers/spot-normal.png';

const mapConfigs = {
  name: "Crywolf",
  url: imageMap,
  markers: [
    {
      imageSrc: spotNormal,
      coordinates: [
        { x: 54, y: 215, quantityMobs: 3, mobLevels:{min: 127, max: 132}, name: 'Werewolf (Hero) / Balram' },
        { x: 21, y: 159, quantityMobs: 3, mobLevels:{min: 123, max: 134}, name: 'Hammer Scout (Hero) / Solam' },
        { x: 88, y: 60, quantityMobs: 3, mobLevels:{min: 97}, name: 'Hammer Scout / Lance Scout' },
        { x: 174, y: 84, quantityMobs: 3, mobLevels:{min: 97}, name: 'Lance Scout / Bow Scout' },
        { x: 203, y: 44, quantityMobs: 3, mobLevels:{min: 97}, name: 'Hammer Scout' },
      ]
    },
  ],
}

const Crywolf = () => <MuMap {...mapConfigs} />

export default Crywolf