import React from "react"

import MuMap from '.';
import imageMap from '../../images/Mu/Data/Interface/GFx/NaviMap/Navimap124.png';

import hsDark from '../../images/markers/hs-dark.png';
import hsFire from '../../images/markers/hs-fire.png';
import hsWater from '../../images/markers/hs-water.png';
import hsWind from '../../images/markers/hs-wind.png';
import hsEarth from '../../images/markers/hs-earth.png';

import subDark from '../../images/markers/sub-dark.png';
import subEarth from '../../images/markers/sub-earth.png';
import subFire from '../../images/markers/sub-fire.png';
import subWater from '../../images/markers/sub-water.png';
import subWind from '../../images/markers/sub-wind.png';

import spotDark from '../../images/markers/spot-dark.png';
import spotEarth from '../../images/markers/spot-earth.png';
import spotFire from '../../images/markers/spot-fire.png';
import spotWater from '../../images/markers/spot-water.png';
import spotWind from '../../images/markers/spot-wind.png';

const generateMapConfigs = (mapElement) => {
  const {spot, sub, hs} = {
    fire: {
      spot: spotFire,
      sub: subFire,
      hs: hsFire,
    },
    dark: {
      spot: spotDark,
      sub: subDark,
      hs: hsDark,
    },
    earth: {
      spot: spotEarth,
      sub: subEarth,
      hs: hsEarth,
    },
    water: {
      spot: spotWater,
      sub: subWater,
      hs: hsWater,
    },
    wind: {
      spot: spotWind,
      sub: subWind,
      hs: hsWind,
    },
  }[mapElement];

  return {
    name: "Cubera Mines",
    center: [160, 64],
    url: imageMap,
    markers: [
      {
        imageSrc: spot,
        coordinates: [
          { x: 156, y: 97, quantityMobs: 5, mobLevels:{min: 380}, name: 'Mine Porter / Mine Digger / Mine Carrier' },
          { x: 185, y: 117, quantityMobs: 4, mobLevels:{min: 380}, name: 'Mine Digger / Mine Carrier' },
          { x: 204, y: 88, quantityMobs: 4, mobLevels:{min: 380}, name: 'Mine Digger / Mine Carrier' },
          { x: 148, y: 7, quantityMobs: 4, mobLevels:{min: 380}, name: 'Mine Digger / Mine Carrier' },
          { x: 222, y: 45, quantityMobs: 5, mobLevels:{min: 380}, name: 'Mine Porter / Mine Digger / Mine Carrier' },
        ]
      },
      {
        imageSrc: sub,
        coordinates: [
          { x: 90, y: 56, quantityMobs: 7, mobLevels:{min: 400}, name: 'Dead Porter / Dead Digger / Dead Shoveler' },
          { x: 108, y: 78, quantityMobs: 6, mobLevels:{min: 400}, name: 'Dead Porter / Dead Digger / Dead Shoveler' },
          { x: 145, y: 34, quantityMobs: 7, mobLevels:{min: 380}, name: 'Mine Porter / Mine Digger / Mine Carrier / Mine Driller' },
          { x: 238, y: 14, quantityMobs: 7, mobLevels:{min: 380}, name: 'Mine Porter / Mine Digger / Mine Carrier / Mine Driller' },
        ]
      },
      {
        imageSrc: hs,
        coordinates: [
          { x: 124, y: 93, quantityMobs: 9, mobLevels:{min: 400}, name: 'Dead Porter / Dead Digger / Dead Shoveler' },
        ]
      },
    ],
  }
}

const CuberaMines = ({ element }) => <MuMap {...generateMapConfigs(element)} />

export default CuberaMines