import React from "react"

import MuMap from '.';
import imageMap from '../../images/Mu/Data/Interface/GFx/NaviMap/Navimap118.png';

import hsDark from '../../images/markers/hs-dark.png';
import hsFire from '../../images/markers/hs-fire.png';
import hsWater from '../../images/markers/hs-water.png';
import hsWind from '../../images/markers/hs-wind.png';
import hsEarth from '../../images/markers/hs-earth.png';

import subDark from '../../images/markers/sub-dark.png';
import subEarth from '../../images/markers/sub-earth.png';
import subFire from '../../images/markers/sub-fire.png';
import subWater from '../../images/markers/sub-water.png';
import subWind from '../../images/markers/sub-wind.png';

import spotDark from '../../images/markers/spot-dark.png';
import spotEarth from '../../images/markers/spot-earth.png';
import spotFire from '../../images/markers/spot-fire.png';
import spotWater from '../../images/markers/spot-water.png';
import spotWind from '../../images/markers/spot-wind.png';

const mapConfigs = {
  name: "Deep Dungeon 2",
  center: [128, 32],
  url: imageMap,
  markers: [
    {
      imageSrc: spotFire,
      coordinates: [
        { x: 19, y: 85, quantityMobs: 4, mobLevels:{min: 200}, name: 'Deep Dungeon Cyclops / Deep Dungeon Ghost' },
        { x: 92, y: 116, quantityMobs: 4, mobLevels:{min: 200}, name: 'Deep Dungeon Cyclops / Deep Dungeon Ghost' },
      ]
    },
    {
      imageSrc: subFire,
      coordinates: [
        { x: 33, y: 120, quantityMobs: 5, mobLevels:{min: 200}, name: 'Deep Dungeon Cyclops / Deep Dungeon Ghost' },
        { x: 53, y: 126, quantityMobs: 6, mobLevels:{min: 200}, name: 'Deep Dungeon Cyclops / Deep Dungeon Ghost' },
        { x: 53, y: 72, quantityMobs: 5, mobLevels:{min: 200}, name: 'Deep Dungeon Cyclops / Deep Dungeon Ghost' },
        { x: 64, y: 83, quantityMobs: 5, mobLevels:{min: 200}, name: 'Deep Dungeon Cyclops / Deep Dungeon Ghost' },
      ]
    },
    {
      imageSrc: hsFire,
      coordinates: [
        { x: 16, y: 106, quantityMobs: 7, mobLevels:{min: 200}, name: 'Deep Dungeon Cyclops / Deep Dungeon Ghost' },
      ]
    },
    {
      imageSrc: spotWind,
      coordinates: [
        { x: 172, y: 96, quantityMobs: 4, mobLevels:{min: 200}, name: 'Deep Dungeon Cyclops / Deep Dungeon Ghost' },
        { x: 169, y: 75, quantityMobs: 4, mobLevels:{min: 200}, name: 'Deep Dungeon Cyclops / Deep Dungeon Ghost' },
      ]
    },
    {
      imageSrc: subWind,
      coordinates: [
        { x: 166, y: 6, quantityMobs: 5, mobLevels:{min: 200}, name: 'Deep Dungeon Cyclops / Deep Dungeon Ghost' },
        { x: 200, y: 50, quantityMobs: 6, mobLevels:{min: 200}, name: 'Deep Dungeon Cyclops / Deep Dungeon Ghost' },
        { x: 245, y: 35, quantityMobs: 5, mobLevels:{min: 200}, name: 'Deep Dungeon Cyclops / Deep Dungeon Ghost' },
        { x: 234, y: 7, quantityMobs: 5, mobLevels:{min: 200}, name: 'Deep Dungeon Cyclops / Deep Dungeon Ghost' },
      ]
    },
    {
      imageSrc: hsWind,
      coordinates: [
        { x: 167, y: 22, quantityMobs: 7, mobLevels:{min: 200}, name: 'Deep Dungeon Cyclops / Deep Dungeon Ghost' },
      ]
    },
    {
      imageSrc: spotWater,
      coordinates: [
        { x: 9, y: 36, quantityMobs: 4, mobLevels:{min: 200}, name: 'Deep Dungeon Cyclops / Deep Dungeon Ghost' },
        { x: 40, y: 5, quantityMobs: 4, mobLevels:{min: 200}, name: 'Deep Dungeon Cyclops / Deep Dungeon Ghost' },
      ]
    },
    {
      imageSrc: subWater,
      coordinates: [
        { x: 54, y: 11, quantityMobs: 5, mobLevels:{min: 200}, name: 'Deep Dungeon Cyclops / Deep Dungeon Ghost' },
        { x: 71, y: 61, quantityMobs: 5, mobLevels:{min: 200}, name: 'Deep Dungeon Cyclops / Deep Dungeon Ghost' },
        { x: 98, y: 77, quantityMobs: 5, mobLevels:{min: 200}, name: 'Deep Dungeon Cyclops / Deep Dungeon Ghost' },
        { x: 117, y: 60, quantityMobs: 5, mobLevels:{min: 200}, name: 'Deep Dungeon Cyclops / Deep Dungeon Ghost' },
      ]
    },
    {
      imageSrc: hsWater,
      coordinates: [
        { x: 74, y: 27, quantityMobs: 7, mobLevels:{min: 200}, name: 'Deep Dungeon Cyclops / Deep Dungeon Ghost' },
      ]
    },
    {
      imageSrc: spotDark,
      coordinates: [
        { x: 162, y: 110, quantityMobs: 4, mobLevels:{min: 200}, name: 'Deep Dungeon Cyclops / Deep Dungeon Ghost' },
        { x: 179, y: 110, quantityMobs: 4, mobLevels:{min: 200}, name: 'Deep Dungeon Cyclops / Deep Dungeon Ghost' },
      ]
    },
    {
      imageSrc: subDark,
      coordinates: [
        { x: 246, y: 116, quantityMobs: 5, mobLevels:{min: 200}, name: 'Deep Dungeon Cyclops / Deep Dungeon Ghost' },
        { x: 215, y: 89, quantityMobs: 6, mobLevels:{min: 200}, name: 'Deep Dungeon Cyclops / Deep Dungeon Ghost' },
        { x: 225, y: 69, quantityMobs: 5, mobLevels:{min: 200}, name: 'Deep Dungeon Cyclops / Deep Dungeon Ghost' },
        { x: 245, y: 78, quantityMobs: 5, mobLevels:{min: 200}, name: 'Deep Dungeon Cyclops / Deep Dungeon Ghost' },
      ]
    },
    {
      imageSrc: hsDark,
      coordinates: [
        { x: 221, y: 119, quantityMobs: 7, mobLevels:{min: 200}, name: 'Deep Dungeon Cyclops / Deep Dungeon Ghost' },
      ]
    },
    {
      imageSrc: spotEarth,
      coordinates: [
        { x: 76, y: 9, quantityMobs: 4, mobLevels:{min: 200}, name: 'Deep Dungeon Cyclops / Deep Dungeon Ghost' },
        { x: 91, y: 20, quantityMobs: 4, mobLevels:{min: 200}, name: 'Deep Dungeon Cyclops / Deep Dungeon Ghost' },
      ]
    },
    {
      imageSrc: subEarth,
      coordinates: [
        { x: 108, y: 24, quantityMobs: 5, mobLevels:{min: 200}, name: 'Deep Dungeon Cyclops / Deep Dungeon Ghost' },
        { x: 113, y: 41, quantityMobs: 5, mobLevels:{min: 200}, name: 'Deep Dungeon Cyclops / Deep Dungeon Ghost' },
        { x: 122, y: 6, quantityMobs: 5, mobLevels:{min: 200}, name: 'Deep Dungeon Cyclops / Deep Dungeon Ghost' },
        { x: 136, y: 17, quantityMobs: 6, mobLevels:{min: 200}, name: 'Deep Dungeon Cyclops / Deep Dungeon Ghost' },
      ]
    },
    {
      imageSrc: hsEarth,
      coordinates: [
        { x: 143, y: 55, quantityMobs: 7, mobLevels:{min: 200}, name: 'Deep Dungeon Cyclops / Deep Dungeon Ghost' },
      ]
    },
  ],
}

const DeepDungeon2 = () => <MuMap {...mapConfigs} />

export default DeepDungeon2