import React from "react"

import MuMap from '.';
import imageMap from '../../images/Mu/Data/Interface/GFx/NaviMap/Navimap119.png';

import hsDark from '../../images/markers/hs-dark.png';
import hsFire from '../../images/markers/hs-fire.png';
import hsWater from '../../images/markers/hs-water.png';
import hsWind from '../../images/markers/hs-wind.png';
import hsEarth from '../../images/markers/hs-earth.png';

import subDark from '../../images/markers/sub-dark.png';
import subEarth from '../../images/markers/sub-earth.png';
import subFire from '../../images/markers/sub-fire.png';
import subWater from '../../images/markers/sub-water.png';
import subWind from '../../images/markers/sub-wind.png';

import spotDark from '../../images/markers/spot-dark.png';
import spotEarth from '../../images/markers/spot-earth.png';
import spotFire from '../../images/markers/spot-fire.png';
import spotWater from '../../images/markers/spot-water.png';
import spotWind from '../../images/markers/spot-wind.png';

const mapConfigs = {
  name: "Deep Dungeon 3",
  center: [64, 160],
  url: imageMap,
  markers: [
    {
      imageSrc: spotWater,
      coordinates: [
        { x: 7, y: 100, quantityMobs: 4, mobLevels:{min: 225}, name: 'Deep Dungeon Skeleton Archer / Deep Dungeon Hell Hound' },
        { x: 34, y: 104, quantityMobs: 4, mobLevels:{min: 225}, name: 'Deep Dungeon Skeleton Archer / Deep Dungeon Hell Hound' },
        { x: 84, y: 119, quantityMobs: 4, mobLevels:{min: 225}, name: 'Deep Dungeon Skeleton Archer / Deep Dungeon Hell Hound' },
      ]
    },
    {
      imageSrc: subWater,
      coordinates: [
        { x: 44, y: 157, quantityMobs: 5, mobLevels:{min: 225}, name: 'Deep Dungeon Skeleton Archer / Deep Dungeon Hell Hound' },
        { x: 41, y: 89, quantityMobs: 6, mobLevels:{min: 225}, name: 'Deep Dungeon Skeleton Archer / Deep Dungeon Hell Hound' },
      ]
    },
    {
      imageSrc: hsWater,
      coordinates: [
        { x: 9, y: 139, quantityMobs: 7, mobLevels:{min: 225}, name: 'Deep Dungeon Skeleton Archer / Deep Dungeon Hell Hound' },
      ]
    },
    {
      imageSrc: spotFire,
      coordinates: [
        { x: 49, y: 28, quantityMobs: 4, mobLevels:{min: 225}, name: 'Deep Dungeon Skeleton Archer / Deep Dungeon Hell Hound' },
        { x: 54, y: 78, quantityMobs: 4, mobLevels:{min: 225}, name: 'Deep Dungeon Skeleton Archer / Deep Dungeon Hell Hound' },
        { x: 76, y: 74, quantityMobs: 4, mobLevels:{min: 225}, name: 'Deep Dungeon Skeleton Archer / Deep Dungeon Hell Hound' },
      ]
    },
    {
      imageSrc: subFire,
      coordinates: [
        { x: 65, y: 54, quantityMobs: 6, mobLevels:{min: 225}, name: 'Deep Dungeon Skeleton Archer / Deep Dungeon Hell Hound' },
        { x: 83, y: 101, quantityMobs: 5, mobLevels:{min: 225}, name: 'Deep Dungeon Skeleton Archer / Deep Dungeon Hell Hound' },
        { x: 105, y: 114, quantityMobs: 5, mobLevels:{min: 225}, name: 'Deep Dungeon Skeleton Archer / Deep Dungeon Hell Hound' },
      ]
    },
    {
      imageSrc: hsFire,
      coordinates: [
        { x: 43, y: 51, quantityMobs: 7, mobLevels:{min: 225}, name: 'Deep Dungeon Skeleton Archer / Deep Dungeon Hell Hound' },
      ]
    },
    {
      imageSrc: spotWind,
      coordinates: [
        { x: 89, y: 174, quantityMobs: 4, mobLevels:{min: 225}, name: 'Deep Dungeon Skeleton Archer / Deep Dungeon Hell Hound' },
        { x: 103, y: 182, quantityMobs: 4, mobLevels:{min: 225}, name: 'Deep Dungeon Skeleton Archer / Deep Dungeon Hell Hound' },
      ]
    },
    {
      imageSrc: subWind,
      coordinates: [
        { x: 121, y: 215, quantityMobs: 5, mobLevels:{min: 225}, name: 'Deep Dungeon Skeleton Archer / Deep Dungeon Hell Hound' },
        { x: 135, y: 228, quantityMobs: 5, mobLevels:{min: 225}, name: 'Deep Dungeon Skeleton Archer / Deep Dungeon Hell Hound' },
        { x: 165, y: 240, quantityMobs: 5, mobLevels:{min: 225}, name: 'Deep Dungeon Skeleton Archer / Deep Dungeon Hell Hound' },
      ]
    },
    {
      imageSrc: hsWind,
      coordinates: [
        { x: 71, y: 243, quantityMobs: 7, mobLevels:{min: 225}, name: 'Deep Dungeon Skeleton Archer / Deep Dungeon Hell Hound' },
      ]
    },
    {
      imageSrc: spotDark,
      coordinates: [
        { x: 125, y: 160, quantityMobs: 4, mobLevels:{min: 225}, name: 'Deep Dungeon Skeleton Archer / Deep Dungeon Hell Hound' },
        { x: 153, y: 200, quantityMobs: 4, mobLevels:{min: 225}, name: 'Deep Dungeon Skeleton Archer / Deep Dungeon Hell Hound' },
        { x: 204, y: 181, quantityMobs: 4, mobLevels:{min: 225}, name: 'Deep Dungeon Skeleton Archer / Deep Dungeon Hell Hound' },
      ]
    },
    {
      imageSrc: subDark,
      coordinates: [
        { x: 177, y: 199, quantityMobs: 6, mobLevels:{min: 225}, name: 'Deep Dungeon Skeleton Archer / Deep Dungeon Hell Hound' },
        { x: 206, y: 217, quantityMobs: 5, mobLevels:{min: 225}, name: 'Deep Dungeon Skeleton Archer / Deep Dungeon Hell Hound' },
        { x: 188, y: 170, quantityMobs: 5, mobLevels:{min: 225}, name: 'Deep Dungeon Skeleton Archer / Deep Dungeon Hell Hound' },
      ]
    },
    {
      imageSrc: hsDark,
      coordinates: [
        { x: 164, y: 158, quantityMobs: 7, mobLevels:{min: 225}, name: 'Deep Dungeon Skeleton Archer / Deep Dungeon Hell Hound' },
      ]
    },
    {
      imageSrc: spotEarth,
      coordinates: [
        { x: 60, y: 198, quantityMobs: 4, mobLevels:{min: 225}, name: 'Deep Dungeon Skeleton Archer / Deep Dungeon Hell Hound' },
        { x: 61, y: 176, quantityMobs: 4, mobLevels:{min: 225}, name: 'Deep Dungeon Skeleton Archer / Deep Dungeon Hell Hound' },
        { x: 89, y: 154, quantityMobs: 4, mobLevels:{min: 225}, name: 'Deep Dungeon Skeleton Archer / Deep Dungeon Hell Hound' },
      ]
    },
    {
      imageSrc: subEarth,
      coordinates: [
        { x: 12, y: 211, quantityMobs: 5, mobLevels:{min: 225}, name: 'Deep Dungeon Skeleton Archer / Deep Dungeon Hell Hound' },
        { x: 20, y: 191, quantityMobs: 5, mobLevels:{min: 225}, name: 'Deep Dungeon Skeleton Archer / Deep Dungeon Hell Hound' },
        { x: 18, y: 173, quantityMobs: 6, mobLevels:{min: 225}, name: 'Deep Dungeon Skeleton Archer / Deep Dungeon Hell Hound' },
      ]
    },
    {
      imageSrc: hsEarth,
      coordinates: [
        { x: 27, y: 226, quantityMobs: 7, mobLevels:{min: 225}, name: 'Deep Dungeon Skeleton Archer / Deep Dungeon Hell Hound' },
      ]
    },
  ],
}

const DeepDungeon3 = () => <MuMap {...mapConfigs} />

export default DeepDungeon3