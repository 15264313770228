import React from "react"

import MuMap from '.';
import imageMap from '../../images/Mu/Data/Interface/GFx/NaviMap/Navimap64.png';

import hsNormal from '../../images/markers/hs-normal.png';
import subNormal from '../../images/markers/sub-normal.png';
import spotNormal from '../../images/markers/spot-normal.png';

const mapConfigs = {
  name: "Vulcanus",
  url: imageMap,
  markers: [
    {
      imageSrc: spotNormal,
      coordinates: [
        { x: 91, y: 180, quantityMobs: 3, mobLevels:{min: 93, max: 100}, name: 'Ash Slaughterer / Resurrected Gladiator' },
        { x: 178, y: 110, quantityMobs: 3, mobLevels:{min: 107, max: 109}, name: 'Blood Assassin / Cruel Blood Assassin' },
        { x: 210, y: 27, quantityMobs: 3, mobLevels:{min: 111, max: 113}, name: 'Ruthless Lava Giant / Burning Lava Giant' },
      ]
    },
    {
      imageSrc: subNormal,
      coordinates: [
        { x: 43, y: 224, quantityMobs: 4, mobLevels:{min: 93, max: 121}, name: 'Ash Slaughterer / Resurrected Gladiator / Resurrected Gladiator (Hero)' },
        { x: 76, y: 218, quantityMobs: 4, mobLevels:{min: 100, max: 121}, name: 'Ash Slaughterer / Resurrected Gladiator (Hero)' },
        { x: 99, y: 216, quantityMobs: 4, mobLevels:{min: 100, max: 121}, name: 'Ash Slaughterer / Resurrected Gladiator (Hero)' },
        { x: 31, y: 175, quantityMobs: 4, mobLevels:{min: 93, max: 121}, name: 'Ash Slaughterer / Resurrected Gladiator / Resurrected Gladiator (Hero)' },
        { x: 84, y: 201, quantityMobs: 4, mobLevels:{min: 100, max: 121}, name: 'Ash Slaughterer / Resurrected Gladiator (Hero)' },
        { x: 31, y: 139, quantityMobs: 4, mobLevels:{min: 93, max: 121}, name: 'Ash Slaughterer / Resurrected Gladiator / Resurrected Gladiator (Hero)' },
        { x: 60, y: 138, quantityMobs: 4, mobLevels:{min: 93, max: 121}, name: 'Ash Slaughterer / Resurrected Gladiator / Resurrected Gladiator (Hero)' },
        { x: 35, y: 87, quantityMobs: 4, mobLevels:{min: 90, max: 120}, name: 'Zombie Fighter / Resurrected Gladiator / Zombie Fighter (Hero)' },
        { x: 36, y: 60, quantityMobs: 4, mobLevels:{min: 90, max: 120}, name: 'Zombie Fighter / Resurrected Gladiator / Zombie Fighter (Hero)' },
        { x: 37, y: 41, quantityMobs: 4, mobLevels:{min: 90, max: 120}, name: 'Zombie Fighter / Resurrected Gladiator / Zombie Fighter (Hero)' },
        { x: 116, y: 83, quantityMobs: 4, mobLevels:{min: 90, max: 120}, name: 'Zombie Fighter / Zombie Fighter (Hero)' },
        { x: 114, y: 62, quantityMobs: 4, mobLevels:{min: 90, max: 120}, name: 'Zombie Fighter / Resurrected Gladiator / Zombie Fighter (Hero)' },
        { x: 195, y: 15, quantityMobs: 4, mobLevels:{min: 111, max: 124}, name: 'Ruthless Lava Giant (Hero) / Ruthless Lava Giant / Burning Lava Giant' },
        { x: 215, y: 48, quantityMobs: 4, mobLevels:{min: 111, max: 124}, name: 'Ruthless Lava Giant (Hero) / Ruthless Lava Giant / Burning Lava Giant' },
        { x: 234, y: 59, quantityMobs: 4, mobLevels:{min: 111, max: 124}, name: 'Ruthless Lava Giant (Hero) / Ruthless Lava Giant / Burning Lava Giant' },
      ]
    },
    {
      imageSrc: hsNormal,
      coordinates: [
        { x: 37, y: 202, quantityMobs: 5, mobLevels:{min: 93, max: 121}, name: 'Ash Slaughterer / Resurrected Gladiator / Resurrected Gladiator (Hero)' },
        { x: 66, y: 160, quantityMobs: 5, mobLevels:{min: 93, max: 121}, name: 'Ash Slaughterer / Resurrected Gladiator / Resurrected Gladiator (Hero)' },
        { x: 77, y: 80, quantityMobs: 5, mobLevels:{min: 90, max: 120}, name: 'Zombie Fighter / Resurrected Gladiator / Zombie Fighter (Hero)' },
        { x: 58, y: 64, quantityMobs: 5, mobLevels:{min: 90, max: 120}, name: 'Zombie Fighter / Resurrected Gladiator / Zombie Fighter (Hero)' },
        { x: 82, y: 41, quantityMobs: 5, mobLevels:{min: 90, max: 120}, name: 'Zombie Fighter / Resurrected Gladiator / Zombie Fighter (Hero)' },
        { x: 47, y: 18, quantityMobs: 5, mobLevels:{min: 90, max: 120}, name: 'Zombie Fighter / Resurrected Gladiator / Zombie Fighter (Hero)' },
        { x: 76, y: 23, quantityMobs: 5, mobLevels:{min: 90, max: 120}, name: 'Zombie Fighter / Resurrected Gladiator / Zombie Fighter (Hero)' },
        { x: 98, y: 27, quantityMobs: 5, mobLevels:{min: 90, max: 120}, name: 'Zombie Fighter / Resurrected Gladiator / Zombie Fighter (Hero)' },
        { x: 158, y: 55, quantityMobs: 5, mobLevels:{min: 111, max: 124}, name: 'Ruthless Lava Giant (Hero) / Ruthless Lava Giant / Burning Lava Giant' },
        { x: 236, y: 120, quantityMobs: 6, mobLevels:{min: 107, max: 123}, name: 'Blood Assassin / Cruel Blood Assassin / Cruel Blood Assassin (Hero)' },
        { x: 204, y: 222, quantityMobs: 5, mobLevels:{min: 100, max: 120}, name: 'Ash Slaughterer / Ash Slaughterer (Hero) / Blood Assassin' },
        { x: 180, y: 225, quantityMobs: 5, mobLevels:{min: 100, max: 120}, name: 'Ash Slaughterer / Ash Slaughterer (Hero) / Blood Assassin' },
      ]
    },
  ],
}

const Vulcanus = () => <MuMap {...mapConfigs} />

export default Vulcanus