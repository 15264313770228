import React from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import {
  TextField as MUITextField,
} from "@mui/material";


const TextField = ({ label, ...props }) => {
  const [field, meta] = useField(props);

  return (
    <MUITextField
      label={label}
      {...field}
      {...props}
      error={meta.touched && Boolean(meta.error)}
      helperText={meta.touched && meta.error}
    />
  );
};

TextField.propTypes = {
  label: PropTypes.string.isRequired,
  props: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }),
};

export default TextField
