import React from "react"

import MuMap from '.';
import imageMap from '../../images/Mu/Data/Interface/GFx/NaviMap/Navimap39.png';

import hsNormal from '../../images/markers/hs-normal.png';
import spotNormal from '../../images/markers/spot-normal.png';

const mapConfigs = {
  name: "Kanturu Relics",
  url: imageMap,
  zoom: 1.5,
  zoomMobile: 1,
  markers: [
    {
      imageSrc: spotNormal,
      coordinates: [
        { x: 119, y: 131, quantityMobs: 3, mobLevels:{min: 117, max: 119}, name: "Twin Tail / Persona / Drear Fear" },
        { x: 107, y: 139, quantityMobs: 3, mobLevels:{min: 117, max: 119}, name: "Twin Tail / Persona / Drear Fear" },
      ]
    },
    {
      imageSrc: hsNormal,
      coordinates: [
        { x: 109, y: 153, quantityMobs: 5, mobLevels:{min: 118}, name: "Persona" },
      ]
    },
  ],
}

const KanturuRelics = () => <MuMap {...mapConfigs} />

export default KanturuRelics