import React from 'react';
import { Container, Box } from '@mui/material';
import AppBar from '../components/AppBar';
import PageFooter from '../components/PageFooter';
import ThemeProvider from '../themes/ThemeProvider';
import AppRoutes from '../routes/AppRoutes';

import AnimatedBg from './AnimatedBg';

const Core = () => {
  return (
    <ThemeProvider>
      <AnimatedBg>
        <Container
          maxWidth="lg"
          disableGutters
        >
          <AppBar title="muonline.app" />
        </Container>
        <Box sx={{ pt: 8 }}>
          <AppRoutes />
        </Box>
        <PageFooter />
      </AnimatedBg>
    </ThemeProvider>
  )
}

export default Core