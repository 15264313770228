import React from 'react';
import { Paper, Container, Grid, Divider, Avatar, Link } from '@mui/material';
import { Instagram, YouTube } from '@mui/icons-material';
import SmFooterImage from "../images/sm_footer.png"
import AppLogo from './AppLogo';
import MuLogo from './MuLogo';

const Copyright = ({ logo, text }) => (
  <Container
    style={{ padding: 0, paddingRight: 10 }}
    sx={{
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    }}>
    {logo && logo}
    <small style={{ paddingLeft: 24 }}>{text}</small>
  </Container>
)

const PageFooter = () => {
  const copyrightWebZen = "O logotipo MU e o logotipo Webzen são marcas registradas da © Webzen Inc. O conteúdo do jogo e os materiais são marcas comerciais e direitos autorais da © Webzen Inc e são usados com permissão.";
  const year = new Date().getFullYear();
  const copyrightMuApp = `© Copyright ${year}, muonline.app Todos os Direitos Reservados.`;

  const instagramLink = "https://www.instagram.com/muonline.app";

  return (
    <Container style={{ padding: 0, paddingTop: 48 }}>
      <Paper square>
        <Grid container>
          <Grid item xs={12} align="center">
          <Link href={instagramLink} target="_blank" rel="noreferrer">
            <Instagram sx={{ m: 2 }} />
          </Link>
            <YouTube sx={{ m: 2 }} />
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12} sm={10} sx={{ p: 2 }}>
            <Copyright logo={<MuLogo sx={{ width: 64, height: 64 }} />} text={copyrightWebZen} />
            <Copyright logo={<AppLogo sx={{ width: 64, height: 64 }} />} text={copyrightMuApp} />
          </Grid>
          <Grid item xs={12} sm={2} align="center">
            <Avatar
              sx={{ width: 95, height: 160, m: 2 }}
              src={SmFooterImage}
              variant="square"
            />
          </Grid>
        </Grid>
      </Paper>
    </Container>
  )
}

export default PageFooter