import React from "react"

import MuMap from '.';
import imageMap from '../../images/Mu/Data/Interface/GFx/NaviMap/Navimap34.png';

import hsNormal from '../../images/markers/hs-normal.png';
import subNormal from '../../images/markers/sub-normal.png';
import spotNormal from '../../images/markers/spot-normal.png';

const mapConfigs = {
  name: "Aida",
  url: imageMap,
  markers: [
    {
      imageSrc: spotNormal,
      coordinates: [
        // Aida 1
        { x: 166, y: 78, quantityMobs: 3, mobLevels:{min: 74, max: 78}, name: 'Forest Orc / Death Rider' },
        { x: 188, y: 94, quantityMobs: 3, mobLevels:{min: 72, max: 78}, name: 'Death Tree / Forest Orc / Death Rider' },
        { x: 227, y: 13, quantityMobs: 3, mobLevels:{min: 72, max: 78}, name: 'Death Tree / Death Rider' },
        { x: 229, y: 63, quantityMobs: 3, mobLevels:{min: 74, max: 78}, name: 'Forest Orc / Death Rider' },
        // Aida 2
        { x: 163, y: 168, quantityMobs: 3, mobLevels:{min: 84}, name: 'Blue Golem' },
        { x: 218, y: 234, quantityMobs: 3, mobLevels:{min: 114, max: 117}, name: 'Bloody Death Tree / Bloody Death Ride' },
        { x: 237, y: 233, quantityMobs: 3, mobLevels:{min: 114, max: 117}, name: 'Bloody Death Tree / Bloody Death Ride' },
      ]
    },
    {
      imageSrc: subNormal,
      coordinates: [
        // Aida 1
        { x: 234, y: 37, quantityMobs: 4, mobLevels:{min: 74, max: 78}, name: 'Forest Orc / Death Rider' },
        // Aida 2
        { x: 74, y: 223, quantityMobs: 4, mobLevels:{min: 114, max: 115}, name: 'Bloody Death Tree / Bloody Forest Orc' },
        { x: 134, y: 190, quantityMobs: 4, mobLevels:{min: 115, max: 117}, name: 'Bloody Forest Orc / Bloody Death Ride' },
        { x: 207, y: 207, quantityMobs: 4, mobLevels:{min: 114, max: 117}, name: 'Bloody Death Tree / Bloody Forest Orc / Bloody Death Ride' },
      ]
    },
    {
      imageSrc: hsNormal,
      coordinates: [
        // Aida 1
        { x: 214, y: 33, quantityMobs: 5, mobLevels:{min: 72, max: 78}, name: 'Death Tree / Death Rider' },
        // Aida 2
        { x: 26, y: 184, quantityMobs: 5, mobLevels:{min: 115, max: 120}, name: 'Bloody Witch Queen / Bloody Forest Orc / Bloody Death Ride' },
        { x: 50, y: 123, quantityMobs: 5, mobLevels:{min: 115, max: 120}, name: 'Bloody Witch Queen / Bloody Forest Orc / Bloody Death Ride' },
        { x: 43, y: 222, quantityMobs: 5, mobLevels:{min: 114, max: 120}, name: 'Bloody Death Tree / Bloody Forest Orc / Bloody Death Ride' },
        { x: 131, y: 222, quantityMobs: 5, mobLevels:{min: 115, max: 117}, name: 'Bloody Forest Orc / Bloody Death Ride' },
        { x: 159, y: 231, quantityMobs: 5, mobLevels:{min: 114, max: 117}, name: 'Bloody Death Tree / Bloody Forest Orc / Bloody Death Ride' },
        { x: 191, y: 147, quantityMobs: 5, mobLevels:{min: 78, max: 84}, name: 'Blue Golem / Death Rider' },
      ]
    },
  ],
}

const Aida = () => <MuMap {...mapConfigs} />

export default Aida