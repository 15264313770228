import React, { useState } from "react";
import {
  Paper,
  Grid,
  Box,
} from "@mui/material";
import MuBg from "../../images/mu11.jpg";
import { EXP } from "./expData";
import { sumExpValues, expRange, calculateTimeForExperience, secondsToDHMS } from "./calculator-utils";
import CalculatorForm from "./CalculatorForm";
import CalculatorResponse from "./CalculatorResponse";

const style = {
  BoxBg: {
    backgroundImage: `url(${MuBg})`,
    backgroundPosition: "right",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
};

const LevelTimeCalculator = () => {
  const [resultMessage, setResultMessage] = useState("");
  
  const daysToMilliseconds = days => days * 24 * 60 * 60 * 1000;
  const hoursToMilliseconds = hours => hours * 60 * 60 * 1000;
  const minutesToMilliseconds = minutes => minutes * 60 * 1000;
  const secondsToMilliseconds = seconds => seconds * 1000;

  const handleSubmit = ({
    levelFrom,
    levelTo,
    experiencePerSecond,
    bar,
  }) => {
    const targetExperience = sumExpValues(expRange(EXP, levelFrom, levelTo), bar);
    const { days, hours, minutes, seconds } = secondsToDHMS(calculateTimeForExperience(targetExperience, experiencePerSecond));

    // Calcular a data futura
    const currentDate = new Date();
    const futureDate = new Date(currentDate.getTime() + daysToMilliseconds(days) + hoursToMilliseconds(hours) + minutesToMilliseconds(minutes) + secondsToMilliseconds(seconds));

    // Formatando a hora para o padrão de 24 horas
    const formattedHours = futureDate.getHours().toString().padStart(2, '0');
    const formattedMinutes = futureDate.getMinutes().toString().padStart(2, '0');
    
    setResultMessage(`
      Do level ${levelFrom} ao ${levelTo}, ganhando ${experiencePerSecond} xp/s
      Serão necessários ${days} dias, ${hours} horas, ${minutes} minutos e ${seconds} segundos
      Você vai alcançar o level ${levelTo} em ${futureDate.toLocaleDateString()} às ${formattedHours}:${formattedMinutes}
    `);
  }

  return (
    <Paper>
      <Grid container>
        <Grid item xs={12} sm={4} p={2}>
          <CalculatorForm onSubmit={handleSubmit} />
        </Grid>
        <Grid item xs={12} sm={8}>
          <Box style={style.BoxBg} sx={{ height: 410 }} p={2}>
            {resultMessage && <CalculatorResponse message={resultMessage}/>}
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default LevelTimeCalculator;
