import React from "react"

import MuMap from '.';
import imageMap from '../../images/Mu/Data/Interface/GFx/NaviMap/Navimap101.png';

import hsDark from '../../images/markers/hs-dark.png';
import hsFire from '../../images/markers/hs-fire.png';
import hsWater from '../../images/markers/hs-water.png';
import hsWind from '../../images/markers/hs-wind.png';
import hsEarth from '../../images/markers/hs-earth.png';

import subDark from '../../images/markers/sub-dark.png';
import subEarth from '../../images/markers/sub-earth.png';
import subFire from '../../images/markers/sub-fire.png';
import subWater from '../../images/markers/sub-water.png';
import subWind from '../../images/markers/sub-wind.png';

import spotDark from '../../images/markers/spot-dark.png';
import spotEarth from '../../images/markers/spot-earth.png';
import spotFire from '../../images/markers/spot-fire.png';
import spotWater from '../../images/markers/spot-water.png';
import spotWind from '../../images/markers/spot-wind.png';

const mapConfigs = {
  name: "Archeron - Uruk Mountain",
  url: imageMap,
  markers: [
    {
      imageSrc: spotFire,
      coordinates: [
        { x: 36, y: 41, quantityMobs: 3, mobLevels:{min: 115, max: 119}, name: 'Uruk Devil Fairy / Uruk Elemental Beast / Uruk Elemental Knight' },
      ]
    },
    {
      imageSrc: spotWind,
      coordinates: [
        { x: 138, y: 36, quantityMobs: 3, mobLevels:{min: 115, max: 119}, name: 'Uruk Devil Fairy / Uruk Elemental Beast / Uruk Elemental Knight' },
      ]
    },
    {
      imageSrc: spotWater,
      coordinates: [
        { x: 114, y: 145, quantityMobs: 3, mobLevels:{min: 115, max: 119}, name: 'Uruk Devil Fairy / Uruk Elemental Knight' },
      ]
    },
    {
      imageSrc: subFire,
      coordinates: [
        { x: 88, y: 94, quantityMobs: 4, mobLevels:{min: 115, max: 119}, name: 'Uruk Devil Fairy / Uruk Elemental Beast / Uruk Elemental Knight' },
        { x: 101, y: 11, quantityMobs: 4, mobLevels:{min: 115, max: 119}, name: 'Uruk Devil Fairy / Uruk Elemental Beast / Uruk Elemental Knight' },
      ]
    },
    {
      imageSrc: hsFire,
      coordinates: [
        { x: 98, y: 70, quantityMobs: 5, mobLevels:{min: 115, max: 119}, name: 'Uruk Devil Fairy / Uruk Elemental Beast / Uruk Elemental Knight' },
      ]
    },
    {
      imageSrc: subWater,
      coordinates: [
        { x: 134, y: 153, quantityMobs: 4, mobLevels:{min: 115, max: 119}, name: 'Uruk Devil Fairy / Uruk Elemental Beast / Uruk Elemental Knight' },
        { x: 108, y: 199, quantityMobs: 4, mobLevels:{min: 115, max: 119}, name: 'Uruk Devil Fairy / Uruk Elemental Beast / Uruk Elemental Knight' },
      ]
    },
    {
      imageSrc: subWind,
      coordinates: [
        { x: 203, y: 51, quantityMobs: 4, mobLevels:{min: 115, max: 117}, name: 'Uruk Devil Fairy / Uruk Elemental Beast' },
        { x: 163, y: 82, quantityMobs: 4, mobLevels:{min: 115, max: 119}, name: 'Uruk Devil Fairy / Uruk Elemental Beast / Uruk Elemental Knight' },
      ]
    },
    {
      imageSrc: spotEarth,
      coordinates: [
        { x: 234, y: 168, quantityMobs: 3, mobLevels:{min: 115, max: 119}, name: 'Uruk Devil Fairy / Uruk Elemental Beast / Uruk Elemental Knight' },
      ]
    },
    {
      imageSrc: subDark,
      coordinates: [
        { x: 199, y: 132, quantityMobs: 4, mobLevels:{min: 115, max: 119}, name: 'Uruk Devil Fairy / Uruk Elemental Beast / Uruk Elemental Knight' },
        { x: 175, y: 130, quantityMobs: 4, mobLevels:{min: 115, max: 119}, name: 'Uruk Devil Fairy / Uruk Elemental Beast / Uruk Elemental Knight' },
      ]
    },
    {
      imageSrc: hsDark,
      coordinates: [
        { x: 205, y: 111, quantityMobs: 5, mobLevels:{min: 115, max: 119}, name: 'Uruk Devil Fairy / Uruk Elemental Beast / Uruk Elemental Knight' },
      ]
    },
    {
      imageSrc: spotDark,
      coordinates: [
        { x: 220, y: 96, quantityMobs: 3, mobLevels:{min: 115, max: 119}, name: 'Uruk Devil Fairy / Uruk Elemental Beast / Uruk Elemental Knight' },
      ]
    },
    {
      imageSrc: subEarth,
      coordinates: [
        { x: 239, y: 135, quantityMobs: 4, mobLevels:{min: 115, max: 117}, name: 'Uruk Devil Fairy / Uruk Elemental Beast' },
        { x: 180, y: 193, quantityMobs: 4, mobLevels:{min: 115, max: 117}, name: 'Uruk Devil Fairy / Uruk Elemental Beast' },
      ]
    },
    {
      imageSrc: hsEarth,
      coordinates: [
        { x: 213, y: 213, quantityMobs: 5, mobLevels:{min: 115, max: 119}, name: 'Uruk Devil Fairy / Uruk Elemental Beast / Uruk Elemental Knight' },
      ]
    },
    {
      imageSrc: hsWater,
      coordinates: [
        { x: 79, y: 131, quantityMobs: 5, mobLevels:{min: 115, max: 119}, name: 'Uruk Devil Fairy / Uruk Elemental Beast / Uruk Elemental Knight' },
      ]
    },
    {
      imageSrc: hsWind,
      coordinates: [
        { x: 163, y: 55, quantityMobs: 5, mobLevels:{min: 115, max: 119}, name: 'Uruk Devil Fairy / Uruk Elemental Beast / Uruk Elemental Knight' },
      ]
    },
  ],
}

const ArcheronUrukMountain = () => <MuMap {...mapConfigs} />

export default ArcheronUrukMountain