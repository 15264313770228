import React from "react";
import {
  Paper,
  Stack,
  Typography,
} from "@mui/material";

const CalculatorResponse = ({ message }) => (
  <Paper sx={{ backgroundColor: 'rgba(0,0,0,0.7)', maxWidth: 450 }}>
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="flex-start"
      p={2}
    >
      <Typography>{message}</Typography>
    </Stack>
  </Paper>
);

export default CalculatorResponse;
