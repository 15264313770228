import React from "react"

import MuMap from '.';
import imageMap from '../../images/Mu/Data/Interface/GFx/NaviMap/Navimap123.png';

import hsDark from '../../images/markers/hs-dark.png';
import hsFire from '../../images/markers/hs-fire.png';
import hsWater from '../../images/markers/hs-water.png';
import hsWind from '../../images/markers/hs-wind.png';
import hsEarth from '../../images/markers/hs-earth.png';

import subDark from '../../images/markers/sub-dark.png';
import subEarth from '../../images/markers/sub-earth.png';
import subFire from '../../images/markers/sub-fire.png';
import subWater from '../../images/markers/sub-water.png';
import subWind from '../../images/markers/sub-wind.png';

import spotDark from '../../images/markers/spot-dark.png';
import spotEarth from '../../images/markers/spot-earth.png';
import spotFire from '../../images/markers/spot-fire.png';
import spotWater from '../../images/markers/spot-water.png';
import spotWind from '../../images/markers/spot-wind.png';

const mapConfigs = {
  name: "Swamp of Darkness",
  url: imageMap,
  markers: [
    {
      imageSrc: spotWater,
      coordinates: [
      ]
    },
    {
      imageSrc: subWater,
      coordinates: [
        { x: 225, y: 110, quantityMobs: 5, mobLevels:{min: 275}, name: 'Wooden Beast' },
        { x: 230, y: 68, quantityMobs: 5, mobLevels:{min: 275}, name: 'Swamp Ent / Wooden Beast / Mutantile' },
        { x: 238, y: 42, quantityMobs: 6, mobLevels:{min: 275}, name: 'Swamp Ent / Wooden Beast / Mutantile' },
      ]
    },
    {
      imageSrc: hsWater,
      coordinates: [
        { x: 240, y: 115, quantityMobs: 7, mobLevels:{min: 275}, name: 'Swamp Ent / Wooden Beast / Mutantile' },
        { x: 220, y: 22, quantityMobs: 9, mobLevels:{min: 275}, name: 'Swamp Ent / Wooden Beast / Mutantile' },
      ]
    },
    {
      imageSrc: spotFire,
      coordinates: [
      ]
    },
    {
      imageSrc: subFire,
      coordinates: [
        { x: 53, y: 160, quantityMobs: 5, mobLevels:{min: 275}, name: 'Swamp Ent / Wooden Beast / Mutantile' },
        { x: 100, y: 190, quantityMobs: 6, mobLevels:{min: 275}, name: 'Swamp Ent / Wooden Beast / Mutantile' },
        { x: 91, y: 144, quantityMobs: 5, mobLevels:{min: 275}, name: 'Wooden Beast' },
        { x: 128, y: 191, quantityMobs: 6, mobLevels:{min: 275}, name: 'Swamp Ent / Wooden Beast / Mutantile' },
      ]
    },
    {
      imageSrc: hsFire,
      coordinates: [
        { x: 75, y: 221, quantityMobs: 9, mobLevels:{min: 275}, name: 'Swamp Ent / Wooden Beast / Mutantile' },
      ]
    },
    {
      imageSrc: spotWind,
      coordinates: [
      ]
    },
    {
      imageSrc: subWind,
      coordinates: [
        { x: 149, y: 184, quantityMobs: 5, mobLevels:{min: 275}, name: 'Wooden Beast' },
        { x: 193, y: 225, quantityMobs: 6, mobLevels:{min: 275}, name: 'Swamp Ent / Wooden Beast / Mutantile' },
      ]
    },
    {
      imageSrc: hsWind,
      coordinates: [
        { x: 217, y: 224, quantityMobs: 7, mobLevels:{min: 275}, name: 'Swamp Ent / Wooden Beast / Mutantile' },
        { x: 131, y: 161, quantityMobs: 9, mobLevels:{min: 275}, name: 'Swamp Ent / Wooden Beast / Mutantile' },
      ]
    },
    {
      imageSrc: spotDark,
      coordinates: [
      ]
    },
    {
      imageSrc: subDark,
      coordinates: [
        { x: 25, y: 10, quantityMobs: 6, mobLevels:{min: 275}, name: 'Swamp Ent / Wooden Beast / Mutantile' },
        { x: 152, y: 20, quantityMobs: 5, mobLevels:{min: 275}, name: 'Swamp Ent / Wooden Beast / Mutantile' },
      ]
    },
    {
      imageSrc: hsDark,
      coordinates: [
        { x: 18, y: 47, quantityMobs: 7, mobLevels:{min: 275}, name: 'Swamp Ent / Wooden Beast / Mutantile' },
        { x: 125, y: 14, quantityMobs: 9, mobLevels:{min: 275}, name: 'Swamp Ent / Wooden Beast / Mutantile' },
      ]
    },
    {
      imageSrc: spotEarth,
      coordinates: [
        { x: 103, y: 74, quantityMobs: 4, mobLevels:{min: 275}, name: 'Swamp Ent' },
      ]
    },
    {
      imageSrc: subEarth,
      coordinates: [
        { x: 85, y: 122, quantityMobs: 6, mobLevels:{min: 275}, name: 'Swamp Ent / Wooden Beast / Mutantile' },
        { x: 49, y: 51, quantityMobs: 6, mobLevels:{min: 275}, name: 'Swamp Ent / Wooden Beast / Mutantile' },
      ]
    },
    {
      imageSrc: hsEarth,
      coordinates: [
        { x: 23, y: 96, quantityMobs: 7, mobLevels:{min: 275}, name: 'Swamp Ent / Wooden Beast / Mutantile' },
        { x: 93, y: 58, quantityMobs: 9, mobLevels:{min: 275}, name: 'Swamp Ent / Wooden Beast / Mutantile' },
      ]
    },
  ],
}

const SwampOfDarkness = () => <MuMap {...mapConfigs} />

export default SwampOfDarkness