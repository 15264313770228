import React from "react"

import MuMap from '.';
import imageMap from '../../images/Mu/Data/Interface/GFx/NaviMap/Navimap04.png';

import spotNormal from '../../images/markers/spot-normal.png';

const mapConfigs = {
  name: "Noria",
  url: imageMap,
  markers: [
    {
      imageSrc: spotNormal,
      coordinates: [
        { x: 81, y: 116, quantityMobs: 3, mobLevels:{min: 8}, name: 'Elite Goblin' },
        { x: 102, y: 83, quantityMobs: 3, mobLevels:{min: 10}, name: 'Beetle Monster' },
        { x: 106, y: 40, quantityMobs: 3, mobLevels:{min: 10, max: 13}, name: 'Hunter / Beetle Monster' },
        { x: 134, y: 208, quantityMobs: 3, mobLevels:{min: 15}, name: 'Forest Monster' },
        { x: 136, y: 79, quantityMobs: 3, mobLevels:{min: 5}, name: 'Chain Scorpion' },
        { x: 229, y: 224, quantityMobs: 3, mobLevels:{min: 16}, name: 'Agon' },
      ]
    },
  ],
}

const Noria = () => <MuMap {...mapConfigs} />

export default Noria