import React from "react"

import MuMap from '.';
import imageMap from '../../images/Mu/Data/Interface/GFx/NaviMap/Navimap111.png';

import hsDark from '../../images/markers/hs-dark.png';
import hsFire from '../../images/markers/hs-fire.png';
import hsWater from '../../images/markers/hs-water.png';
import hsWind from '../../images/markers/hs-wind.png';
import hsEarth from '../../images/markers/hs-earth.png';

import subDark from '../../images/markers/sub-dark.png';
import subEarth from '../../images/markers/sub-earth.png';
import subFire from '../../images/markers/sub-fire.png';
import subNormal from '../../images/markers/sub-normal.png';
import subWater from '../../images/markers/sub-water.png';
import subWind from '../../images/markers/sub-wind.png';

import spotDark from '../../images/markers/spot-dark.png';
import spotEarth from '../../images/markers/spot-earth.png';
import spotFire from '../../images/markers/spot-fire.png';
import spotNormal from '../../images/markers/spot-normal.png';
import spotWater from '../../images/markers/spot-water.png';
import spotWind from '../../images/markers/spot-wind.png';

const mapConfigs = {
  name: "Archeron - Nars",
  url: imageMap,
  markers: [
    {
      imageSrc: spotNormal,
      coordinates: [
        { x: 212, y: 154, quantityMobs: 3, mobLevels:{min: 119, max: 120}, name: 'Nars Elemental Beast / Nars Elemental Knight' },
        { x: 192, y: 148, quantityMobs: 3, mobLevels:{min: 119, max: 120}, name: 'Nars Elemental Beast / Nars Elemental Knight' },
        { x: 214, y: 198, quantityMobs: 3, mobLevels:{min: 119}, name: 'Nars Elemental Beast' },
      ]
    },
    {
      imageSrc: subNormal,
      coordinates: [
        { x: 214, y: 131, quantityMobs: 4, mobLevels:{min: 116, max: 120}, name: 'Nars Devil Fairy / Nars Elemental Beast / Nars Elemental Knight' },
      ]
    },
    {
      imageSrc: spotFire,
      coordinates: [
        { x: 176, y: 23, quantityMobs: 3, mobLevels:{min: 116, max: 120}, name: 'Nars Devil Fairy / Nars Elemental Beast / Nars Elemental Knight' },
      ]
    },
    {
      imageSrc: subFire,
      coordinates: [
        { x: 94, y: 15, quantityMobs: 4, mobLevels:{min: 116, max: 120}, name: 'Nars Devil Fairy / Nars Elemental Beast / Nars Elemental Knight' },
        { x: 194, y: 11, quantityMobs: 4, mobLevels:{min: 116, max: 120}, name: 'Nars Devil Fairy / Nars Elemental Beast / Nars Elemental Knight' },
      ]
    },
    {
      imageSrc: hsFire,
      coordinates: [
        { x: 88, y: 37, quantityMobs: 6, mobLevels:{min: 116, max: 120}, name: 'Nars Devil Fairy / Nars Elemental Beast / Nars Elemental Knight' },
      ]
    },
    {
      imageSrc: spotWind,
      coordinates: [
        { x: 197, y: 92, quantityMobs: 3, mobLevels:{min: 116, max: 120}, name: 'Nars Devil Fairy / Nars Elemental Beast / Nars Elemental Knight' },
        { x: 202, y: 43, quantityMobs: 3, mobLevels:{min: 116, max: 119}, name: 'Nars Devil Fairy / Nars Elemental Beast' },
      ]
    },
    {
      imageSrc: subWind,
      coordinates: [
        { x: 182, y: 81, quantityMobs: 4, mobLevels:{min: 119, max: 120}, name: 'Nars Elemental Beast / Nars Elemental Knight' },
        { x: 235, y: 59, quantityMobs: 4, mobLevels:{min: 119, max: 120}, name: 'Nars Elemental Beast / Nars Elemental Knight' },
        { x: 217, y: 106, quantityMobs: 4, mobLevels:{min: 116, max: 120}, name: 'Nars Devil Fairy / Nars Elemental Beast / Nars Elemental Knight' },
      ]
    },
    {
      imageSrc: hsWind,
      coordinates: [
        { x: 229, y: 33, quantityMobs: 6, mobLevels:{min: 116, max: 120}, name: 'Nars Devil Fairy / Nars Elemental Beast / Nars Elemental Knight' },
      ]
    },
    {
      imageSrc: spotWater,
      coordinates: [
        { x: 105, y: 185, quantityMobs: 3, mobLevels:{min: 119, max: 120}, name: 'Nars Elemental Beast / Nars Elemental Knight' },
        { x: 22, y: 210, quantityMobs: 3, mobLevels:{min: 116, max: 120}, name: 'Nars Devil Fairy / Nars Elemental Beast / Nars Elemental Knight' },
        { x: 26, y: 163, quantityMobs: 3, mobLevels:{min: 119, max: 120}, name: 'Nars Elemental Beast / Nars Elemental Knight' },
        { x: 40, y: 126, quantityMobs: 3, mobLevels:{min: 119}, name: 'Nars Elemental Beast' },
      ]
    },
    {
      imageSrc: subWater,
      coordinates: [
        { x: 18, y: 133, quantityMobs: 4, mobLevels:{min: 116, max: 120}, name: 'Nars Devil Fairy / Nars Elemental Beast / Nars Elemental Knight' },
      ]
    },
    {
      imageSrc: hsWater,
      coordinates: [
        { x: 84, y: 229, quantityMobs: 7, mobLevels:{min: 116, max: 120}, name: 'Nars Devil Fairy / Nars Elemental Beast / Nars Elemental Knight' },
      ]
    },
    {
      imageSrc: hsDark,
      coordinates: [
        { x: 120, y: 83, quantityMobs: 5, mobLevels:{min: 116, max: 120}, name: 'Nars Devil Fairy / Nars Elemental Beast / Nars Elemental Knight' },
      ]
    },
    {
      imageSrc: subDark,
      coordinates: [
        { x: 18, y: 84, quantityMobs: 4, mobLevels:{min: 116, max: 120}, name: 'Nars Devil Fairy / Nars Elemental Beast / Nars Elemental Knight' },
        { x: 129, y: 61, quantityMobs: 4, mobLevels:{min: 119, max: 120}, name: 'Nars Elemental Beast / Nars Elemental Knight' },
      ]
    },
    {
      imageSrc: spotDark,
      coordinates: [
        { x: 58, y: 106, quantityMobs: 3, mobLevels:{min: 119, max: 120}, name: 'Nars Elemental Beast / Nars Elemental Knight' },
        { x: 36, y: 73, quantityMobs: 3, mobLevels:{min: 119, max: 120}, name: 'Nars Elemental Beast / Nars Elemental Knight' },
        { x: 115, y: 67, quantityMobs: 3, mobLevels:{min: 119, max: 120}, name: 'Nars Elemental Beast / Nars Elemental Knight' },
      ]
    },
    {
      imageSrc: spotEarth,
      coordinates: [
        { x: 234, y: 236, quantityMobs: 3, mobLevels:{min: 116, max: 120}, name: 'Nars Devil Fairy / Nars Elemental Beast / Nars Elemental Knight' },
        { x: 241, y: 206, quantityMobs: 3, mobLevels:{min: 116, max: 120}, name: 'Nars Devil Fairy / Nars Elemental Beast / Nars Elemental Knight' },
        { x: 144, y: 241, quantityMobs: 3, mobLevels:{min: 119, max: 120}, name: 'Nars Elemental Beast / Nars Elemental Knight' },
        { x: 155, y: 200, quantityMobs: 3, mobLevels:{min: 116, max: 119}, name: 'Nars Devil Fairy / Nars Elemental Beast' },
      ]
    },
    {
      imageSrc: subEarth,
      coordinates: [
        { x: 173, y: 231, quantityMobs: 4, mobLevels:{min: 116, max: 119}, name: 'Nars Devil Fairy / Nars Elemental Beast' },
        { x: 122, y: 236, quantityMobs: 4, mobLevels:{min: 116, max: 120}, name: 'Nars Devil Fairy / Nars Elemental Beast / Nars Elemental Knight' },
      ]
    },
    {
      imageSrc: hsEarth,
      coordinates: [
        { x: 133, y: 197, quantityMobs: 7, mobLevels:{min: 116, max: 120}, name: 'Nars Devil Fairy / Nars Elemental Beast / Nars Elemental Knight' },
      ]
    },
  ],
}

const ArcheronNars = () => <MuMap {...mapConfigs} />

export default ArcheronNars