import React from "react"

import MuMap from '.';
import imageMap from '../../images/Mu/Data/Interface/GFx/NaviMap/Navimap131.png';

import hsDark from '../../images/markers/hs-dark.png';
import hsFire from '../../images/markers/hs-fire.png';
import hsWater from '../../images/markers/hs-water.png';
import hsWind from '../../images/markers/hs-wind.png';
import hsEarth from '../../images/markers/hs-earth.png';

import subDark from '../../images/markers/sub-dark.png';
import subEarth from '../../images/markers/sub-earth.png';
import subFire from '../../images/markers/sub-fire.png';
import subWater from '../../images/markers/sub-water.png';
import subWind from '../../images/markers/sub-wind.png';

import spotDark from '../../images/markers/spot-dark.png';
import spotEarth from '../../images/markers/spot-earth.png';
import spotFire from '../../images/markers/spot-fire.png';
import spotWater from '../../images/markers/spot-water.png';
import spotWind from '../../images/markers/spot-wind.png';

const mapConfigs = {
  name: "Atlans Abyss 3",
  url: imageMap,
  markers: [
    {
      imageSrc: spotWater,
      coordinates: [
        { x: 43, y: 29, quantityMobs: 5, mobLevels:{min: 460}, name: 'Great Bahamut of Abyss / Lizard King of Abyss' },
        { x: 82, y: 58, quantityMobs: 5, mobLevels:{min: 460}, name: 'Great Bahamut of Abyss / Lizard King of Abyss' },
        { x: 129, y: 48, quantityMobs: 5, mobLevels:{min: 460}, name: 'Great Bahamut of Abyss / Lizard King of Abyss' },
        { x: 158, y: 86, quantityMobs: 5, mobLevels:{min: 460}, name: 'Great Bahamut of Abyss / Lizard King of Abyss' },
        { x: 163, y: 116, quantityMobs: 5, mobLevels:{min: 460}, name: 'Great Bahamut of Abyss / Lizard King of Abyss' },
      ]
    },
    {
      imageSrc: subWater,
      coordinates: [
        { x: 68, y: 36, quantityMobs: 5, mobLevels:{min: 460}, name: 'Great Bahamut of Abyss / Lizard King of Abyss' },
      ]
    },
    {
      imageSrc: hsWater,
      coordinates: [
        { x: 123, y: 69, quantityMobs: 9, mobLevels:{min: 460}, name: 'Great Bahamut of Abyss / Lizard King of Abyss' },
        { x: 103, y: 33, quantityMobs: 9, mobLevels:{min: 460}, name: 'Great Bahamut of Abyss / Lizard King of Abyss' },
      ]
    },
    {
      imageSrc: spotFire,
      coordinates: [
        { x: 115, y: 154, quantityMobs: 5, mobLevels:{min: 460}, name: 'Great Bahamut of Abyss / Lizard King of Abyss' },
        { x: 151, y: 187, quantityMobs: 5, mobLevels:{min: 460}, name: 'Great Bahamut of Abyss / Lizard King of Abyss' },
        { x: 210, y: 174, quantityMobs: 5, mobLevels:{min: 460}, name: 'Great Bahamut of Abyss / Lizard King of Abyss' },
        { x: 235, y: 186, quantityMobs: 5, mobLevels:{min: 460}, name: 'Great Bahamut of Abyss / Lizard King of Abyss' },
        { x: 216, y: 145, quantityMobs: 5, mobLevels:{min: 460}, name: 'Great Bahamut of Abyss / Lizard King of Abyss' },
      ]
    },
    {
      imageSrc: subFire,
      coordinates: [
        { x: 210, y: 197, quantityMobs: 6, mobLevels:{min: 460}, name: 'Great Bahamut of Abyss / Lizard King of Abyss' },
      ]
    },
    {
      imageSrc: hsFire,
      coordinates: [
        { x: 177, y: 157, quantityMobs: 9, mobLevels:{min: 460}, name: 'Great Bahamut of Abyss / Lizard King of Abyss' },
        { x: 233, y: 203, quantityMobs: 9, mobLevels:{min: 460}, name: 'Great Bahamut of Abyss / Lizard King of Abyss' },
      ]
    },
    {
      imageSrc: spotWind,
      coordinates: [
        { x: 155, y: 156, quantityMobs: 5, mobLevels:{min: 460}, name: 'Great Bahamut of Abyss / Lizard King of Abyss' },
        { x: 196, y: 131, quantityMobs: 5, mobLevels:{min: 460}, name: 'Great Bahamut of Abyss / Lizard King of Abyss' },
        { x: 237, y: 80, quantityMobs: 5, mobLevels:{min: 460}, name: 'Great Bahamut of Abyss / Lizard King of Abyss' },
        { x: 198, y: 28, quantityMobs: 5, mobLevels:{min: 460}, name: 'Great Bahamut of Abyss / Lizard King of Abyss' },
        { x: 224, y: 30, quantityMobs: 5, mobLevels:{min: 460}, name: 'Great Bahamut of Abyss / Lizard King of Abyss' },
      ]
    },
    {
      imageSrc: subWind,
      coordinates: [
        { x: 198, y: 58, quantityMobs: 6, mobLevels:{min: 460}, name: 'Great Bahamut of Abyss / Lizard King of Abyss' },
      ]
    },
    {
      imageSrc: hsWind,
      coordinates: [
        { x: 174, y: 101, quantityMobs: 9, mobLevels:{min: 460}, name: 'Great Bahamut of Abyss / Lizard King of Abyss' },
        { x: 213, y: 71, quantityMobs: 9, mobLevels:{min: 460}, name: 'Great Bahamut of Abyss / Lizard King of Abyss' },
      ]
    },
    {
      imageSrc: spotDark,
      coordinates: [
        { x: 47, y: 180, quantityMobs: 5, mobLevels:{min: 460}, name: 'Great Bahamut of Abyss / Lizard King of Abyss' },
        { x: 12, y: 133, quantityMobs: 5, mobLevels:{min: 460}, name: 'Great Bahamut of Abyss / Lizard King of Abyss' },
        { x: 13, y: 112, quantityMobs: 5, mobLevels:{min: 460}, name: 'Great Bahamut of Abyss / Lizard King of Abyss' },
        { x: 47, y: 109, quantityMobs: 5, mobLevels:{min: 460}, name: 'Great Bahamut of Abyss / Lizard King of Abyss' },
        { x: 87, y: 75, quantityMobs: 5, mobLevels:{min: 460}, name: 'Great Bahamut of Abyss / Lizard King of Abyss' },
        { x: 132, y: 85, quantityMobs: 5, mobLevels:{min: 460}, name: 'Great Bahamut of Abyss / Lizard King of Abyss' },
      ]
    },
    {
      imageSrc: subDark,
      coordinates: [
      ]
    },
    {
      imageSrc: hsDark,
      coordinates: [
        { x: 36, y: 138, quantityMobs: 9, mobLevels:{min: 460}, name: 'Great Bahamut of Abyss / Lizard King of Abyss' },
        { x: 72, y: 121, quantityMobs: 9, mobLevels:{min: 460}, name: 'Great Bahamut of Abyss / Lizard King of Abyss' },
      ]
    },
    {
      imageSrc: spotEarth,
      coordinates: [
        { x: 115, y: 240, quantityMobs: 5, mobLevels:{min: 460}, name: 'Great Bahamut of Abyss / Lizard King of Abyss' },
        { x: 93, y: 210, quantityMobs: 5, mobLevels:{min: 460}, name: 'Great Bahamut of Abyss / Lizard King of Abyss' },
        { x: 92, y: 184, quantityMobs: 5, mobLevels:{min: 460}, name: 'Great Bahamut of Abyss / Lizard King of Abyss' },
        { x: 69, y: 164, quantityMobs: 5, mobLevels:{min: 460}, name: 'Great Bahamut of Abyss / Lizard King of Abyss' },
        { x: 143, y: 209, quantityMobs: 5, mobLevels:{min: 460}, name: 'Great Bahamut of Abyss / Lizard King of Abyss' },
        { x: 93, y: 119, quantityMobs: 5, mobLevels:{min: 460}, name: 'Great Bahamut of Abyss / Lizard King of Abyss' },
      ]
    },
    {
      imageSrc: subEarth,
      coordinates: [
      ]
    },
    {
      imageSrc: hsEarth,
      coordinates: [
        { x: 111, y: 218, quantityMobs: 9, mobLevels:{min: 460}, name: 'Great Bahamut of Abyss / Lizard King of Abyss' },
        { x: 121, y: 175, quantityMobs: 9, mobLevels:{min: 460}, name: 'Great Bahamut of Abyss / Lizard King of Abyss' },
      ]
    },
  ],
}

const AtlansAbyss3 = () => <MuMap {...mapConfigs} />

export default AtlansAbyss3