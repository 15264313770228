import React, { useRef, useState, useEffect } from "react"

import 'ol/ol.css';
import './Map.css';
import { Map as OlMap, View } from "ol";
import {
  DragRotateAndZoom,
  defaults as defaultInteractions,
} from 'ol/interaction';
import Select from 'ol/interaction/Select';

import MapContext from "./MapContext";


// https://viglino.github.io/ol-ext/

const Map = ({
  children,
  zoom=0,
  maxZoom=3,
  center,
  projection,
  rotation,
  controls=[],
  dragRotateAndZoom,
  onFeatureClick= () => {},
}) => {
  const mapRef = useRef();
  const [map, setMap] = useState(null);
  
  useEffect(() => {
    const select = new Select({ style: null });
    select.getFeatures().on('add', function(e) {
      var feature = e.element;
      onFeatureClick(feature)
    });

    let options = {
      view: new View({ zoom, maxZoom, center, projection, rotation }),
      layers: [],
      controls,
      overlays: [],
      interactions: defaultInteractions().extend([
        select,
        ...(dragRotateAndZoom && [new DragRotateAndZoom({autoHide: true})])
      ]),
    };

    let mapObject = new OlMap(options);
    mapObject.setTarget(mapRef.current);
    setMap(mapObject);
    
    return () => mapObject.setTarget(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  // zoom change handler
  useEffect(() => {
    if (!map) return;
    map.getView().setZoom(zoom);
  }, [zoom, map]);
  
  // center change handler
  // useEffect(() => {
  //   if (!map) return;
  //   map.getView().setCenter(center)
  // }, [center])
  
  return (
    <MapContext.Provider value={{ map }}>
      <div style={{ height: '80vh', width: '100%' }} ref={mapRef} className="map-container">
        {children}
      </div>
    </MapContext.Provider>
  );
}

export default Map;
