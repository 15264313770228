import React from 'react';
import { Autocomplete, Container, TextField, Chip, Box } from '@mui/material';
import { useNavigate } from "react-router-dom";
import { mapOptions } from "../mapConfigs";

const Menu = () => {
  const navigate = useNavigate();
  const { pathname } = window.location;

  return (
    <Container>
      <Autocomplete
        key={pathname}
        getOptionDisabled={(option) => pathname === option.path}
        selectOnFocus
        onChange={(event, newValue) => {
          if (newValue) navigate(`/maps${newValue.path}`);
        }}
        options={mapOptions}
        sx={{ flexGrow: 1 }}
        renderInput={(params) => <TextField {...params} label="Selecione um mapa" variant='filled' />}
        getOptionLabel={(option) => option.name ?? ""}
        isOptionEqualToValue={(option, value) => option.name === value.name}
        renderOption={(props, option) => (
          <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
            <Box style={{ flexGrow: "1" }}>{option.name}</Box>
            {Boolean(option.entryLevel) && <Chip
              label={`Lvl: ${option.entryLevel}`}
              sx={{m: 0.5}}
            />}
            {Boolean(option.gap) && <Chip
              label={`Gap: ${option.gap}`}
              sx={{m: 0.5}}
            />}
          </Box>
        )}
      />
    </Container>
  )
}

export default Menu
