import React from "react"

import MuMap from '.';
import imageMap from '../../images/Mu/Data/Interface/GFx/NaviMap/Navimap38.png';

import hsNormal from '../../images/markers/hs-normal.png';
import subNormal from '../../images/markers/sub-normal.png';
import spotNormal from '../../images/markers/spot-normal.png';

const mapConfigs = {
  name: "Kanturu",
  url: imageMap,
  markers: [
    {
      imageSrc: spotNormal,
      coordinates: [
        // Kanturu 1
        { x: 42, y: 232, quantityMobs: 3, mobLevels:{min: 80}, name: "Splinter Wolf" },
        { x: 157, y: 201, quantityMobs: 3, mobLevels:{min: 80}, name: "Splinter Wolf" },
        { x: 188, y: 212, quantityMobs: 3, mobLevels:{min: 80, max: 82}, name: "Splinter Wolf / Iron Rider" },
        // Kanturu 2
        { x: 177, y: 151, quantityMobs: 3, mobLevels:{min: 82, max: 85}, name: "Satyros / Iron Rider" },
        { x: 194, y: 150, quantityMobs: 3, mobLevels:{min: 82, max: 85}, name: "Satyros / Iron Rider" },
        { x: 216, y: 159, quantityMobs: 3, mobLevels:{min: 82, max: 85}, name: "Satyros" },
        { x: 235, y: 95, quantityMobs: 3, mobLevels:{min: 82, max: 85}, name: "Satyros" },
        { x: 226, y: 82, quantityMobs: 3, mobLevels:{min: 82, max: 85}, name: "Satyros" },
        { x: 167, y: 40, quantityMobs: 3, mobLevels:{min: 88, max: 93}, name: "Kentarus / Blade Hunter" },
        { x: 88, y: 32, quantityMobs: 3, mobLevels:{min: 98, max: 100}, name: "Gigantis / Berseker" },
        { x: 36, y: 74, quantityMobs: 3, mobLevels:{min: 105}, name: "Genocider" },
        // Kanturu 3
        { x: 102, y: 153, quantityMobs: 3, mobLevels:{min: 123, max: 126}, name: "Warrior Berseker / Warrior Kentarus" },
        { x: 145, y: 130, quantityMobs: 3, mobLevels:{min: 126, max: 129}, name: "Warrior Genocider / Warrior Kentarus / Warrior Gigantis" },
      ]
    },
    {
      imageSrc: subNormal,
      coordinates: [
        // Kanturu 2
        { x: 233, y: 129, quantityMobs: 4, mobLevels:{min: 82, max: 85}, name: "Satyros / Iron Rider" },
        { x: 154, y: 56, quantityMobs: 4, mobLevels:{min: 88, max: 100}, name: "Blade Hunter / Berseker" },
        { x: 182, y: 15, quantityMobs: 4, mobLevels:{min: 88, max: 100}, name: "Kentarus / Blade Hunter / Berseker" },
        { x: 151, y: 17, quantityMobs: 4, mobLevels:{min: 88, max: 100}, name: "Kentarus / Blade Hunter / Berseker" },
        { x: 58, y: 38, quantityMobs: 4, mobLevels:{min: 98, max: 100}, name: "Gigantis / Berseker" },
        // Kanturu 3
        { x: 88, y: 125, quantityMobs: 4, mobLevels:{min: 123, max: 128}, name: "Warrior Berseker / Warrior Kentarus / Warrior Gigantis" },
        { x: 139, y: 157, quantityMobs: 4, mobLevels:{min: 126, max: 128}, name: "Warrior Kentarus / Warrior Gigantis" },
        { x: 134, y: 127, quantityMobs: 4, mobLevels:{min: 126, max: 128}, name: "Warrior Kentarus / Warrior Gigantis" },
        { x: 145, y: 90, quantityMobs: 4, mobLevels:{min: 126, max: 129}, name: "Warrior Genocider / Warrior Kentarus / Warrior Gigantis" },
      ]
    },
    {
      imageSrc: hsNormal,
      coordinates: [
        // Kanturu 2
        { x: 173, y: 163, quantityMobs: 6, mobLevels:{min: 82, max: 85}, name: "Satyros / Iron Rider" },
        { x: 220, y: 142, quantityMobs: 5, mobLevels:{min: 82, max: 85}, name: "Satyros / Iron Rider" },
        { x: 213, y: 40, quantityMobs: 6, mobLevels:{min: 85, max: 88}, name: "Satyros / Blade Hunter" },
        { x: 140, y: 45, quantityMobs: 7, mobLevels:{min: 88, max: 100}, name: "Kentarus / Blade Hunter / Berseker" },
        { x: 122, y: 25, quantityMobs: 6, mobLevels:{min: 88, max: 100}, name: "Kentarus / Blade Hunter / Berseker" },
        { x: 67, y: 26, quantityMobs: 5, mobLevels:{min: 98, max: 100}, name: "Gigantis / Berseker" },
        { x: 43, y: 50, quantityMobs: 5, mobLevels:{min: 100, max: 105}, name: "Genocider / Berseker" },
        { x: 59, y: 98, quantityMobs: 6, mobLevels:{min: 100, max: 105}, name: "Genocider / Berseker" },
        // Kanturu 3
        { x: 111, y: 102, quantityMobs: 5, mobLevels:{min: 128, max: 129}, name: "Warrior Genocider / Warrior Gigantis" },
        { x: 130, y: 110, quantityMobs: 5, mobLevels:{min: 123, max: 128}, name: "Warrior Berseker / Warrior Kentarus / Warrior Gigantis" },
        { x: 173, y: 109, quantityMobs: 5, mobLevels:{min: 126, max: 129}, name: "Warrior Genocider / Warrior Kentarus / Warrior Gigantis" },
      ]
    },
  ],
}

const Kanturu = () => <MuMap {...mapConfigs} />

export default Kanturu