import React from "react"

import MuMap from '.';
import imageMap from '../../images/Mu/Data/Interface/GFx/NaviMap/Navimap05.png';

import hsNormal from '../../images/markers/hs-normal.png';
import subNormal from '../../images/markers/sub-normal.png';
import spotNormal from '../../images/markers/spot-normal.png';

const mapConfigs = {
  name: "Lost Tower",
  url: imageMap,
  markers: [
    {
      imageSrc: spotNormal,
      coordinates: [
        // Lost Tower 1
        { x: 195, y: 130, quantityMobs: 3, mobLevels:{min: 47}, name: 'Shadow' },
        { x: 198, y: 110, quantityMobs: 3, mobLevels:{min: 47}, name: 'Shadow' },
        { x: 194, y: 33, quantityMobs: 3, mobLevels:{min: 47, max: 50}, name: 'Shadow / Poison Shadow' },
        { x: 194, y: 41, quantityMobs: 3, mobLevels:{min: 47, max: 50}, name: 'Shadow / Poison Shadow' },
        { x: 210, y: 93, quantityMobs: 3, mobLevels:{min: 47}, name: 'Shadow' },
        // Lost Tower 2
        { x: 196, y: 184, quantityMobs: 3, mobLevels:{min: 50, max: 54}, name: 'Cursed Wizard / Poison Shadow' },
        { x: 237, y: 214, quantityMobs: 3, mobLevels:{min: 50, max: 54}, name: 'Cursed Wizard / Poison Shadow' },
        // Lost Tower 3
        { x: 99, y: 205, quantityMobs: 3, mobLevels:{min: 54, max: 57}, name: 'Cursed Wizard / Death Cow' },
        // Lost Tower 4
        { x: 87, y: 119, quantityMobs: 3, mobLevels:{min: 57, max: 60}, name: 'Death Cow / Devil' },
        { x: 89, y: 134, quantityMobs: 3, mobLevels:{min: 57, max: 60}, name: 'Death Cow / Devil' },
        { x: 121, y: 133, quantityMobs: 3, mobLevels:{min: 57, max: 60}, name: 'Death Cow / Devil' },
        { x: 126, y: 105, quantityMobs: 3, mobLevels:{min: 57, max: 60}, name: 'Death Cow / Devil' },
        // Lost Tower 5
        { x: 98, y: 54, quantityMobs: 3, mobLevels:{min: 60, max: 62}, name: 'Death Knight / Devil' },
        { x: 112, y: 10, quantityMobs: 3, mobLevels:{min: 60, max: 62}, name: 'Death Knight / Devil' },
        { x: 129, y: 26, quantityMobs: 3, mobLevels:{min: 60, max: 62}, name: 'Death Knight / Devil' },
        // Lost Tower 7
        { x: 8, y: 176, quantityMobs: 3, mobLevels:{min: 62, max: 64}, name: 'Death Knight / Goron' },
        { x: 8, y: 224, quantityMobs: 3, mobLevels:{min: 62, max: 64}, name: 'Death Knight / Goron' },
        { x: 10, y: 103, quantityMobs: 3, mobLevels:{min: 62, max: 64}, name: 'Death Knight / Goron' },
        { x: 17, y: 122, quantityMobs: 3, mobLevels:{min: 62, max: 64}, name: 'Death Knight / Goron' },
        { x: 47, y: 137, quantityMobs: 3, mobLevels:{min: 62, max: 64}, name: 'Death Knight / Goron' },
        { x: 52, y: 234, quantityMobs: 3, mobLevels:{min: 62, max: 64}, name: 'Death Knight / Goron' },
        { x: 53, y: 115, quantityMobs: 3, mobLevels:{min: 62, max: 64}, name: 'Death Knight / Goron' },
        { x: 53, y: 191, quantityMobs: 3, mobLevels:{min: 62, max: 64}, name: 'Death Knight / Goron' },
      ]
    },
    {
      imageSrc: subNormal,
      coordinates: [
        // Lost Tower 3
        { x: 100, y: 188, quantityMobs: 4, mobLevels:{min: 54, max: 57}, name: 'Cursed Wizard / Death Cow' },
        { x: 101, y: 234, quantityMobs: 4, mobLevels:{min: 54, max: 57}, name: 'Cursed Wizard / Death Cow' },
        { x: 132, y: 170, quantityMobs: 4, mobLevels:{min: 54, max: 57}, name: 'Cursed Wizard / Death Cow' },
        // Lost Tower 6
        { x: 29, y: 17, quantityMobs: 4, mobLevels:{min: 60, max: 64}, name: 'Death Knight / Devil / Goron' },
        { x: 49, y: 16, quantityMobs: 4, mobLevels:{min: 60, max: 64}, name: 'Death Knight / Devil / Goron' },
        // Lost Tower 7
        { x: 23, y: 171, quantityMobs: 4, mobLevels:{min: 64}, name: 'Goron' },
      ]
    },
    {
      imageSrc: hsNormal,
      coordinates: [
        // Lost Tower 3
        { x: 101, y: 170, quantityMobs: 5, mobLevels:{min: 54, max: 57}, name: 'Cursed Wizard / Death Cow' },
        // Lost Tower 7
        { x: 52, y: 91, quantityMobs: 5, mobLevels:{min: 60, max: 64}, name: 'Death Knight / Devil / Goron' },
        { x: 30, y: 102, quantityMobs: 5, mobLevels:{min: 60, max: 64}, name: 'Death Knight / Devil / Goron' },
        { x: 43, y: 171, quantityMobs: 5, mobLevels:{min: 62, max: 64}, name: 'Death Knight / Goron' },
      ]
    },
  ],
}

const LostTower = () => <MuMap {...mapConfigs} />

export default LostTower