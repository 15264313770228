import React from "react"

import MuMap from '.';
import imageMap from '../../images/Mu/Data/Interface/GFx/NaviMap/Navimap03.png';

import hsNormal from '../../images/markers/hs-normal.png';
import subNormal from '../../images/markers/sub-normal.png';
import spotNormal from '../../images/markers/spot-normal.png';

const mapConfigs = {
  name: "Devias",
  url: imageMap,
  markers: [
    {
      imageSrc: spotNormal,
      coordinates: [
        // Devias 1
        { x: 116, y: 76, quantityMobs: 3, mobLevels:{min: 30} },
        { x: 169, y: 120, quantityMobs: 3, mobLevels:{min: 20, max: 22} },
        { x: 209, y: 120, quantityMobs: 3, mobLevels:{min: 24} },
        { x: 216, y: 88, quantityMobs: 3, mobLevels:{min: 20} },
        { x: 235, y: 86, quantityMobs: 3, mobLevels:{min: 20} },
      ]
    },
    {
      imageSrc: subNormal,
      coordinates: [
        // Devias 2
        { x: 55, y: 68, quantityMobs: 3, mobLevels:{min: 30, max: 36} },
        // Devias 1
        { x: 124, y: 31, quantityMobs: 3, mobLevels:{min: 26} },
      ]
    },
    {
      imageSrc: hsNormal,
      coordinates: [
        // Devias 3
        { x: 199, y: 239, quantityMobs: 3, mobLevels:{min: 36} },
      ]
    },
  ],
}

const Devias = () => <MuMap {...mapConfigs} />

export default Devias