import React, { useState } from "react";
import { IconButton } from "@mui/material";
import { NavigateBefore, NavigateNext, Fingerprint } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import { useSwipeable } from 'react-swipeable';

const ImageContainer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "flex-end",
  height: "70vh",
  position: "relative",
  marginBottom: "15px",
  [theme.breakpoints.down("sm")]: {
    alignItems: "center",
  },
}));


const Image = styled("img")({
  maxHeight: "100%",
  maxWidth: "100%",
});

const Footer = styled("div")({
  display: "flex",
  justifyContent: "center",
});

const FooterText = styled("div")({
  margin: "10px",
});

const FingerprintButton = ({ onTouchStart, onTouchEnd }) => {
  return(
    <IconButton
      style={{
        position: "absolute",
        bottom: 0,
        transform: "translateY(50%)",
      }}
      onTouchStart={onTouchStart}
      onTouchEnd={onTouchEnd}
      onMouseDown={onTouchStart}
      onMouseUp={onTouchEnd}
    >
      <Fingerprint />
    </IconButton>
  )
};

export const ImageSliderStateless = ({
  image,
  imageExtra,
  imageIndex,
  imagesLength,
  handlePreviousClick,
  handleNextClick,
}) => {
  const [isTouching, setIsTouching] = useState(false);
  const handleTouchStart = () => setIsTouching(true);
  const handleTouchEnd = () => setIsTouching(false);

  const swipeHandlers = useSwipeable({
    onSwipedLeft: handleNextClick,
    onSwipedRight: handlePreviousClick
  });

  return (
    <div {...swipeHandlers}>
      <ImageContainer>
        <Image
          src={isTouching ? imageExtra : image}
          alt="slider"
        />
        {imageExtra && 
          <FingerprintButton
            onTouchStart={handleTouchStart}
            onTouchEnd={handleTouchEnd}
          />
        }
      </ImageContainer>
      <Footer>
        <IconButton onClick={handlePreviousClick}>
          <NavigateBefore />
        </IconButton>
        <FooterText>
          {imageIndex + 1} / {imagesLength}
        </FooterText>
        <IconButton onClick={handleNextClick}>
          <NavigateNext />
        </IconButton>
      </Footer>
    </div>
  );
};
