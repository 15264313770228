export const expRange = (expObj, start, end) => Object
  .values(expObj)
  .slice(start, end);

export const sumExpValues = (expList, bar) => {
  const expCurrentLevel = expList.shift();
  const expRemainingCurrentLevel = bar === 0
    ? expCurrentLevel
    : (expCurrentLevel / 10) * (10 - bar);

  const expRemaining = expList.reduce((accumulator, currentValue) => accumulator + currentValue, 0)
  return (expRemainingCurrentLevel + expRemaining);
}

export const calculateTimeForExperience = (targetExperience, experiencePerSecond) => {
  return Math.floor(targetExperience / experiencePerSecond);
}

export const secondsToDHMS = timeInSeconds => {
  timeInSeconds = Number(timeInSeconds);
  
  return {
    days: Math.floor(timeInSeconds / (3600*24)),
    hours: Math.floor(timeInSeconds % (3600*24) / 3600),
    minutes: Math.floor(timeInSeconds % 3600 / 60),
    seconds: Math.floor(timeInSeconds % 60),
  }
}
