import React from "react"
import { Chip, Box } from '@mui/material';
import { LocationOn, CatchingPokemon, SmartToy, Info } from '@mui/icons-material';

import "./MapDetails.css"

const MapContainer = ({ children }) => (
  <div className="map-details">
    <div className="details-container">
      {children}
    </div>
  </div>
)

const MapDetails = ({
  coordinate={x: 0, y: 0},
  quantityMobs=0,
  mobLevels={min: 0, max: 0},
  elementImg=null,
}) => {
  const isMobile = window.innerWidth <= 760

  if(!coordinate.x) return (
    <MapContainer>
      <Chip
        avatar={<Info color="action" />}
        label={`Selecione para ver as informações`}
        sx={{m: 0.5}}
      />
      {!isMobile && <Chip
        avatar={<Info color="action" />}
        label={`Segure Shift e arraste para girar`}
        sx={{m: 0.5}}
      />}
    </MapContainer>
  )

  const mobLevelText = ({min, max}) => {
    const text = `Mob level: ${min}`
    if (max) return `${text} a ${max}`
    return text
  }

  return (
    <MapContainer>
      <Box sx={{
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
      }}>
        <Chip
          avatar={<LocationOn color="action" />}
          label={`Spot: (${coordinate.x},${coordinate.y})`}
          sx={{m: 0.5}}
        />
        <Chip
          avatar={<CatchingPokemon color="action" />}
          label={`Mobs: ${quantityMobs || "?"}`}
          sx={{m: 0.5}}
        />
        {Boolean(mobLevels.min) && <Chip
          avatar={<SmartToy color="action" />}
          label={mobLevelText(mobLevels)}
          sx={{m: 0.5}}
        />}
        {elementImg && <Chip
          avatar={<img src={elementImg} alt="Element" />}
          label={`Elemento`}
          sx={{m: 0.5}}
        />}
      </Box>
    </MapContainer>
  )
}

export default MapDetails;