import React from "react"

import MuMap from '.';
import imageMap from '../../images/Mu/Data/Interface/GFx/NaviMap/Navimap32.png';

import subNormal from '../../images/markers/sub-normal.png';
import spotNormal from '../../images/markers/spot-normal.png';

const mapConfigs = {
  name: "Land of Trials",
  url: imageMap,
  markers: [
    {
      imageSrc: spotNormal,
      coordinates: [
        { x: 52, y: 42, quantityMobs: 3, mobLevels:{min: 75}, name: 'Axe Warrior' },
        { x: 74, y: 45, quantityMobs: 3, mobLevels:{min: 75}, name: 'Axe Warrior' },
        { x: 122, y: 85, quantityMobs: 3, mobLevels:{min: 78, max: 84}, name: 'Lizard Warrior / Poison Golem' },
        { x: 138, y: 173, quantityMobs: 3, mobLevels:{min: 92, max: 102}, name: 'Queen Ice / Fire Golem' },
        { x: 152, y: 65, quantityMobs: 3, mobLevels:{min: 78}, name: 'Lizard Warrior' },
        { x: 159, y: 34, quantityMobs: 3, mobLevels:{min: 75, max: 78}, name: 'Axe Warrior / Lizard Warrior' },
        { x: 165, y: 67, quantityMobs: 3, mobLevels:{min: 78}, name: 'Lizard Warrior' },
        { x: 184, y: 129, quantityMobs: 3, mobLevels:{min: 84, max: 92}, name: 'Poison Golem / Queen Ice' },
        { x: 211, y: 59, quantityMobs: 3, mobLevels:{min: 78}, name: 'Lizard Warrior' },
        { x: 228, y: 87, quantityMobs: 3, mobLevels:{min: 78, max: 84}, name: 'Lizard Warrior / Poison Golem' },
      ]
    },
    {
      imageSrc: subNormal,
      coordinates: [
        { x: 61, y: 15, quantityMobs: 4, mobLevels:{min: 75}, name: 'Axe Warrior' },
        { x: 54, y: 59, quantityMobs: 4, mobLevels:{min: 75, max: 78}, name: 'Axe Warrior / Lizard Warrior' },
        { x: 99, y: 33, quantityMobs: 4, mobLevels:{min: 75}, name: 'Axe Warrior' },
        { x: 133, y: 34, quantityMobs: 4, mobLevels:{min: 75}, name: 'Axe Warrior' },
        { x: 199, y: 20, quantityMobs: 4, mobLevels:{min: 75, max: 78}, name: 'Axe Warrior / Lizard Warrior' },
        { x: 210, y: 25, quantityMobs: 4, mobLevels:{min: 75, max: 78}, name: 'Axe Warrior / Lizard Warrior' },
        { x: 225, y: 29, quantityMobs: 4, mobLevels:{min: 75, max: 78}, name: 'Axe Warrior / Lizard Warrior' },
        { x: 224, y: 116, quantityMobs: 4, mobLevels:{min: 84}, name: 'Poison Golem' },
        { x: 153, y: 119, quantityMobs: 4, mobLevels:{min: 84, max: 92}, name: 'Poison Golem / Queen Ice' },
        { x: 134, y: 224, quantityMobs: 4, mobLevels:{min: 92, max: 102}, name: 'Queen Ice / Fire Golem' },
      ]
    },
  ],
}

const LandOfTrials = () => <MuMap {...mapConfigs} />

export default LandOfTrials