import React, { useState, useEffect, useCallback } from "react";
import { ImageSliderStateless } from "./ImageSliderStateless";

export const ImageSlider = ({ images }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const handlePreviousClick = useCallback(() => {
    if (currentImageIndex === 0) {
      setCurrentImageIndex(images.length - 1);
    } else {
      setCurrentImageIndex(currentImageIndex - 1);
    }
  }, [currentImageIndex, setCurrentImageIndex, images.length]);

  const handleNextClick = useCallback(() => {
    setCurrentImageIndex((currentImageIndex + 1) % images.length);
  }, [currentImageIndex, setCurrentImageIndex, images.length]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "ArrowLeft") {
        handlePreviousClick();
      }
      if (event.key === "ArrowRight") {
        handleNextClick();
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [handlePreviousClick, handleNextClick]);

  return (
    <ImageSliderStateless
      image={images[currentImageIndex].image}
      imageExtra={images[currentImageIndex].imageExtra ?? null}
      imageIndex={currentImageIndex}
      imagesLength={images.length}
      handlePreviousClick={handlePreviousClick}
      handleNextClick={handleNextClick}
    />
  );
}
