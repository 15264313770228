import React, { useState } from "react"
import { Container, Typography } from '@mui/material';
import { Projection } from 'ol/proj';
import { getCenter } from 'ol/extent';
import { defaults as defaultControls } from 'ol/control';
import FullScreen from 'ol/control/FullScreen';

import useWindowDimensions from "../../hooks/useWindowDimensions";
import Map from '../Map'
import Layers from '../Map/Layers';
import ImageLayer from '../Map/Layers/ImageLayer';
import MarkerLayer from '../Map/Layers/MarkerLayer';
import MapDetails from "./details/MapDetails";

const styles = {
  mapContainer: {
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
    padding: 0,
  }
};

const extent = [0, 0, 256, 256];

const MuMap = ({
  name,
  url,
  markers,
  center=getCenter(extent),
  zoom=1.2,
  zoomMobile=0,
}) => {
  const [ mapDetails, setMapDetails ] = useState({});
  const { isMobile } = useWindowDimensions();

  const projection = new Projection({
    code: 'img-map',
    units: 'pixels',
    extent,
  });

  const anchor = {
    x: {
      value: 0.5,
      unit: 'fraction',
    },
    y: {
      value: 1,
      unit: 'fraction',
    }
  }

  const onFeatureClick = (feature) => {
    setMapDetails({
      coordinate: {
        x: feature.get('x'),
        y: feature.get('y'),
      },
      quantityMobs: feature.get('quantityMobs'),
      mobLevels: feature.get('mobLevels'),
    })
  }

  return (
    <>
      <Container>
        <Typography variant='h4' color="textPrimary">
          {name}
        </Typography>
      </Container>
      <Container style={styles.mapContainer}>
        <Map
          zoom={isMobile ? zoomMobile : zoom}
          center={center}
          projection={projection}
          rotation={Math.PI / 4}
          controls={defaultControls().extend([new FullScreen()])}
          dragRotateAndZoom
          onFeatureClick={onFeatureClick}>
          <Layers>
            <ImageLayer
              attributions={'© <a href="https://muonline.webzen.com">Mu Online</a>'}
              projection={projection}
              imageExtent={extent}
              url={url}
            />
            {markers.map(({imageSrc, coordinates}, index) =>
              <MarkerLayer
              key={index}
              imageSrc={imageSrc}
              anchor={anchor}
              coordinates={coordinates}
              />
            )}
          </Layers>
          <MapDetails {...mapDetails} />
        </Map>
      </Container>
    </>
  );
}

export default MuMap