import React from "react"

import MuMap from '.';
import imageMap from '../../images/Mu/Data/Interface/GFx/NaviMap/Navimap120.png';

import hsDark from '../../images/markers/hs-dark.png';
import hsFire from '../../images/markers/hs-fire.png';
import hsWater from '../../images/markers/hs-water.png';
import hsWind from '../../images/markers/hs-wind.png';
import hsEarth from '../../images/markers/hs-earth.png';

import subDark from '../../images/markers/sub-dark.png';
import subEarth from '../../images/markers/sub-earth.png';
import subFire from '../../images/markers/sub-fire.png';
import subWater from '../../images/markers/sub-water.png';
import subWind from '../../images/markers/sub-wind.png';

import spotDark from '../../images/markers/spot-dark.png';
import spotEarth from '../../images/markers/spot-earth.png';
import spotFire from '../../images/markers/spot-fire.png';
import spotWater from '../../images/markers/spot-water.png';
import spotWind from '../../images/markers/spot-wind.png';

const mapConfigs = {
  name: "Deep Dungeon 4",
  center: [160, 64],
  url: imageMap,
  markers: [
    {
      imageSrc: spotWater,
      coordinates: [
        { x: 129, y: 62, quantityMobs: 4, mobLevels:{min: 250}, name: 'Deep Dungeon Hell Spider / Deep Dungeon Chief Skeleton' },
        { x: 116, y: 36, quantityMobs: 4, mobLevels:{min: 250}, name: 'Deep Dungeon Hell Spider / Deep Dungeon Chief Skeleton' },
        { x: 117, y: 14, quantityMobs: 4, mobLevels:{min: 250}, name: 'Deep Dungeon Hell Spider / Deep Dungeon Chief Skeleton' },
      ]
    },
    {
      imageSrc: subWater,
      coordinates: [
        { x: 164, y: 64, quantityMobs: 5, mobLevels:{min: 250}, name: 'Deep Dungeon Hell Spider / Deep Dungeon Chief Skeleton' },
        { x: 179, y: 35, quantityMobs: 5, mobLevels:{min: 250}, name: 'Deep Dungeon Hell Spider / Deep Dungeon Chief Skeleton' },
        { x: 181, y: 16, quantityMobs: 6, mobLevels:{min: 250}, name: 'Deep Dungeon Hell Spider / Deep Dungeon Chief Skeleton' },
      ]
    },
    {
      imageSrc: hsWater,
      coordinates: [
        { x: 152, y: 29, quantityMobs: 7, mobLevels:{min: 250}, name: 'Deep Dungeon Hell Spider / Deep Dungeon Chief Skeleton' },
      ]
    },
    {
      imageSrc: spotFire,
      coordinates: [
        { x: 87, y: 49, quantityMobs: 4, mobLevels:{min: 250}, name: 'Deep Dungeon Hell Spider / Deep Dungeon Chief Skeleton' },
        { x: 99, y: 65, quantityMobs: 4, mobLevels:{min: 250}, name: 'Deep Dungeon Hell Spider / Deep Dungeon Chief Skeleton' },
        { x: 79, y: 13, quantityMobs: 4, mobLevels:{min: 250}, name: 'Deep Dungeon Hell Spider / Deep Dungeon Chief Skeleton' },
      ]
    },
    {
      imageSrc: subFire,
      coordinates: [
        { x: 25, y: 14, quantityMobs: 5, mobLevels:{min: 250}, name: 'Deep Dungeon Hell Spider / Deep Dungeon Chief Skeleton' },
        { x: 68, y: 40, quantityMobs: 5, mobLevels:{min: 250}, name: 'Deep Dungeon Hell Spider / Deep Dungeon Chief Skeleton' },
        { x: 102, y: 25, quantityMobs: 6, mobLevels:{min: 250}, name: 'Deep Dungeon Hell Spider / Deep Dungeon Chief Skeleton' },
      ]
    },
    {
      imageSrc: hsFire,
      coordinates: [
        { x: 7, y: 15, quantityMobs: 7, mobLevels:{min: 250}, name: 'Deep Dungeon Hell Spider / Deep Dungeon Chief Skeleton' },
      ]
    },
    {
      imageSrc: spotWind,
      coordinates: [
        { x: 232, y: 136, quantityMobs: 4, mobLevels:{min: 250}, name: 'Deep Dungeon Hell Spider / Deep Dungeon Chief Skeleton' },
        { x: 244, y: 164, quantityMobs: 4, mobLevels:{min: 250}, name: 'Deep Dungeon Hell Spider / Deep Dungeon Chief Skeleton' },
        { x: 246, y: 69, quantityMobs: 4, mobLevels:{min: 250}, name: 'Deep Dungeon Hell Spider / Deep Dungeon Chief Skeleton' },
      ]
    },
    {
      imageSrc: subWind,
      coordinates: [
        { x: 182, y: 116, quantityMobs: 5, mobLevels:{min: 250}, name: 'Deep Dungeon Hell Spider / Deep Dungeon Chief Skeleton' },
        { x: 200, y: 121, quantityMobs: 6, mobLevels:{min: 250}, name: 'Deep Dungeon Hell Spider / Deep Dungeon Chief Skeleton' },
        { x: 222, y: 119, quantityMobs: 5, mobLevels:{min: 250}, name: 'Deep Dungeon Hell Spider / Deep Dungeon Chief Skeleton' },
        { x: 211, y: 82, quantityMobs: 5, mobLevels:{min: 250}, name: 'Deep Dungeon Hell Spider / Deep Dungeon Chief Skeleton' },
      ]
    },
    {
      imageSrc: hsWind,
      coordinates: [
        { x: 237, y: 93, quantityMobs: 7, mobLevels:{min: 250}, name: 'Deep Dungeon Hell Spider / Deep Dungeon Chief Skeleton' },
      ]
    },
    {
      imageSrc: spotDark,
      coordinates: [
        { x: 209, y: 244, quantityMobs: 4, mobLevels:{min: 250}, name: 'Deep Dungeon Hell Spider / Deep Dungeon Chief Skeleton' },
        { x: 223, y: 233, quantityMobs: 4, mobLevels:{min: 250}, name: 'Deep Dungeon Hell Spider / Deep Dungeon Chief Skeleton' },
        { x: 184, y: 132, quantityMobs: 4, mobLevels:{min: 250}, name: 'Deep Dungeon Hell Spider / Deep Dungeon Chief Skeleton' },
      ]
    },
    {
      imageSrc: subDark,
      coordinates: [
        { x: 185, y: 152, quantityMobs: 6, mobLevels:{min: 250}, name: 'Deep Dungeon Hell Spider / Deep Dungeon Chief Skeleton' },
        { x: 163, y: 140, quantityMobs: 5, mobLevels:{min: 250}, name: 'Deep Dungeon Hell Spider / Deep Dungeon Chief Skeleton' },
      ]
    },
    {
      imageSrc: hsDark,
      coordinates: [
        { x: 241, y: 183, quantityMobs: 7, mobLevels:{min: 250}, name: 'Deep Dungeon Hell Spider / Deep Dungeon Chief Skeleton' },
      ]
    },
    {
      imageSrc: spotEarth,
      coordinates: [
        { x: 185, y: 61, quantityMobs: 4, mobLevels:{min: 250}, name: 'Deep Dungeon Hell Spider / Deep Dungeon Chief Skeleton' },
        { x: 204, y: 67, quantityMobs: 4, mobLevels:{min: 250}, name: 'Deep Dungeon Hell Spider / Deep Dungeon Chief Skeleton' },
        { x: 205, y: 31, quantityMobs: 4, mobLevels:{min: 250}, name: 'Deep Dungeon Hell Spider / Deep Dungeon Chief Skeleton' },
      ]
    },
    {
      imageSrc: subEarth,
      coordinates: [
        { x: 183, y: 95, quantityMobs: 6, mobLevels:{min: 250}, name: 'Deep Dungeon Hell Spider / Deep Dungeon Chief Skeleton' },
        { x: 224, y: 43, quantityMobs: 5, mobLevels:{min: 250}, name: 'Deep Dungeon Hell Spider / Deep Dungeon Chief Skeleton' },
        { x: 246, y: 39, quantityMobs: 5, mobLevels:{min: 250}, name: 'Deep Dungeon Hell Spider / Deep Dungeon Chief Skeleton' },
      ]
    },
    {
      imageSrc: hsEarth,
      coordinates: [
        { x: 234, y: 19, quantityMobs: 7, mobLevels:{min: 250}, name: 'Deep Dungeon Hell Spider / Deep Dungeon Chief Skeleton' },
      ]
    },
  ],
}

const DeepDungeon4 = () => <MuMap {...mapConfigs} />

export default DeepDungeon4