import React from 'react';
import { ThemeProvider as MuiThemeProvider, createTheme } from '@mui/material';
import { darkTheme } from './darkTheme';
// import { lightTheme } from './lightTheme';

const ThemeProvider = ({ children }) => {
  return (
    <MuiThemeProvider theme={createTheme(darkTheme)}>
      {children}
    </MuiThemeProvider>
  );
}
  
export default ThemeProvider