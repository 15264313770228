import React, { useContext, useEffect } from "react";

import OlImageLayer from 'ol/layer/Image';
import Static from 'ol/source/ImageStatic';

import MapContext from "../MapContext";

const ImageLayer = ({ attributions, projection, imageExtent, url }) => {
  const { map } = useContext(MapContext);
  
  useEffect(() => {
    if (!map) return;
    
    let layer = new OlImageLayer({
      source: new Static({
        attributions,
        projection,
        imageExtent,
        url,
      }),
    });
    map.addLayer(layer);
    
    return () => {
      if (map) {
        map.removeLayer(layer);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map]);
  
  return <></>;
};

export default ImageLayer;