import React from 'react';
import { CssBaseline, Container, Typography } from '@mui/material';
import { ImageSlider } from '../../components/ImageSlider';

import Map1 from "../../images/mu-maze-maps/1.jpg"
import Map1_1 from "../../images/mu-maze-maps/1-1.jpg"
import Map2 from "../../images/mu-maze-maps/2.jpg"
import Map2_1 from "../../images/mu-maze-maps/2-1.jpg"
import Map3 from "../../images/mu-maze-maps/3.jpg"
import Map4 from "../../images/mu-maze-maps/4.jpg"
import Map5 from "../../images/mu-maze-maps/5.jpg"
import Map6 from "../../images/mu-maze-maps/6.jpg"
import Map7 from "../../images/mu-maze-maps/7.jpg"
import Map8 from "../../images/mu-maze-maps/8.jpg"
import Map9 from "../../images/mu-maze-maps/9.jpg"
import Map10 from "../../images/mu-maze-maps/10.jpg"
import Map11 from "../../images/mu-maze-maps/11.jpg"
import Map12 from "../../images/mu-maze-maps/12.jpg"
import Map13 from "../../images/mu-maze-maps/13.jpg"
import Map14 from "../../images/mu-maze-maps/14.jpg"
import Map15 from "../../images/mu-maze-maps/15.jpg"
import Map16 from "../../images/mu-maze-maps/16.jpg"
import Map17 from "../../images/mu-maze-maps/17.jpg"
import Map18 from "../../images/mu-maze-maps/18.jpg"
import Map19 from "../../images/mu-maze-maps/19.jpg"
import Map20 from "../../images/mu-maze-maps/20.jpg"
import Map21 from "../../images/mu-maze-maps/21.jpg"
import Map22 from "../../images/mu-maze-maps/22.jpg"
import Map23 from "../../images/mu-maze-maps/23.jpg"
import Map24 from "../../images/mu-maze-maps/24.jpg"
import Map25 from "../../images/mu-maze-maps/25.jpg"
import Map26 from "../../images/mu-maze-maps/26.jpg"
import Map27 from "../../images/mu-maze-maps/27.jpg"
import Map28 from "../../images/mu-maze-maps/28.jpg"
import Map29 from "../../images/mu-maze-maps/29.jpg"
import Map30 from "../../images/mu-maze-maps/30.jpg"

const images = [
  { image: Map1, imageExtra: Map1_1 },
  { image: Map2, imageExtra: Map2_1 },
  { image: Map3 },
  { image: Map4 },
  { image: Map5 },
  { image: Map6 },
  { image: Map7 },
  { image: Map8 },
  { image: Map9 },
  { image: Map10 },
  { image: Map11 },
  { image: Map12 },
  { image: Map13 },
  { image: Map14 },
  { image: Map15 },
  { image: Map16 },
  { image: Map17 },
  { image: Map18 },
  { image: Map19 },
  { image: Map20 },
  { image: Map21 },
  { image: Map22 },
  { image: Map23 },
  { image: Map24 },
  { image: Map25 },
  { image: Map26 },
  { image: Map27 },
  { image: Map28 },
  { image: Map29 },
  { image: Map30 },
];

const Maze = () => {
  return (
    <>
      <CssBaseline />
      <Container style={{ paddingTop: 20 }}>
        <Typography variant='h4' color="textPrimary">
          Maze of Dimensions
        </Typography>
      </Container>
      <Container>
        <ImageSlider images={images} />
      </Container>
    </>
  );
}

export default Maze