import React from "react"

import MuMap from '.';
import imageMap from '../../images/Mu/Data/Interface/GFx/NaviMap/Navimap133.png';

import hsDark from '../../images/markers/hs-dark.png';
import hsFire from '../../images/markers/hs-fire.png';
import hsWater from '../../images/markers/hs-water.png';
import hsWind from '../../images/markers/hs-wind.png';
import hsEarth from '../../images/markers/hs-earth.png';

import subDark from '../../images/markers/sub-dark.png';
import subEarth from '../../images/markers/sub-earth.png';
import subFire from '../../images/markers/sub-fire.png';
import subWater from '../../images/markers/sub-water.png';
import subWind from '../../images/markers/sub-wind.png';

import spotDark from '../../images/markers/spot-dark.png';
import spotEarth from '../../images/markers/spot-earth.png';
import spotFire from '../../images/markers/spot-fire.png';
import spotWater from '../../images/markers/spot-water.png';
import spotWind from '../../images/markers/spot-wind.png';

const mapConfigs = {
  name: "Crimson Flame's Icarus",
  url: imageMap,
  markers: [
    {
      imageSrc: spotWater,
      coordinates: [
        { x: 57, y: 227, quantityMobs: 5, mobLevels:{min: 0}, name: "Crimson Flame's Megacrust / Crimson Flame's Alquamos" },
        { x: 85, y: 237, quantityMobs: 5, mobLevels:{min: 0}, name: "Crimson Flame's Megacrust / Crimson Flame's Alquamos" },
      ]
    },
    {
      imageSrc: subWater,
      coordinates: [
        { x: 108, y: 192, quantityMobs: 7, mobLevels:{min: 0}, name: "Crimson Flame's Queen Rainer / Crimson Flame's Megacrust / Crimson Flame's Alquamos" },
        { x: 103, y: 152, quantityMobs: 8, mobLevels:{min: 0}, name: "Crimson Flame's Queen Rainer / Crimson Flame's Alphacrust / Phanton Knight" },
        { x: 61, y: 186, quantityMobs: 8, mobLevels:{min: 0}, name: " Crimson Flame's Megacrust / Crimson Flame's Alquamos" },
      ]
    },
    {
      imageSrc: hsWater,
      coordinates: [
        { x: 53, y: 154, quantityMobs: 9, mobLevels:{min: 0}, name: "Crimson Flame's Queen Rainer / Crimson Flame's Alphacrust / Phanton Knight" },
        { x: 56, y: 208, quantityMobs: 9, mobLevels:{min: 0}, name: "Crimson Flame's Queen Rainer / Crimson Flame's Alphacrust / Phanton Knight" },
      ]
    },
    {
      imageSrc: spotFire,
      coordinates: [
        { x: 62, y: 46, quantityMobs: 6, mobLevels:{min: 0}, name: " Crimson Flame's Megacrust / Crimson Flame's Alquamos" },
        { x: 126, y: 21, quantityMobs: 5, mobLevels:{min: 0}, name: " Crimson Flame's Megacrust / Crimson Flame's Alquamos" },
      ]
    },
    {
      imageSrc: subFire,
      coordinates: [
        { x: 56, y: 114, quantityMobs: 7, mobLevels:{min: 0}, name: "Crimson Flame's Megacrust / Crimson Flame's Alquamos" },
        { x: 96, y: 75, quantityMobs: 8, mobLevels:{min: 0}, name: "Crimson Flame's Queen Rainer / Crimson Flame's Alphacrust / Phanton Knight" },
        { x: 79, y: 22, quantityMobs: 7, mobLevels:{min: 0}, name: "Crimson Flame's Megacrust / Crimson Flame's Alquamos" },
      ]
    },
    {
      imageSrc: hsFire,
      coordinates: [
        { x: 47, y: 87, quantityMobs: 9, mobLevels:{min: 0}, name: "Crimson Flame's Queen Rainer / Crimson Flame's Alphacrust / Phanton Knight" },
        { x: 44, y: 20, quantityMobs: 9, mobLevels:{min: 0}, name: "Crimson Flame's Queen Rainer / Crimson Flame's Alphacrust / Phanton Knight" },
      ]
    },
    {
      imageSrc: spotWind,
      coordinates: [
        { x: 211, y: 187, quantityMobs: 6, mobLevels:{min: 0}, name: "Crimson Flame's Megacrust / Crimson Flame's Alquamos" },
        { x: 228, y: 203, quantityMobs: 6, mobLevels:{min: 0}, name: "Crimson Flame's Megacrust / Crimson Flame's Alquamos" },
        { x: 218, y: 226, quantityMobs: 5, mobLevels:{min: 0}, name: "Crimson Flame's Megacrust / Crimson Flame's Alquamos" },
      ]
    },
    {
      imageSrc: subWind,
      coordinates: [
        { x: 125, y: 190, quantityMobs: 8, mobLevels:{min: 0}, name: "Crimson Flame's Queen Rainer / Crimson Flame's Alphacrust / Phanton Knight" },
        { x: 152, y: 209, quantityMobs: 8, mobLevels:{min: 0}, name: "Crimson Flame's Queen Rainer / Crimson Flame's Alphacrust / Phanton Knight" },
        { x: 177, y: 228, quantityMobs: 7, mobLevels:{min: 0}, name: "Crimson Flame's Megacrust / Crimson Flame's Alquamos" },
      ]
    },
    {
      imageSrc: hsWind,
      coordinates: [
        { x: 153, y: 165, quantityMobs: 9, mobLevels:{min: 0}, name: "Crimson Flame's Queen Rainer / Crimson Flame's Alphacrust / Phanton Knight" },
      ]
    },
    {
      imageSrc: spotDark,
      coordinates: [
        { x: 189, y: 169, quantityMobs: 6, mobLevels:{min: 0}, name: "Crimson Flame's Megacrust / Crimson Flame's Alquamos" },
        { x: 230, y: 112, quantityMobs: 5, mobLevels:{min: 0}, name: "Crimson Flame's Megacrust / Crimson Flame's Alquamos" },
        { x: 210, y: 156, quantityMobs: 6, mobLevels:{min: 0}, name: "Crimson Flame's Megacrust / Crimson Flame's Alquamos" },
      ]
    },
    {
      imageSrc: subDark,
      coordinates: [
        { x: 169, y: 146, quantityMobs: 8, mobLevels:{min: 0}, name: "Crimson Flame's Queen Rainer / Crimson Flame's Alphacrust / Phanton Knight" },
        { x: 241, y: 142, quantityMobs: 7, mobLevels:{min: 0}, name: "Crimson Flame's Megacrust / Crimson Flame's Alquamos" },
      ]
    },
    {
      imageSrc: hsDark,
      coordinates: [
        { x: 199, y: 124, quantityMobs: 9, mobLevels:{min: 0}, name: "Crimson Flame's Queen Rainer / Crimson Flame's Alphacrust / Phanton Knight" },
        { x: 194, y: 100, quantityMobs: 9, mobLevels:{min: 0}, name: "Crimson Flame's Queen Rainer / Crimson Flame's Alphacrust / Phanton Knight" },
      ]
    },
    {
      imageSrc: spotEarth,
      coordinates: [
        { x: 150, y: 39, quantityMobs: 5, mobLevels:{min: 0}, name: "Crimson Flame's Megacrust / Crimson Flame's Alquamos" },
        { x: 199, y: 37, quantityMobs: 6, mobLevels:{min: 0}, name: "Crimson Flame's Megacrust / Crimson Flame's Alquamos" },
      ]
    },
    {
      imageSrc: subEarth,
      coordinates: [
        { x: 154, y: 100, quantityMobs: 8, mobLevels:{min: 0}, name: "Crimson Flame's Queen Rainer / Crimson Flame's Alphacrust / Phanton Knight" },
        { x: 124, y: 38, quantityMobs: 7, mobLevels:{min: 0}, name: "Crimson Flame's Megacrust / Crimson Flame's Alquamos" },
        { x: 222, y: 54, quantityMobs: 7, mobLevels:{min: 0}, name: "Crimson Flame's Megacrust / Crimson Flame's Alquamos" },
      ]
    },
    {
      imageSrc: hsEarth,
      coordinates: [
        { x: 162, y: 23, quantityMobs: 9, mobLevels:{min: 0}, name: "Crimson Flame's Queen Rainer / Crimson Flame's Alphacrust / Phanton Knight" },
        { x: 193, y: 79, quantityMobs: 9, mobLevels:{min: 0}, name: "Crimson Flame's Queen Rainer / Crimson Flame's Alphacrust / Phanton Knight" },
      ]
    },
  ],
}

const CrimsonFlamesIcarus = () => <MuMap {...mapConfigs} />

export default CrimsonFlamesIcarus