import React from 'react';
import { Avatar } from '@mui/material';
import MuLogo from  '../images/mu-logo.png';

const WebZenLogo = (props) => {
  return (
    <Avatar
      alt="WebZen logo"
      src={MuLogo}
      variant={'square'}
      {...props}
    />
  );
}

export default WebZenLogo