import React from "react"

import MuMap from '.';
import imageMap from '../../images/Mu/Data/Interface/GFx/NaviMap/Navimap129.png';

import hsDark from '../../images/markers/hs-dark.png';
import hsFire from '../../images/markers/hs-fire.png';
import hsWater from '../../images/markers/hs-water.png';
import hsWind from '../../images/markers/hs-wind.png';
import hsEarth from '../../images/markers/hs-earth.png';

import subDark from '../../images/markers/sub-dark.png';
import subEarth from '../../images/markers/sub-earth.png';
import subFire from '../../images/markers/sub-fire.png';
import subWater from '../../images/markers/sub-water.png';
import subWind from '../../images/markers/sub-wind.png';

import spotDark from '../../images/markers/spot-dark.png';
import spotEarth from '../../images/markers/spot-earth.png';
import spotFire from '../../images/markers/spot-fire.png';
import spotWater from '../../images/markers/spot-water.png';
import spotWind from '../../images/markers/spot-wind.png';

const mapConfigs = {
  name: "Atlans Abyss 1",
  url: imageMap,
  markers: [
    {
      imageSrc: spotWater,
      coordinates: [
        { x: 53, y: 107, quantityMobs: 5, mobLevels:{min: 410}, name: 'Bahamut of Abyss / Vepar of Abyss' },
        { x: 74, y: 63, quantityMobs: 5, mobLevels:{min: 410}, name: 'Bahamut of Abyss / Vepar of Abyss' },
        { x: 103, y: 73, quantityMobs: 5, mobLevels:{min: 410}, name: 'Bahamut of Abyss / Vepar of Abyss' },
        { x: 130, y: 89, quantityMobs: 5, mobLevels:{min: 410}, name: 'Bahamut of Abyss / Vepar of Abyss' },
        { x: 95, y: 37, quantityMobs: 5, mobLevels:{min: 410}, name: 'Bahamut of Abyss / Vepar of Abyss' },
      ]
    },
    {
      imageSrc: subWater,
      coordinates: [
        { x: 29, y: 56, quantityMobs: 6, mobLevels:{min: 410}, name: 'Bahamut of Abyss / Vepar of Abyss' },
      ]
    },
    {
      imageSrc: hsWater,
      coordinates: [
        { x: 52, y: 21, quantityMobs: 9, mobLevels:{min: 410}, name: 'Bahamut of Abyss / Vepar of Abyss' },
      ]
    },
    {
      imageSrc: spotFire,
      coordinates: [
        { x: 183, y: 169, quantityMobs: 5, mobLevels:{min: 410}, name: 'Bahamut of Abyss / Vepar of Abyss' },
        { x: 197, y: 175, quantityMobs: 5, mobLevels:{min: 410}, name: 'Bahamut of Abyss / Vepar of Abyss' },
        { x: 214, y: 179, quantityMobs: 5, mobLevels:{min: 410}, name: 'Bahamut of Abyss / Vepar of Abyss' },
        { x: 205, y: 138, quantityMobs: 5, mobLevels:{min: 410}, name: 'Bahamut of Abyss / Vepar of Abyss' },
        { x: 208, y: 124, quantityMobs: 5, mobLevels:{min: 410}, name: 'Bahamut of Abyss / Vepar of Abyss' },
      ]
    },
    {
      imageSrc: subFire,
      coordinates: [
        { x: 240, y: 121, quantityMobs: 6, mobLevels:{min: 410}, name: 'Bahamut of Abyss / Vepar of Abyss' },
      ]
    },
    {
      imageSrc: hsFire,
      coordinates: [
        { x: 238, y: 153, quantityMobs: 9, mobLevels:{min: 410}, name: 'Bahamut of Abyss / Vepar of Abyss' },
      ]
    },
    {
      imageSrc: spotWind,
      coordinates: [
        { x: 162, y: 58, quantityMobs: 5, mobLevels:{min: 410}, name: 'Bahamut of Abyss / Vepar of Abyss' },
        { x: 177, y: 66, quantityMobs: 5, mobLevels:{min: 410}, name: 'Bahamut of Abyss / Vepar of Abyss' },
        { x: 201, y: 71, quantityMobs: 5, mobLevels:{min: 410}, name: 'Bahamut of Abyss / Vepar of Abyss' },
        { x: 178, y: 45, quantityMobs: 5, mobLevels:{min: 410}, name: 'Bahamut of Abyss / Vepar of Abyss' },
        { x: 189, y: 12, quantityMobs: 5, mobLevels:{min: 410}, name: 'Bahamut of Abyss / Vepar of Abyss' },
      ]
    },
    {
      imageSrc: subWind,
      coordinates: [
        { x: 223, y: 18, quantityMobs: 6, mobLevels:{min: 410}, name: 'Bahamut of Abyss / Vepar of Abyss' },
      ]
    },
    {
      imageSrc: hsWind,
      coordinates: [
        { x: 233, y: 52, quantityMobs: 9, mobLevels:{min: 410}, name: 'Bahamut of Abyss / Vepar of Abyss' },
      ]
    },
    {
      imageSrc: spotDark,
      coordinates: [
        { x: 79, y: 191, quantityMobs: 5, mobLevels:{min: 410}, name: 'Bahamut of Abyss / Vepar of Abyss' },
        { x: 79, y: 175, quantityMobs: 5, mobLevels:{min: 410}, name: 'Bahamut of Abyss / Vepar of Abyss' },
        { x: 102, y: 132, quantityMobs: 5, mobLevels:{min: 410}, name: 'Bahamut of Abyss / Vepar of Abyss' },
        { x: 36, y: 149, quantityMobs: 5, mobLevels:{min: 410}, name: 'Bahamut of Abyss / Vepar of Abyss' },
        { x: 59, y: 140, quantityMobs: 5, mobLevels:{min: 410}, name: 'Bahamut of Abyss / Vepar of Abyss' },
      ]
    },
    {
      imageSrc: subDark,
      coordinates: [
        { x: 70, y: 236, quantityMobs: 6, mobLevels:{min: 410}, name: 'Bahamut of Abyss / Vepar of Abyss' },
      ]
    },
    {
      imageSrc: hsDark,
      coordinates: [
        { x: 26, y: 199, quantityMobs: 9, mobLevels:{min: 410}, name: 'Bahamut of Abyss / Vepar of Abyss' },
      ]
    },
    {
      imageSrc: spotEarth,
      coordinates: [
        { x: 99, y: 147, quantityMobs: 5, mobLevels:{min: 410}, name: 'Bahamut of Abyss / Vepar of Abyss' },
        { x: 130, y: 185, quantityMobs: 5, mobLevels:{min: 410}, name: 'Bahamut of Abyss / Vepar of Abyss' },
        { x: 138, y: 206, quantityMobs: 5, mobLevels:{min: 410}, name: 'Bahamut of Abyss / Vepar of Abyss' },
        { x: 155, y: 201, quantityMobs: 5, mobLevels:{min: 410}, name: 'Bahamut of Abyss / Vepar of Abyss' },
        { x: 153, y: 173, quantityMobs: 5, mobLevels:{min: 410}, name: 'Bahamut of Abyss / Vepar of Abyss' },
      ]
    },
    {
      imageSrc: subEarth,
      coordinates: [
        { x: 177, y: 233, quantityMobs: 6, mobLevels:{min: 410}, name: 'Bahamut of Abyss / Vepar of Abyss' },
      ]
    },
    {
      imageSrc: hsEarth,
      coordinates: [
        { x: 121, y: 236, quantityMobs: 9, mobLevels:{min: 410}, name: 'Bahamut of Abyss / Vepar of Abyss' },
      ]
    },
  ],
}

const AtlansAbyss1 = () => <MuMap {...mapConfigs} />

export default AtlansAbyss1