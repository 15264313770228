import React, { useContext, useEffect } from "react";

import { Feature } from 'ol';
import { Style, Icon } from 'ol/style';
import { Point } from 'ol/geom';
import Vector from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';

import MapContext from "../MapContext";

const MarkerLayer = ({ imageSrc, anchor, coordinates }) => {
  const { map } = useContext(MapContext);

  useEffect(() => {
    if (!map) return;

    let layer = new Vector({
      style: new Style({
        image: new Icon({
          anchor: [ anchor.x.value, anchor.y.value ],
          anchorXUnits: anchor.x.unit,
          anchorYUnits: anchor.y.unit,
          src: imageSrc,
        })
      }),
      source: new VectorSource({
        features: coordinates.map(
          (props) => new Feature({
            geometry: new Point([props.x, props.y]),
            ...props,
          })
        ),
      }),
    });
    map.addLayer(layer);

    return () => {
      if (map) {
        map.removeLayer(layer);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map]);
  
  return <></>;
};

export default MarkerLayer;
