import React from "react"

import MuMap from '.';
import imageMap from '../../images/Mu/Data/Interface/GFx/NaviMap/Navimap09.png';

import hsNormal from '../../images/markers/hs-normal.png';
import subNormal from '../../images/markers/sub-normal.png';
import spotNormal from '../../images/markers/spot-normal.png';

const mapConfigs = {
  name: "Tarkan",
  url: imageMap,
  markers: [
    {
      imageSrc: spotNormal,
      coordinates: [
        // Tarkan 1
        { x: 96, y: 45, quantityMobs: 4, mobLevels:{min: 76, max: 80}, name: 'Bloody Wolf / Iron Wheel' },
        { x: 108, y: 39, quantityMobs: 4, mobLevels:{min: 76}, name: 'Bloody Wolf' },
        { x: 109, y: 84, quantityMobs: 4, mobLevels:{min: 76}, name: 'Bloody Wolf' },
        { x: 129, y: 75, quantityMobs: 4, mobLevels:{min: 72, max: 80}, name: 'Mutant / Bloody Wolf / Iron Wheel' },
        { x: 149, y: 39, quantityMobs: 4, mobLevels:{min: 72}, name: 'Mutant' },
        { x: 184, y: 114, quantityMobs: 4, mobLevels:{min: 72, max: 76}, name: 'Mutant / Bloody Wolf' },
        // Tarkan 2
        { x: 30, y: 213, quantityMobs: 4, mobLevels:{min: 83, max: 84}, name: 'Tantalos / Beam Knight' },
        { x: 161, y: 214, quantityMobs: 4, mobLevels:{min: 83, max: 84}, name: 'Tantalos / Beam Knight' },
        { x: 176, y: 210, quantityMobs: 4, mobLevels:{min: 83, max: 84}, name: 'Tantalos / Beam Knight' },
      ]
    },
    {
      imageSrc: subNormal,
      coordinates: [
        // Tarkan 1
        { x: 103, y: 61, quantityMobs: 5, mobLevels:{min: 76}, name: 'Bloody Wolf' },
      ]
    },
    {
      imageSrc: hsNormal,
      coordinates: [
        // Tarkan 2
        { x: 13, y: 237, quantityMobs: 5, mobLevels:{min: 83, max: 90}, name: 'Tantalos / Beam Knight / Zaikan' },
      ]
    },
  ],
}

const Tarkan = () => <MuMap {...mapConfigs} />

export default Tarkan