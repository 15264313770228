import React from 'react';
import { Routes, Route } from "react-router-dom";
import Home from '../pages/home';
import Maps from '../pages/maps';
import Maze from '../pages/maze';
import ExperienceCalculator from '../pages/experience-calculator';


const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="maps/*" element={<Maps />} />
      <Route path="maze-of-dimensions/" element={<Maze />} />
      <Route path="experience-calculator/" element={<ExperienceCalculator />} />
    </Routes>
  )
}

export default AppRoutes