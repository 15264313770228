import React from 'react';
import { Typography, Paper } from '@mui/material';
import AppLogo from '../AppLogo';

const Menu = ({ title }) => {
  return (
    <Paper
      square
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      }}>
      <AppLogo sx={{ width: 64, height: 64 }} />
      <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
        {title}
      </Typography>
    </Paper>
  );
}

export default Menu