import React from "react"

import MuMap from '.';
import imageMap from '../../images/Mu/Data/Interface/GFx/NaviMap/Navimap121.png';

import hsDark from '../../images/markers/hs-dark.png';
import hsFire from '../../images/markers/hs-fire.png';
import hsWater from '../../images/markers/hs-water.png';
import hsWind from '../../images/markers/hs-wind.png';
import hsEarth from '../../images/markers/hs-earth.png';

import subDark from '../../images/markers/sub-dark.png';
import subEarth from '../../images/markers/sub-earth.png';
import subFire from '../../images/markers/sub-fire.png';
import subWater from '../../images/markers/sub-water.png';
import subWind from '../../images/markers/sub-wind.png';

import spotDark from '../../images/markers/spot-dark.png';
import spotEarth from '../../images/markers/spot-earth.png';
import spotFire from '../../images/markers/spot-fire.png';
import spotWater from '../../images/markers/spot-water.png';
import spotWind from '../../images/markers/spot-wind.png';

const mapConfigs = {
  name: "Deep Dungeon 5",
  url: imageMap,
  markers: [
    {
      imageSrc: spotWater,
      coordinates: [
        { x: 20, y: 194, quantityMobs: 4, mobLevels:{min: 275}, name: 'Deep Dungeon Thunder Lich / Deep Dungeon Poison Bull Fighter / Deep Dungeon Dark Knight' },
        { x: 39, y: 150, quantityMobs: 4, mobLevels:{min: 275}, name: 'Deep Dungeon Thunder Lich / Deep Dungeon Poison Bull Fighter / Deep Dungeon Dark Knight' },
        { x: 56, y: 130, quantityMobs: 4, mobLevels:{min: 275}, name: 'Deep Dungeon Thunder Lich / Deep Dungeon Poison Bull Fighter / Deep Dungeon Dark Knight' },
      ]
    },
    {
      imageSrc: subWater,
      coordinates: [
        { x: 68, y: 198, quantityMobs: 6, mobLevels:{min: 275}, name: 'Deep Dungeon Thunder Lich / Deep Dungeon Poison Bull Fighter / Deep Dungeon Dark Knight' },
        { x: 27, y: 162, quantityMobs: 5, mobLevels:{min: 275}, name: 'Deep Dungeon Thunder Lich / Deep Dungeon Poison Bull Fighter / Deep Dungeon Dark Knight' },
      ]
    },
    {
      imageSrc: hsWater,
      coordinates: [
        { x: 22, y: 219, quantityMobs: 7, mobLevels:{min: 275}, name: 'Deep Dungeon Thunder Lich / Deep Dungeon Poison Bull Fighter / Deep Dungeon Dark Knight' },
      ]
    },
    {
      imageSrc: spotFire,
      coordinates: [
        { x: 105, y: 80, quantityMobs: 4, mobLevels:{min: 275}, name: 'Deep Dungeon Thunder Lich / Deep Dungeon Poison Bull Fighter / Deep Dungeon Dark Knight' },
      ]
    },
    {
      imageSrc: subFire,
      coordinates: [
        { x: 38, y: 93, quantityMobs: 5, mobLevels:{min: 275}, name: 'Deep Dungeon Thunder Lich / Deep Dungeon Poison Bull Fighter / Deep Dungeon Dark Knight' },
        { x: 8, y: 58, quantityMobs: 5, mobLevels:{min: 275}, name: 'Deep Dungeon Thunder Lich / Deep Dungeon Poison Bull Fighter / Deep Dungeon Dark Knight' },
        { x: 66, y: 79, quantityMobs: 5, mobLevels:{min: 275}, name: 'Deep Dungeon Thunder Lich / Deep Dungeon Poison Bull Fighter / Deep Dungeon Dark Knight' },
        { x: 92, y: 70, quantityMobs: 5, mobLevels:{min: 275}, name: 'Deep Dungeon Thunder Lich / Deep Dungeon Poison Bull Fighter / Deep Dungeon Dark Knight' },
        { x: 112, y: 65, quantityMobs: 5, mobLevels:{min: 275}, name: 'Deep Dungeon Thunder Lich / Deep Dungeon Poison Bull Fighter / Deep Dungeon Dark Knight' },
      ]
    },
    {
      imageSrc: hsFire,
      coordinates: [
        { x: 38, y: 10, quantityMobs: 7, mobLevels:{min: 275}, name: 'Deep Dungeon Thunder Lich / Deep Dungeon Poison Bull Fighter / Deep Dungeon Dark Knight' },
      ]
    },
    {
      imageSrc: spotWind,
      coordinates: [
        { x: 107, y: 231, quantityMobs: 4, mobLevels:{min: 275}, name: 'Deep Dungeon Thunder Lich / Deep Dungeon Poison Bull Fighter / Deep Dungeon Dark Knight' },
        { x: 133, y: 183, quantityMobs: 4, mobLevels:{min: 275}, name: 'Deep Dungeon Thunder Lich / Deep Dungeon Poison Bull Fighter / Deep Dungeon Dark Knight' },
        { x: 167, y: 191, quantityMobs: 4, mobLevels:{min: 275}, name: 'Deep Dungeon Thunder Lich / Deep Dungeon Poison Bull Fighter / Deep Dungeon Dark Knight' },
        { x: 198, y: 172, quantityMobs: 4, mobLevels:{min: 275}, name: 'Deep Dungeon Thunder Lich / Deep Dungeon Poison Bull Fighter / Deep Dungeon Dark Knight' },
      ]
    },
    {
      imageSrc: subWind,
      coordinates: [
        { x: 132, y: 155, quantityMobs: 5, mobLevels:{min: 275}, name: 'Deep Dungeon Thunder Lich / Deep Dungeon Poison Bull Fighter / Deep Dungeon Dark Knight' },
        { x: 195, y: 209, quantityMobs: 6, mobLevels:{min: 275}, name: 'Deep Dungeon Thunder Lich / Deep Dungeon Poison Bull Fighter / Deep Dungeon Dark Knight' },
      ]
    },
    {
      imageSrc: hsWind,
      coordinates: [
        { x: 173, y: 209, quantityMobs: 7, mobLevels:{min: 275}, name: 'Deep Dungeon Thunder Lich / Deep Dungeon Poison Bull Fighter / Deep Dungeon Dark Knight' },
      ]
    },
    {
      imageSrc: spotDark,
      coordinates: [
        { x: 167, y: 126, quantityMobs: 4, mobLevels:{min: 275}, name: 'Deep Dungeon Thunder Lich / Deep Dungeon Poison Bull Fighter / Deep Dungeon Dark Knight' },
        { x: 234, y: 164, quantityMobs: 4, mobLevels:{min: 275}, name: 'Deep Dungeon Thunder Lich / Deep Dungeon Poison Bull Fighter / Deep Dungeon Dark Knight' },
        { x: 221, y: 67, quantityMobs: 4, mobLevels:{min: 275}, name: 'Deep Dungeon Thunder Lich / Deep Dungeon Poison Bull Fighter / Deep Dungeon Dark Knight' },
      ]
    },
    {
      imageSrc: subDark,
      coordinates: [
        { x: 194, y: 139, quantityMobs: 5, mobLevels:{min: 275}, name: 'Deep Dungeon Thunder Lich / Deep Dungeon Poison Bull Fighter / Deep Dungeon Dark Knight' },
        { x: 181, y: 100, quantityMobs: 6, mobLevels:{min: 275}, name: 'Deep Dungeon Thunder Lich / Deep Dungeon Poison Bull Fighter / Deep Dungeon Dark Knight' },
        { x: 246, y: 64, quantityMobs: 5, mobLevels:{min: 275}, name: 'Deep Dungeon Thunder Lich / Deep Dungeon Poison Bull Fighter / Deep Dungeon Dark Knight' },
      ]
    },
    {
      imageSrc: hsDark,
      coordinates: [
        { x: 246, y: 104, quantityMobs: 7, mobLevels:{min: 275}, name: 'Deep Dungeon Thunder Lich / Deep Dungeon Poison Bull Fighter / Deep Dungeon Dark Knight' },
      ]
    },
    {
      imageSrc: spotEarth,
      coordinates: [
        { x: 135, y: 44, quantityMobs: 4, mobLevels:{min: 275}, name: 'Deep Dungeon Thunder Lich / Deep Dungeon Poison Bull Fighter / Deep Dungeon Dark Knight' },
        { x: 168, y: 68, quantityMobs: 4, mobLevels:{min: 275}, name: 'Deep Dungeon Thunder Lich / Deep Dungeon Poison Bull Fighter / Deep Dungeon Dark Knight' },
        { x: 182, y: 27, quantityMobs: 4, mobLevels:{min: 275}, name: 'Deep Dungeon Thunder Lich / Deep Dungeon Poison Bull Fighter / Deep Dungeon Dark Knight' },
      ]
    },
    {
      imageSrc: subEarth,
      coordinates: [
        { x: 151, y: 74, quantityMobs: 6, mobLevels:{min: 275}, name: 'Deep Dungeon Thunder Lich / Deep Dungeon Poison Bull Fighter / Deep Dungeon Dark Knight' },
        { x: 169, y: 51, quantityMobs: 5, mobLevels:{min: 275}, name: 'Deep Dungeon Thunder Lich / Deep Dungeon Poison Bull Fighter / Deep Dungeon Dark Knight' },
        { x: 224, y: 25, quantityMobs: 6, mobLevels:{min: 275}, name: 'Deep Dungeon Thunder Lich / Deep Dungeon Poison Bull Fighter / Deep Dungeon Dark Knight' },
      ]
    },
    {
      imageSrc: hsEarth,
      coordinates: [
        { x: 121, y: 17, quantityMobs: 7, mobLevels:{min: 275}, name: 'Deep Dungeon Thunder Lich / Deep Dungeon Poison Bull Fighter / Deep Dungeon Dark Knight' },
      ]
    },
  ],
}

const DeepDungeon5 = () => <MuMap {...mapConfigs} />

export default DeepDungeon5