import React from "react"

import MuMap from '.';
import imageMap from '../../images/Mu/Data/Interface/GFx/NaviMap/Navimap92.png';

import hsDark from '../../images/markers/hs-dark.png';
import hsWind from '../../images/markers/hs-wind.png';
import hsEarth from '../../images/markers/hs-earth.png';

import subEarth from '../../images/markers/sub-earth.png';
import subFire from '../../images/markers/sub-fire.png';
import subWater from '../../images/markers/sub-water.png';
import subWind from '../../images/markers/sub-wind.png';
import subNormal from '../../images/markers/sub-normal.png';

import spotFire from '../../images/markers/spot-fire.png';
import spotWater from '../../images/markers/spot-water.png';
import spotWind from '../../images/markers/spot-wind.png';

const mapConfigs = {
  name: "Archeron - Alkmar / Ubaid",
  url: imageMap,
  markers: [
    {
      imageSrc: spotWater,
      coordinates: [
        { x: 119, y: 127, quantityMobs: 3, mobLevels:{min: 88, max: 91}, name: 'Devil Fairy / Elemental Beast' },
        { x: 144, y: 11, quantityMobs: 3, mobLevels:{min: 91, max: 91}, name: 'Elemental Beast / Elemental Knight' },
        { x: 189, y: 221, quantityMobs: 3, mobLevels:{min: 99, max: 103}, name: 'Ubaid Devil Fairy / Ubaid Elemental Beast / Ubaid Elemental Knight' },
      ]
    },
    {
      imageSrc: spotWind,
      coordinates: [
        { x: 35, y: 84, quantityMobs: 3, mobLevels:{min: 91}, name: 'Elemental Beast' },
        { x: 237, y: 86, quantityMobs: 3, mobLevels:{min: 101, max: 103}, name: 'Ubaid Elemental Beast / Ubaid Elemental Knight' },
      ]
    },
    {
      imageSrc: subFire,
      coordinates: [
        { x: 156, y: 145, quantityMobs: 4, mobLevels:{min: 88}, name: 'Devil Fairy' },
        { x: 18, y: 25, quantityMobs: 4, mobLevels:{min: 88, max: 93}, name: 'Devil Fairy / Elemental Beast / Elemental Knight' },
        { x: 191, y: 153, quantityMobs: 4, mobLevels:{min: 99, max: 101}, name: 'Ubaid Devil Fairy / Ubaid Elemental Beast' },
      ]
    },
    {
      imageSrc: subWater,
      coordinates: [
        { x: 58, y: 136, quantityMobs: 4, mobLevels:{min: 88}, name: 'Devil Fairy' },
        { x: 239, y: 230, quantityMobs: 4, mobLevels:{min: 99}, name: 'Ubaid Devil Fairy' },
      ]
    },
    {
      imageSrc: subWind,
      coordinates: [
        { x: 239, y: 171, quantityMobs: 4, mobLevels:{min: 99, max: 101}, name: 'Ubaid Devil Fairy / Ubaid Elemental Beast' },
      ]
    },
    {
      imageSrc: hsDark,
      coordinates: [
        { x: 158, y: 67, quantityMobs: 5, mobLevels:{min: 88, max: 91}, name: 'Devil Fairy / Elemental Beast' },
        { x: 209, y: 57, quantityMobs: 8, mobLevels:{min: 99, max: 101}, name: 'Ubaid Devil Fairy / Ubaid Elemental Beast' },
      ]
    },
    {
      imageSrc: spotFire,
      coordinates: [
        { x: 228, y: 140, quantityMobs: 3, mobLevels:{min: 99, max: 103}, name: 'Ubaid Devil Fairy / Ubaid Elemental Beast / Ubaid Elemental Knight' },
        { x: 221, y: 56, quantityMobs: 3, mobLevels:{min: 99, max: 101}, name: 'Ubaid Devil Fairy / Ubaid Elemental Beast' },
      ]
    },
    {
      imageSrc: subNormal,
      coordinates: [
        { x: 240, y: 50, quantityMobs: 4, mobLevels:{min: 99, max: 103}, name: 'Ubaid Devil Fairy / Ubaid Elemental Beast / Ubaid Elemental Knight' },
      ]
    },
    {
      imageSrc: subEarth,
      coordinates: [
        { x: 42, y: 112, quantityMobs: 4, mobLevels:{min: 88, max: 91}, name: 'Devil Fairy / Elemental Beast' },
        { x: 173, y: 41, quantityMobs: 4, mobLevels:{min: 91, max: 91}, name: 'Elemental Beast / Elemental Knight' },
        { x: 215, y: 211, quantityMobs: 4, mobLevels:{min: 99, max: 103}, name: 'Ubaid Devil Fairy / Ubaid Elemental Beast / Ubaid Elemental Knight' },
      ]
    },
    {
      imageSrc: hsEarth,
      coordinates: [
        { x: 240, y: 206, quantityMobs: 5, mobLevels:{min: 99, max: 101}, name: 'Ubaid Devil Fairy / Ubaid Elemental Beast' },
      ]
    },
    {
      imageSrc: hsWind,
      coordinates: [
        { x: 222, y: 21, quantityMobs: 8, mobLevels:{min: 91, max: 93}, name: 'Elemental Beast / Elemental Knight' },
      ]
    },
  ],
}

const ArcheronAlkmarUbaid = () => <MuMap {...mapConfigs} />

export default ArcheronAlkmarUbaid