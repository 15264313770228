import React from "react"

import MuMap from '.';
import imageMap from '../../images/Mu/Data/Interface/GFx/NaviMap/Navimap08.png';

import hsNormal from '../../images/markers/hs-normal.png';
import subNormal from '../../images/markers/sub-normal.png';
import spotNormal from '../../images/markers/spot-normal.png';

const mapConfigs = {
  name: "Atlans",
  url: imageMap,
  markers: [
    {
      imageSrc: spotNormal,
      coordinates: [
        // Atlans 1
        { x: 35, y: 117, quantityMobs: 3, mobLevels:{min: 45, max: 46}, name: 'Valkyrie / Vepar' },
        { x: 46, y: 12, quantityMobs: 3, mobLevels:{min: 43, max: 45}, name: 'Bahamut / Vepar' },
        { x: 60, y: 20, quantityMobs: 3, mobLevels:{min: 43, max: 45}, name: 'Bahamut / Vepar' },
        { x: 68, y: 84, quantityMobs: 3, mobLevels:{min: 45, max: 46}, name: 'Valkyrie / Vepar' },
        { x: 89, y: 63, quantityMobs: 3, mobLevels:{min: 45, max: 46}, name: 'Valkyrie / Vepar' },
        { x: 110, y: 15, quantityMobs: 3, mobLevels:{min: 45, max: 46}, name: 'Valkyrie / Vepar' },
        // Atlans 2
        { x: 143, y: 14, quantityMobs: 3, mobLevels:{min: 45, max: 46}, name: 'Valkyrie / Vepar' },
        { x: 171, y: 58, quantityMobs: 3, mobLevels:{min: 46, max: 66}, name: 'Valkyrie / Great Bahamut' },
        { x: 189, y: 52, quantityMobs: 3, mobLevels:{min: 46, max: 66}, name: 'Valkyrie / Great Bahamut' },
        { x: 226, y: 129, quantityMobs: 3, mobLevels:{min: 70}, name: 'Lizard King' },
        // Atlans 3
        { x: 35, y: 232, quantityMobs: 3, mobLevels:{min: 68, max: 70}, name: 'Silver Valkyrie / Lizard King' },
        { x: 85, y: 214, quantityMobs: 3, mobLevels:{min: 68, max: 70}, name: 'Silver Valkyrie / Lizard King' },
        { x: 96, y: 146, quantityMobs: 3, mobLevels:{min: 68}, name: 'Silver Valkyrie' },
        { x: 118, y: 133, quantityMobs: 3, mobLevels:{min: 68}, name: 'Silver Valkyrie' },
        { x: 159, y: 197, quantityMobs: 3, mobLevels:{min: 68, max: 70}, name: 'Silver Valkyrie / Lizard King' },
      ]
    },
    {
      imageSrc: subNormal,
      coordinates: [
        // Atlans 1
        { x: 69, y: 52, quantityMobs: 4, mobLevels:{min: 43, max: 45}, name: 'Bahamut / Vepar' },
        { x: 110, y: 41, quantityMobs: 4, mobLevels:{min: 45, max: 46}, name: 'Valkyrie / Vepar' },
        // Atlans 2
        { x: 223, y: 75, quantityMobs: 4, mobLevels:{min: 66, max: 68}, name: 'Silver Valkyrie / Great Bahamut' },
        { x: 227, y: 156, quantityMobs: 4, mobLevels:{min: 68, max: 70}, name: 'Silver Valkyrie / Lizard King' },
        { x: 232, y: 18, quantityMobs: 4, mobLevels:{min: 46, max: 66}, name: 'Valkyrie / Great Bahamut' },
        // Atlans 3
        { x: 74, y: 129, quantityMobs: 4, mobLevels:{min: 66, max: 68}, name: 'Valkyrie / Great Bahamut' },
        { x: 86, y: 180, quantityMobs: 4, mobLevels:{min: 66, max: 68}, name: 'Valkyrie / Great Bahamut' },
        { x: 114, y: 178, quantityMobs: 4, mobLevels:{min: 66, max: 68}, name: 'Valkyrie / Great Bahamut' },
        { x: 134, y: 170, quantityMobs: 4, mobLevels:{min: 66, max: 68}, name: 'Valkyrie / Great Bahamut' },
      ]
    },
    {
      imageSrc: hsNormal,
      coordinates: [
        // Atlans 1
        { x: 47, y: 67, quantityMobs: 5, mobLevels:{min: 43, max: 45}, name: 'Bahamut / Vepar' },
        { x: 79, y: 19, quantityMobs: 5, mobLevels:{min: 43, max: 45}, name: 'Bahamut / Vepar' },
        { x: 117, y: 28, quantityMobs: 5, mobLevels:{min: 45, max: 46}, name: 'Valkyrie / Vepar' },
        // Atlans 2
        { x: 184, y: 93, quantityMobs: 5, mobLevels:{min: 66, max: 68}, name: 'Silver Valkyrie / Great Bahamut' },
        { x: 222, y: 191, quantityMobs: 6, mobLevels:{min: 70, max: 74}, name: 'Hydra / Lizard King' },
        { x: 228, y: 51, quantityMobs: 5, mobLevels:{min: 46, max: 70}, name: 'Valkyrie / Great Bahamut / Lizard King' },
        { x: 230, y: 174, quantityMobs: 5, mobLevels:{min: 68, max: 70}, name: 'Silver Valkyrie / Lizard King' },
        // Atlans 3
        { x: 23, y: 231, quantityMobs: 7, mobLevels:{min: 68, max: 74}, name: 'Silver Valkyrie / Lizard King / Hydra' },
        { x: 31, y: 207, quantityMobs: 6, mobLevels:{min: 68, max: 70}, name: 'Silver Valkyrie / Lizard King' },
        { x: 46, y: 224, quantityMobs: 6, mobLevels:{min: 68, max: 70}, name: 'Silver Valkyrie / Lizard King' },
        { x: 65, y: 180, quantityMobs: 5, mobLevels:{min: 66, max: 68}, name: 'Valkyrie / Great Bahamut' },
        { x: 66, y: 142, quantityMobs: 5, mobLevels:{min: 66, max: 68}, name: 'Valkyrie / Great Bahamut' },
        { x: 166, y: 147, quantityMobs: 6, mobLevels:{min: 68, max: 70}, name: 'Silver Valkyrie / Lizard King' },
      ]
    },
  ],
}

const Atlans = () => <MuMap {...mapConfigs} />

export default Atlans