import React from "react"

import MuMap from '.';
import imageMap from '../../images/Mu/Data/Interface/GFx/NaviMap/Navimap114.png';

import hsDark from '../../images/markers/hs-dark.png';
import hsFire from '../../images/markers/hs-fire.png';
import hsWater from '../../images/markers/hs-water.png';
import hsWind from '../../images/markers/hs-wind.png';
import hsEarth from '../../images/markers/hs-earth.png';

import subDark from '../../images/markers/sub-dark.png';
import subEarth from '../../images/markers/sub-earth.png';
import subFire from '../../images/markers/sub-fire.png';
import subWater from '../../images/markers/sub-water.png';
import subWind from '../../images/markers/sub-wind.png';

import spotDark from '../../images/markers/spot-dark.png';
import spotEarth from '../../images/markers/spot-earth.png';
import spotFire from '../../images/markers/spot-fire.png';
import spotWater from '../../images/markers/spot-water.png';
import spotWind from '../../images/markers/spot-wind.png';

const mapConfigs = {
  name: "Nixies Lake",
  url: imageMap,
  markers: [
    {
      imageSrc: spotFire,
      coordinates: [
        { x: 20, y: 88, quantityMobs: 4, mobLevels:{min: 125, max: 129}, name: 'Paraca / Bass / Vasuki' },
        { x: 19, y: 45, quantityMobs: 3, mobLevels:{min: 125, max: 129}, name: 'Paraca / Bass / Vasuki' },
        { x: 78, y: 81, quantityMobs: 3, mobLevels:{min: 125, max: 129}, name: 'Paraca / Bass / Vasuki' },
        { x: 33, y: 14, quantityMobs: 4, mobLevels:{min: 125, max: 129}, name: 'Paraca / Bass / Vasuki' },
        { x: 94, y: 64, quantityMobs: 4, mobLevels:{min: 125, max: 129}, name: 'Paraca / Bass / Vasuki' },
      ]
    },
    {
      imageSrc: subFire,
      coordinates: [
      ]
    },
    {
      imageSrc: hsFire,
      coordinates: [
        { x: 17, y: 21, quantityMobs: 9, mobLevels:{min: 125, max: 129}, name: 'Paraca / Bass / Vasuki' },
        { x: 54, y: 16, quantityMobs: 9, mobLevels:{min: 125, max: 129}, name: 'Paraca / Bass / Vasuki' },
      ]
    },
    {
      imageSrc: spotWind,
      coordinates: [
        { x: 237, y: 218, quantityMobs: 4, mobLevels:{min: 125, max: 129}, name: 'Paraca / Bass / Vasuki' },
        { x: 237, y: 182, quantityMobs: 3, mobLevels:{min: 125, max: 129}, name: 'Paraca / Bass / Vasuki' },
        { x: 153, y: 133, quantityMobs: 4, mobLevels:{min: 125, max: 129}, name: 'Paraca / Bass / Vasuki' },
        { x: 201, y: 121, quantityMobs: 3, mobLevels:{min: 125, max: 129}, name: 'Paraca / Bass / Vasuki' },
      ]
    },
    {
      imageSrc: subWind,
      coordinates: [
        { x: 183, y: 163, quantityMobs: 5, mobLevels:{min: 125, max: 129}, name: 'Paraca / Bass / Vasuki' },
        { x: 184, y: 117, quantityMobs: 5, mobLevels:{min: 125, max: 129}, name: 'Paraca / Bass / Vasuki' },
      ]
    },
    {
      imageSrc: hsWind,
      coordinates: [
        { x: 179, y: 219, quantityMobs: 9, mobLevels:{min: 125, max: 129}, name: 'Paraca / Bass / Vasuki' },
        { x: 233, y: 131, quantityMobs: 9, mobLevels:{min: 125, max: 129}, name: 'Paraca / Bass / Vasuki' },
      ]
    },
    {
      imageSrc: spotWater,
      coordinates: [
        { x: 237, y: 109, quantityMobs: 4, mobLevels:{min: 125, max: 129}, name: 'Paraca / Bass / Vasuki' },
        { x: 207, y: 66, quantityMobs: 4, mobLevels:{min: 125, max: 129}, name: 'Paraca / Bass / Vasuki' },
        { x: 218, y: 55, quantityMobs: 3, mobLevels:{min: 125, max: 129}, name: 'Paraca / Bass / Vasuki' },
        { x: 239, y: 50, quantityMobs: 3, mobLevels:{min: 125, max: 129}, name: 'Paraca / Bass / Vasuki' },
      ]
    },
    {
      imageSrc: subWater,
      coordinates: [
        { x: 216, y: 106, quantityMobs: 5, mobLevels:{min: 125, max: 129}, name: 'Paraca / Bass / Vasuki' },
        { x: 241, y: 27, quantityMobs: 5, mobLevels:{min: 125, max: 129}, name: 'Paraca / Bass / Vasuki' },
      ]
    },
    {
      imageSrc: hsWater,
      coordinates: [
        { x: 203, y: 99, quantityMobs: 9, mobLevels:{min: 125, max: 129}, name: 'Paraca / Bass / Vasuki' },
        { x: 228, y: 13, quantityMobs: 9, mobLevels:{min: 125, max: 129}, name: 'Paraca / Bass / Vasuki' },
      ]
    },
    {
      imageSrc: spotDark,
      coordinates: [
        { x: 114, y: 68, quantityMobs: 3, mobLevels:{min: 125, max: 129}, name: 'Paraca / Bass / Vasuki' },
        { x: 105, y: 44, quantityMobs: 3, mobLevels:{min: 125, max: 129}, name: 'Paraca / Bass / Vasuki' },
        { x: 97, y: 17, quantityMobs: 4, mobLevels:{min: 125, max: 129}, name: 'Paraca / Bass / Vasuki' },
        { x: 167, y: 29, quantityMobs: 3, mobLevels:{min: 125, max: 129}, name: 'Paraca / Bass / Vasuki' },
        { x: 189, y: 41, quantityMobs: 4, mobLevels:{min: 125, max: 129}, name: 'Paraca / Bass / Vasuki' },
      ]
    },
    {
      imageSrc: subDark,
      coordinates: [
        { x: 120, y: 15, quantityMobs: 5, mobLevels:{min: 125, max: 129}, name: 'Paraca / Bass / Vasuki' },
        { x: 177, y: 14, quantityMobs: 5, mobLevels:{min: 125, max: 129}, name: 'Paraca / Bass / Vasuki' },
      ]
    },
    {
      imageSrc: hsDark,
      coordinates: [
        { x: 144, y: 14, quantityMobs: 9, mobLevels:{min: 125, max: 129}, name: 'Paraca / Bass / Vasuki' },
        { x: 196, y: 19, quantityMobs: 9, mobLevels:{min: 125, max: 129}, name: 'Paraca / Bass / Vasuki' },
      ]
    },
    {
      imageSrc: spotEarth,
      coordinates: [
        { x: 40, y: 161, quantityMobs: 4, mobLevels:{min: 125, max: 129}, name: 'Paraca / Bass / Vasuki' },
        { x: 144, y: 226, quantityMobs: 3, mobLevels:{min: 125, max: 129}, name: 'Paraca / Bass / Vasuki' },
        { x: 123, y: 197, quantityMobs: 3, mobLevels:{min: 125, max: 129}, name: 'Paraca / Bass / Vasuki' },
        { x: 89, y: 159, quantityMobs: 3, mobLevels:{min: 125, max: 129}, name: 'Paraca / Bass / Vasuki' },
        { x: 161, y: 207, quantityMobs: 4, mobLevels:{min: 125, max: 129}, name: 'Paraca / Bass / Vasuki' },
      ]
    },
    {
      imageSrc: subEarth,
      coordinates: [
        { x: 53, y: 182, quantityMobs: 5, mobLevels:{min: 125, max: 129}, name: 'Paraca / Bass / Vasuki' },
        { x: 153, y: 177, quantityMobs: 5, mobLevels:{min: 125, max: 129}, name: 'Paraca / Bass / Vasuki' },
      ]
    },
    {
      imageSrc: hsEarth,
      coordinates: [
        { x: 80, y: 236, quantityMobs: 9, mobLevels:{min: 125, max: 129}, name: 'Paraca / Bass / Vasuki' },
        { x: 137, y: 154, quantityMobs: 9, mobLevels:{min: 125, max: 129}, name: 'Paraca / Bass / Vasuki' },
      ]
    },
  ],
}

const NixiesLake = () => <MuMap {...mapConfigs} />

export default NixiesLake