import React from "react"

import MuMap from '.';
import imageMap from '../../images/Mu/Data/Interface/GFx/NaviMap/Navimap81.png';

import subNormal from '../../images/markers/sub-normal.png';
import spotNormal from '../../images/markers/spot-normal.png';

const mapConfigs = {
  name: "Karutan 1",
  url: imageMap,
  markers: [
    {
      imageSrc: spotNormal,
      coordinates: [
        { x: 52, y: 67, quantityMobs: 3, mobLevels:{min: 111, max: 114}, name: 'Crypta / Crypos' },
        { x: 169, y: 64, quantityMobs: 3, mobLevels:{min: 99, max: 103}, name: 'Poisonuos Scorpion / Bone Scorpion' },
        { x: 201, y: 102, quantityMobs: 3, mobLevels:{min: 99, max: 103}, name: 'Poisonuos Scorpion / Bone Scorpion' },
      ]
    },
    {
      imageSrc: subNormal,
      coordinates: [
        { x: 38, y: 165, quantityMobs: 4, mobLevels:{min: 111, max: 114}, name: 'Crypta / Crypos' },
        { x: 37, y: 141, quantityMobs: 4, mobLevels:{min: 111, max: 114}, name: 'Crypta / Crypos' },
        { x: 41, y: 89, quantityMobs: 4, mobLevels:{min: 111, max: 114}, name: 'Crypta / Crypos' },
        { x: 104, y: 184, quantityMobs: 4, mobLevels:{min: 99, max: 103}, name: 'Poisonuos Scorpion / Bone Scorpion' },
        { x: 91, y: 73, quantityMobs: 4, mobLevels:{min: 99, max: 103}, name: 'Poisonuos Scorpion / Bone Scorpion' },
        { x: 225, y: 164, quantityMobs: 4, mobLevels:{min: 103}, name: 'Bone Scorpion' },
        { x: 205, y: 36, quantityMobs: 4, mobLevels:{min: 99, max: 105}, name: 'Poisonuos Scorpion / Orcus / Bone Scorpion' },
      ]
    },
  ],
}

const Karutan1 = () => <MuMap {...mapConfigs} />

export default Karutan1