import React from 'react';
import { CssBaseline, Container, Typography } from '@mui/material';
import LevelTimeCalculator from './LevelTimeCalculator';


const ExperienceCalculator = () => {
  return (
    <>
      <CssBaseline />
      <Container style={{ paddingTop: 20 }}>
        <Typography variant='h4' color="textPrimary">
          Calculadora de experiência
        </Typography>
      </Container>
      <Container>
        <LevelTimeCalculator />
      </Container>
    </>
  );
}

export default ExperienceCalculator
