import React from 'react';
import { Avatar, Box } from '@mui/material';
import ImageLogo from  '../images/logo.png';

const AppLogo = (props) => {
  return (
    <Box
      sx={{
        background: 'radial-gradient(circle, rgba(59,88,131,1) 0%, rgba(255,255,255,0) 70%)'
      }}
    >
      <Avatar
        alt="Logo MuOnline.app"
        src={ImageLogo}
        {...props}
      />
    </Box>
  );
}

export default AppLogo