import React from "react"

import MuMap from '.';
import imageMap from '../../images/Mu/Data/Interface/GFx/NaviMap/Navimap117.png';

import hsDark from '../../images/markers/hs-dark.png';
import hsFire from '../../images/markers/hs-fire.png';
import hsWater from '../../images/markers/hs-water.png';
import hsWind from '../../images/markers/hs-wind.png';
import hsEarth from '../../images/markers/hs-earth.png';

import subDark from '../../images/markers/sub-dark.png';
import subEarth from '../../images/markers/sub-earth.png';
import subFire from '../../images/markers/sub-fire.png';
import subWater from '../../images/markers/sub-water.png';
import subWind from '../../images/markers/sub-wind.png';

import spotDark from '../../images/markers/spot-dark.png';
import spotEarth from '../../images/markers/spot-earth.png';
import spotFire from '../../images/markers/spot-fire.png';
import spotWater from '../../images/markers/spot-water.png';
import spotWind from '../../images/markers/spot-wind.png';

const mapConfigs = {
  name: "Deep Dungeon 1",
  center: [128, 160],
  url: imageMap,
  markers: [
    {
      imageSrc: spotFire,
      coordinates: [
        { x: 11, y: 141, quantityMobs: 4, mobLevels:{min: 170}, name: 'Deep Dungeon Larva / Deep Dungeon Skeleton Warrior' },
        { x: 60, y: 130, quantityMobs: 4, mobLevels:{min: 170}, name: 'Deep Dungeon Larva / Deep Dungeon Skeleton Warrior' },
        { x: 99, y: 131, quantityMobs: 4, mobLevels:{min: 170}, name: 'Deep Dungeon Larva / Deep Dungeon Skeleton Warrior' },
      ]
    },
    {
      imageSrc: subFire,
      coordinates: [
        { x: 11, y: 173, quantityMobs: 6, mobLevels:{min: 170}, name: 'Deep Dungeon Larva / Deep Dungeon Skeleton Warrior' },
        { x: 62, y: 148, quantityMobs: 5, mobLevels:{min: 170}, name: 'Deep Dungeon Larva / Deep Dungeon Skeleton Warrior' },
        { x: 89, y: 149, quantityMobs: 5, mobLevels:{min: 170}, name: 'Deep Dungeon Larva / Deep Dungeon Skeleton Warrior' },
      ]
    },
    {
      imageSrc: hsFire,
      coordinates: [
        { x: 47, y: 186, quantityMobs: 7, mobLevels:{min: 170}, name: 'Deep Dungeon Larva / Deep Dungeon Skeleton Warrior' },
      ]
    },
    {
      imageSrc: spotWind,
      coordinates: [
        { x: 172, y: 177, quantityMobs: 4, mobLevels:{min: 170}, name: 'Deep Dungeon Larva / Deep Dungeon Skeleton Warrior' },
        { x: 186, y: 237, quantityMobs: 4, mobLevels:{min: 170}, name: 'Deep Dungeon Larva / Deep Dungeon Skeleton Warrior' },
        { x: 213, y: 234, quantityMobs: 4, mobLevels:{min: 170}, name: 'Deep Dungeon Larva / Deep Dungeon Skeleton Warrior' },
      ]
    },
    {
      imageSrc: subWind,
      coordinates: [
        { x: 190, y: 176, quantityMobs: 5, mobLevels:{min: 170}, name: 'Deep Dungeon Larva / Deep Dungeon Skeleton Warrior' },
        { x: 214, y: 213, quantityMobs: 6, mobLevels:{min: 170}, name: 'Deep Dungeon Larva / Deep Dungeon Skeleton Warrior' },
        { x: 239, y: 225, quantityMobs: 6, mobLevels:{min: 170}, name: 'Deep Dungeon Larva / Deep Dungeon Skeleton Warrior' },
        { x: 240, y: 198, quantityMobs: 5, mobLevels:{min: 170}, name: 'Deep Dungeon Larva / Deep Dungeon Skeleton Warrior' },
      ]
    },
    {
      imageSrc: hsWind,
      coordinates: [
      ]
    },
    {
      imageSrc: spotWater,
      coordinates: [
        { x: 126, y: 168, quantityMobs: 4, mobLevels:{min: 170}, name: 'Deep Dungeon Larva / Deep Dungeon Skeleton Warrior' },
        { x: 112, y: 156, quantityMobs: 3, mobLevels:{min: 170}, name: 'Deep Dungeon Larva / Deep Dungeon Skeleton Warrior' },
      ]
    },
    {
      imageSrc: subWater,
      coordinates: [
        { x: 37, y: 237, quantityMobs: 5, mobLevels:{min: 170}, name: 'Deep Dungeon Larva / Deep Dungeon Skeleton Warrior' },
        { x: 68, y: 241, quantityMobs: 5, mobLevels:{min: 170}, name: 'Deep Dungeon Larva / Deep Dungeon Skeleton Warrior' },
        { x: 81, y: 215, quantityMobs: 6, mobLevels:{min: 170}, name: 'Deep Dungeon Larva / Deep Dungeon Skeleton Warrior' },
        { x: 99, y: 170, quantityMobs: 5, mobLevels:{min: 170}, name: 'Deep Dungeon Larva / Deep Dungeon Skeleton Warrior' },
      ]
    },
    {
      imageSrc: hsWater,
      coordinates: [
        { x: 22, y: 230, quantityMobs: 7, mobLevels:{min: 170}, name: 'Deep Dungeon Larva / Deep Dungeon Skeleton Warrior' },
      ]
    },
    {
      imageSrc: spotDark,
      coordinates: [
        { x: 231, y: 141, quantityMobs: 4, mobLevels:{min: 170}, name: 'Deep Dungeon Larva / Deep Dungeon Skeleton Warrior' },
      ]
    },
    {
      imageSrc: subDark,
      coordinates: [
        { x: 186, y: 129, quantityMobs: 5, mobLevels:{min: 170}, name: 'Deep Dungeon Larva / Deep Dungeon Skeleton Warrior' },
        { x: 207, y: 129, quantityMobs: 5, mobLevels:{min: 170}, name: 'Deep Dungeon Larva / Deep Dungeon Skeleton Warrior' },
        { x: 240, y: 179, quantityMobs: 6, mobLevels:{min: 170}, name: 'Deep Dungeon Larva / Deep Dungeon Skeleton Warrior' },
        { x: 239, y: 165, quantityMobs: 6, mobLevels:{min: 170}, name: 'Deep Dungeon Larva / Deep Dungeon Skeleton Warrior' },
        { x: 246, y: 146, quantityMobs: 5, mobLevels:{min: 170}, name: 'Deep Dungeon Larva / Deep Dungeon Skeleton Warrior' },
      ]
    },
    {
      imageSrc: hsDark,
      coordinates: [
      ]
    },
    {
      imageSrc: spotEarth,
      coordinates: [
        { x: 140, y: 243, quantityMobs: 4, mobLevels:{min: 170}, name: 'Deep Dungeon Larva / Deep Dungeon Skeleton Warrior' },
        { x: 148, y: 227, quantityMobs: 4, mobLevels:{min: 170}, name: 'Deep Dungeon Larva / Deep Dungeon Skeleton Warrior' },
      ]
    },
    {
      imageSrc: subEarth,
      coordinates: [
        { x: 118, y: 219, quantityMobs: 5, mobLevels:{min: 170}, name: 'Deep Dungeon Larva / Deep Dungeon Skeleton Warrior' },
        { x: 163, y: 240, quantityMobs: 6, mobLevels:{min: 170}, name: 'Deep Dungeon Larva / Deep Dungeon Skeleton Warrior' },
        { x: 125, y: 187, quantityMobs: 5, mobLevels:{min: 170}, name: 'Deep Dungeon Larva / Deep Dungeon Skeleton Warrior' },
        { x: 152, y: 167, quantityMobs: 5, mobLevels:{min: 170}, name: 'Deep Dungeon Larva / Deep Dungeon Skeleton Warrior' },
      ]
    },
    {
      imageSrc: hsEarth,
      coordinates: [
        { x: 96, y: 240, quantityMobs: 7, mobLevels:{min: 170}, name: 'Deep Dungeon Larva / Deep Dungeon Skeleton Warrior' },
      ]
    },
  ],
}

const DeepDungeon1 = () => <MuMap {...mapConfigs} />

export default DeepDungeon1