import React from "react"

import MuMap from '.';
import imageMap from '../../images/Mu/Data/Interface/GFx/NaviMap/Navimap02.png';

import hsNormal from '../../images/markers/hs-normal.png';
import subNormal from '../../images/markers/sub-normal.png';
import spotNormal from '../../images/markers/spot-normal.png';

const mapConfigs = {
  name: "Dungeon",
  url: imageMap,
  markers: [
    {
      imageSrc: spotNormal,
      coordinates: [
        // Dungeon 1
        { x: 51, y: 209, quantityMobs: 3, mobLevels:{min: 25, max: 28} },
        { x: 79, y: 152, quantityMobs: 3, mobLevels:{min: 25, max: 32} },
        { x: 192, y: 223, quantityMobs: 3, mobLevels:{min: 25} },
        { x: 239, y: 173, quantityMobs: 3, mobLevels:{min: 28, max: 34} },
        // Dungeon 2
        { x: 120, y: 46, quantityMobs: 3, mobLevels:{min: 46} },
        { x: 143, y: 92, quantityMobs: 3, mobLevels:{min: 38, max: 40} },
        { x: 161, y: 120, quantityMobs: 4, mobLevels:{min: 34, max: 38} },
        { x: 178, y: 121, quantityMobs: 3, mobLevels:{min: 34, max: 38} },
        { x: 234, y: 59, quantityMobs: 3, mobLevels:{min: 42, max: 44} },
        //Dungeon 3
        { x: 11, y: 61, quantityMobs: 3, mobLevels:{min: 44, max: 48} },
        { x: 11, y: 115, quantityMobs: 3, mobLevels:{min: 44, max: 48} },
        { x: 26, y: 65, quantityMobs: 3, mobLevels:{min: 44, max: 48} },
        { x: 26, y: 81, quantityMobs: 3, mobLevels:{min: 44, max: 48} },
        { x: 39, y: 59, quantityMobs: 3, mobLevels:{min: 44, max: 48} },
        { x: 40, y: 115, quantityMobs: 3, mobLevels:{min: 44, max: 48} },
      ]
    },
    {
      imageSrc: subNormal,
      coordinates: [
        // Dungeon 1
        { x: 49, y: 182, quantityMobs: 5, mobLevels:{min: 32} },
        // Dungeon 2
        { x: 115, y: 76, quantityMobs: 4, mobLevels:{min: 38, max: 40} },
      ]
    },
    {
      imageSrc: hsNormal,
      coordinates: [
        // Dungeon 2
        { x: 242, y: 15, quantityMobs: 5, mobLevels:{min: 32, max: 44} },
      ]
    },
  ],
}

const Dungeon = () => <MuMap {...mapConfigs} />

export default Dungeon