import React from "react"

import MuMap from '.';
import imageMap from '../../images/Mu/Data/Interface/GFx/NaviMap/Navimap58.png';

import subNormal from '../../images/markers/sub-normal.png';
import spotNormal from '../../images/markers/spot-normal.png';

const mapConfigs = {
  name: "Raklion",
  url: imageMap,
  markers: [
    {
      imageSrc: spotNormal,
      coordinates: [
        { x: 31, y: 196, quantityMobs: 3, mobLevels:{min: 143, max: 148}, name: 'The Dark Giant / The Dark Knight Iron / Dark Coolutin' },
        { x: 33, y: 116, quantityMobs: 3, mobLevels:{min: 145, max: 148}, name: 'The Dark Knight Iron / Dark Coolutin' },
        { x: 66, y: 140, quantityMobs: 3, mobLevels:{min: 143, max: 148}, name: 'The Dark Giant / The Dark Knight Iron' },
        { x: 69, y: 96, quantityMobs: 3, mobLevels:{min: 143, max: 148}, name: 'The Dark Giant / The Dark Knight Iron / Dark Coolutin' },
        { x: 105, y: 105, quantityMobs: 3, mobLevels:{min: 140}, name: 'Dark Mammonth' },
        { x: 64, y: 35, quantityMobs: 3, mobLevels:{min: 145, max: 148}, name: 'The Dark Knight Iron / Dark Coolutin' },
        { x: 96, y: 27, quantityMobs: 3, mobLevels:{min: 143, max: 148}, name: 'The Dark Giant / The Dark Knight Iron' },
      ]
    },
    {
      imageSrc: subNormal,
      coordinates: [
        { x: 33, y: 223, quantityMobs: 4, mobLevels:{min: 143, max: 148}, name: 'The Dark Giant / The Dark Knight Iron / Dark Coolutin' },
        { x: 170, y: 212, quantityMobs: 4, mobLevels:{min: 112, max: 122}, name: 'Giant Mammonth / Ice Giant' },
        { x: 124, y: 43, quantityMobs: 4, mobLevels:{min: 143, max: 148}, name: 'The Dark Giant / The Dark Knight Iron / Dark Coolutin' },
        { x: 208, y: 31, quantityMobs: 4, mobLevels:{min: 132, max: 142}, name: 'Iron Knight / Coolutin' },
      ]
    },
  ],
}

const Raklion = () => <MuMap {...mapConfigs} />

export default Raklion