import React from "react"

import MuMap from '.';
import imageMap from '../../images/Mu/Data/Interface/GFx/NaviMap/Navimap113.png';

import hsDark from '../../images/markers/hs-dark.png';
import hsFire from '../../images/markers/hs-fire.png';
import hsWater from '../../images/markers/hs-water.png';
import hsWind from '../../images/markers/hs-wind.png';
import hsEarth from '../../images/markers/hs-earth.png';

import subDark from '../../images/markers/sub-dark.png';
import subEarth from '../../images/markers/sub-earth.png';
import subFire from '../../images/markers/sub-fire.png';
import subWater from '../../images/markers/sub-water.png';
import subWind from '../../images/markers/sub-wind.png';

import spotDark from '../../images/markers/spot-dark.png';
import spotEarth from '../../images/markers/spot-earth.png';
import spotFire from '../../images/markers/spot-fire.png';
import spotWater from '../../images/markers/spot-water.png';
import spotWind from '../../images/markers/spot-wind.png';

const mapConfigs = {
  name: "Ferea",
  url: imageMap,
  markers: [
    {
      imageSrc: spotFire,
      coordinates: [
        { x: 234, y: 198, quantityMobs: 3, mobLevels:{min: 118, max: 120}, name: 'Ferea Knight / Ferea Archer / Ferea Fighter' },
        { x: 235, y: 215, quantityMobs: 4, mobLevels:{min: 118, max: 120}, name: 'Ferea Knight / Ferea Archer / Ferea Fighter' },
        { x: 222, y: 217, quantityMobs: 4, mobLevels:{min: 118, max: 120}, name: 'Ferea Knight / Ferea Archer / Ferea Fighter' },
        { x: 204, y: 223, quantityMobs: 4, mobLevels:{min: 118, max: 120}, name: 'Ferea Knight / Ferea Archer / Ferea Fighter' },
      ]
    },
    {
      imageSrc: subFire,
      coordinates: [
        { x: 142, y: 211, quantityMobs: 6, mobLevels:{min: 118, max: 120}, name: 'Ferea Knight / Ferea Archer / Ferea Fighter' },
        { x: 157, y: 170, quantityMobs: 5, mobLevels:{min: 118, max: 120}, name: 'Ferea Knight / Ferea Archer / Ferea Fighter' },
        { x: 165, y: 154, quantityMobs: 6, mobLevels:{min: 118, max: 120}, name: 'Ferea Knight / Ferea Archer / Ferea Fighter' },
      ]
    },
    {
      imageSrc: hsFire,
      coordinates: [
        { x: 185, y: 218, quantityMobs: 8, mobLevels:{min: 118, max: 120}, name: 'Ferea Knight / Ferea Archer / Ferea Fighter' },
        { x: 172, y: 193, quantityMobs: 8, mobLevels:{min: 118, max: 120}, name: 'Ferea Knight / Ferea Archer / Ferea Fighter' },
      ]
    },
    {
      imageSrc: spotWind,
      coordinates: [
        { x: 83, y: 164, quantityMobs: 4, mobLevels:{min: 118, max: 120}, name: 'Ferea Knight / Ferea Archer / Ferea Fighter' },
        { x: 98, y: 168, quantityMobs: 3, mobLevels:{min: 118, max: 120}, name: 'Ferea Knight / Ferea Archer / Ferea Fighter' },
        { x: 89, y: 89, quantityMobs: 3, mobLevels:{min: 118, max: 120}, name: 'Ferea Knight / Ferea Archer / Ferea Fighter' },
        { x: 116, y: 107, quantityMobs: 4, mobLevels:{min: 118, max: 120}, name: 'Ferea Knight / Ferea Archer / Ferea Fighter' },
        { x: 127, y: 71, quantityMobs: 3, mobLevels:{min: 118, max: 120}, name: 'Ferea Knight / Ferea Archer / Ferea Fighter' },
        { x: 157, y: 71, quantityMobs: 4, mobLevels:{min: 118, max: 120}, name: 'Ferea Knight / Ferea Archer / Ferea Fighter' },
        { x: 196, y: 100, quantityMobs: 4, mobLevels:{min: 118, max: 120}, name: 'Ferea Knight / Ferea Archer / Ferea Fighter' },
      ]
    },
    {
      imageSrc: subWind,
      coordinates: [
        { x: 120, y: 141, quantityMobs: 5, mobLevels:{min: 118, max: 120}, name: 'Ferea Knight / Ferea Archer / Ferea Fighter' },
        { x: 166, y: 137, quantityMobs: 6, mobLevels:{min: 118, max: 120}, name: 'Ferea Knight / Ferea Archer / Ferea Fighter' },
        { x: 205, y: 114, quantityMobs: 5, mobLevels:{min: 118, max: 120}, name: 'Ferea Knight / Ferea Archer / Ferea Fighter' },
      ]
    },
    {
      imageSrc: hsWind,
      coordinates: [
        { x: 142, y: 140, quantityMobs: 8, mobLevels:{min: 118, max: 120}, name: 'Ferea Knight / Ferea Archer / Ferea Fighter' },
        { x: 185, y: 72, quantityMobs: 8, mobLevels:{min: 118, max: 120}, name: 'Ferea Knight / Ferea Archer / Ferea Fighter' },
      ]
    },
    {
      imageSrc: spotWater,
      coordinates: [
        { x: 110, y: 231, quantityMobs: 3, mobLevels:{min: 118, max: 120}, name: 'Ferea Knight / Ferea Archer / Ferea Fighter' },
        { x: 125, y: 215, quantityMobs: 4, mobLevels:{min: 118, max: 120}, name: 'Ferea Knight / Ferea Archer / Ferea Fighter' },
        { x: 62, y: 237, quantityMobs: 3, mobLevels:{min: 118, max: 120}, name: 'Ferea Knight / Ferea Archer / Ferea Fighter' },
        { x: 33, y: 241, quantityMobs: 4, mobLevels:{min: 118, max: 120}, name: 'Ferea Knight / Ferea Archer / Ferea Fighter' },
        { x: 79, y: 190, quantityMobs: 3, mobLevels:{min: 118, max: 120}, name: 'Ferea Knight / Ferea Archer / Ferea Fighter' },
        { x: 37, y: 216, quantityMobs: 3, mobLevels:{min: 118, max: 120}, name: 'Ferea Knight / Ferea Archer / Ferea Fighter' },
        { x: 18, y: 164, quantityMobs: 3, mobLevels:{min: 118, max: 120}, name: 'Ferea Knight / Ferea Archer / Ferea Fighter' },
      ]
    },
    {
      imageSrc: subWater,
      coordinates: [
        { x: 61, y: 169, quantityMobs: 5, mobLevels:{min: 118, max: 120}, name: 'Ferea Knight / Ferea Archer / Ferea Fighter' },
        { x: 17, y: 202, quantityMobs: 6, mobLevels:{min: 118, max: 120}, name: 'Ferea Knight / Ferea Archer / Ferea Fighter' },
      ]
    },
    {
      imageSrc: hsWater,
      coordinates: [
        { x: 15, y: 236, quantityMobs: 8, mobLevels:{min: 118, max: 120}, name: 'Ferea Knight / Ferea Archer / Ferea Fighter' },
        { x: 31, y: 183, quantityMobs: 8, mobLevels:{min: 118, max: 120}, name: 'Ferea Knight / Ferea Archer / Ferea Fighter' },
      ]
    },
    {
      imageSrc: hsDark,
      coordinates: [
        { x: 25, y: 54, quantityMobs: 8, mobLevels:{min: 118, max: 120}, name: 'Ferea Knight / Ferea Archer / Ferea Fighter' },
        { x: 119, y: 18, quantityMobs: 8, mobLevels:{min: 118, max: 120}, name: 'Ferea Knight / Ferea Archer / Ferea Fighter' },
      ]
    },
    {
      imageSrc: subDark,
      coordinates: [
        { x: 70, y: 70, quantityMobs: 6, mobLevels:{min: 118, max: 120}, name: 'Ferea Knight / Ferea Archer / Ferea Fighter' },
        { x: 97, y: 43, quantityMobs: 5, mobLevels:{min: 118, max: 120}, name: 'Ferea Knight / Ferea Archer / Ferea Fighter' },
        { x: 80, y: 47, quantityMobs: 5, mobLevels:{min: 118, max: 120}, name: 'Ferea Knight / Ferea Archer / Ferea Fighter' },
      ]
    },
    {
      imageSrc: spotDark,
      coordinates: [
        { x: 47, y: 56, quantityMobs: 4, mobLevels:{min: 118, max: 120}, name: 'Ferea Knight / Ferea Archer / Ferea Fighter' },
        { x: 60, y: 54, quantityMobs: 3, mobLevels:{min: 118, max: 120}, name: 'Ferea Knight / Ferea Archer / Ferea Fighter' },
        { x: 83, y: 13, quantityMobs: 3, mobLevels:{min: 118, max: 120}, name: 'Ferea Knight / Ferea Archer / Ferea Fighter' },
        { x: 47, y: 34, quantityMobs: 4, mobLevels:{min: 118, max: 120}, name: 'Ferea Knight / Ferea Archer / Ferea Fighter' },
        { x: 37, y: 15, quantityMobs: 3, mobLevels:{min: 118, max: 120}, name: 'Ferea Knight / Ferea Archer / Ferea Fighter' },
      ]
    },
    {
      imageSrc: spotEarth,
      coordinates: [
        { x: 234, y: 106, quantityMobs: 3, mobLevels:{min: 118, max: 120}, name: 'Ferea Knight / Ferea Archer / Ferea Fighter' },
        { x: 240, y: 51, quantityMobs: 3, mobLevels:{min: 118, max: 120}, name: 'Ferea Knight / Ferea Archer / Ferea Fighter' },
        { x: 216, y: 41, quantityMobs: 4, mobLevels:{min: 118, max: 120}, name: 'Ferea Knight / Ferea Archer / Ferea Fighter' },
        { x: 241, y: 35, quantityMobs: 4, mobLevels:{min: 118, max: 120}, name: 'Ferea Knight / Ferea Archer / Ferea Fighter' },
        { x: 212, y: 11, quantityMobs: 4, mobLevels:{min: 118, max: 120}, name: 'Ferea Knight / Ferea Archer / Ferea Fighter' },
        { x: 172, y: 13, quantityMobs: 3, mobLevels:{min: 118, max: 120}, name: 'Ferea Knight / Ferea Archer / Ferea Fighter' },
        { x: 157, y: 14, quantityMobs: 4, mobLevels:{min: 118, max: 120}, name: 'Ferea Knight / Ferea Archer / Ferea Fighter' },
      ]
    },
    {
      imageSrc: subEarth,
      coordinates: [
        { x: 229, y: 17, quantityMobs: 5, mobLevels:{min: 118, max: 120}, name: 'Ferea Knight / Ferea Archer / Ferea Fighter' },
        { x: 196, y: 30, quantityMobs: 6, mobLevels:{min: 118, max: 120}, name: 'Ferea Knight / Ferea Archer / Ferea Fighter' },
      ]
    },
    {
      imageSrc: hsEarth,
      coordinates: [
        { x: 215, y: 59, quantityMobs: 8, mobLevels:{min: 118, max: 120}, name: 'Ferea Knight / Ferea Archer / Ferea Fighter' },
        { x: 143, y: 17, quantityMobs: 8, mobLevels:{min: 118, max: 120}, name: 'Ferea Knight / Ferea Archer / Ferea Fighter' },
      ]
    },
  ],
}

const Ferea = () => <MuMap {...mapConfigs} />

export default Ferea