import React from 'react';
import { CssBaseline, Stack, Container } from '@mui/material';
import Menu from './Menu';
import MapRoutes from './MapRoutes';

const Maps = () => {
  return (
    <>
      <CssBaseline />
      <Container style={{ padding:0 }}>
        <Stack spacing={2} mt={2}>
          <Menu />
          <MapRoutes />
        </Stack>
      </Container>
    </>
  );
}

export default Maps