import React from "react"

import MuMap from '.';
import imageMap from '../../images/Mu/Data/Interface/GFx/NaviMap/Navimap137.png';

import hsDark from '../../images/markers/hs-dark.png';
import hsFire from '../../images/markers/hs-fire.png';
import hsWater from '../../images/markers/hs-water.png';
import hsWind from '../../images/markers/hs-wind.png';
import hsEarth from '../../images/markers/hs-earth.png';

import subDark from '../../images/markers/sub-dark.png';
import subEarth from '../../images/markers/sub-earth.png';
import subFire from '../../images/markers/sub-fire.png';
import subWater from '../../images/markers/sub-water.png';
import subWind from '../../images/markers/sub-wind.png';

import spotDark from '../../images/markers/spot-dark.png';
import spotEarth from '../../images/markers/spot-earth.png';
import spotFire from '../../images/markers/spot-fire.png';
import spotWater from '../../images/markers/spot-water.png';
import spotWind from '../../images/markers/spot-wind.png';


const mapConfigs = {
  name: "Burning Kethotum",
  url: imageMap,
  markers: [
    {
      imageSrc: spotWater,
      coordinates: [
        { x: 68, y: 62, quantityMobs: 5, mobLevels:{min: 0}, name: 'Crimson Lycan / Crimson Harpy / Crimson Minos' },
        { x: 43, y: 59, quantityMobs: 5, mobLevels:{min: 0}, name: 'Crimson Lycan / Crimson Harpy / Crimson Minos' },
        { x: 99, y: 9, quantityMobs: 5, mobLevels:{min: 0}, name: 'Crimson Lycan / Crimson Harpy / Crimson Minos' },
        { x: 79, y: 33, quantityMobs: 5, mobLevels:{min: 0}, name: 'Crimson Lycan / Crimson Harpy / Crimson Minos' },
      ]
    },
    {
      imageSrc: subWater,
      coordinates: [
        { x: 61, y: 17, quantityMobs: 7, mobLevels:{min: 0}, name: 'Crimson Lycan / Crimson Harpy / Crimson Minos' },
      ]
    },
    {
      imageSrc: hsWater,
      coordinates: [
        { x: 39, y: 11, quantityMobs: 9, mobLevels:{min: 0}, name: 'Crimson Lycan / Crimson Harpy / Crimson Minos' },
        { x: 14, y: 67, quantityMobs: 9, mobLevels:{min: 0}, name: 'Crimson Lycan / Crimson Harpy / Crimson Minos' },
        // Elite water
        { x: 43, y: 80, quantityMobs: 10, mobLevels:{min: 0}, name: 'Crimson Lycan / Crimson Harpy / Crimson Minos' },
      ]
    },
    {
      imageSrc: spotFire,
      coordinates: [
        { x: 142, y: 130, quantityMobs: 5, mobLevels:{min: 0}, name: 'Crimson Lycan / Crimson Harpy / Crimson Minos' },
        { x: 116, y: 130, quantityMobs: 5, mobLevels:{min: 0}, name: 'Crimson Lycan / Crimson Harpy / Crimson Minos' },
        { x: 148, y: 95, quantityMobs: 5, mobLevels:{min: 0}, name: 'Crimson Lycan / Crimson Harpy / Crimson Minos' },
        { x: 223, y: 112, quantityMobs: 5, mobLevels:{min: 0}, name: 'Crimson Lycan / Crimson Harpy / Crimson Minos' },
      ]
    },
    {
      imageSrc: subFire,
      coordinates: [
        { x: 197, y: 105, quantityMobs: 7, mobLevels:{min: 0}, name: 'Crimson Lycan / Crimson Harpy / Crimson Minos' },
      ]
    },
    {
      imageSrc: hsFire,
      coordinates: [
        { x: 219, y: 190, quantityMobs: 9, mobLevels:{min: 0}, name: 'Crimson Lycan / Crimson Harpy / Crimson Minos' },
        { x: 233, y: 144, quantityMobs: 9, mobLevels:{min: 0}, name: 'Crimson Lycan / Crimson Harpy / Crimson Minos' },
        // Elite Fire
        { x: 165, y: 125, quantityMobs: 10, mobLevels:{min: 0}, name: 'Crimson Lycan / Crimson Harpy / Crimson Minos' },
      ]
    },
    {
      imageSrc: spotWind,
      coordinates: [
        { x: 107, y: 94, quantityMobs: 5, mobLevels:{min: 0}, name: 'Crimson Lycan / Crimson Harpy / Crimson Minos' },
        { x: 83, y: 131, quantityMobs: 5, mobLevels:{min: 0}, name: 'Crimson Lycan / Crimson Harpy / Crimson Minos' },
        { x: 66, y: 129, quantityMobs: 5, mobLevels:{min: 0}, name: 'Crimson Lycan / Crimson Harpy / Crimson Minos' },
        { x: 34, y: 165, quantityMobs: 5, mobLevels:{min: 0}, name: 'Crimson Lycan / Crimson Harpy / Crimson Minos' },
      ]
    },
    {
      imageSrc: subWind,
      coordinates: [
        { x: 46, y: 111, quantityMobs: 7, mobLevels:{min: 0}, name: 'Crimson Lycan / Crimson Harpy / Crimson Minos' },
      ]
    },
    {
      imageSrc: hsWind,
      coordinates: [
        { x: 17, y: 133, quantityMobs: 9, mobLevels:{min: 0}, name: 'Crimson Lycan / Crimson Harpy / Crimson Minos' },
        { x: 14, y: 104, quantityMobs: 9, mobLevels:{min: 0}, name: 'Crimson Lycan / Crimson Harpy / Crimson Minos' },
        // Elite wind
        { x: 81, y: 103, quantityMobs: 10, mobLevels:{min: 0}, name: 'Crimson Lycan / Crimson Harpy / Crimson Minos' },
      ]
    },
    {
      imageSrc: spotDark,
      coordinates: [
        { x: 126, y: 169, quantityMobs: 5, mobLevels:{min: 0}, name: 'Crimson Lycan / Crimson Harpy / Crimson Minos' },
        { x: 93, y: 193, quantityMobs: 5, mobLevels:{min: 0}, name: 'Crimson Lycan / Crimson Harpy / Crimson Minos' },
        { x: 121, y: 211, quantityMobs: 5, mobLevels:{min: 0}, name: 'Crimson Lycan / Crimson Harpy / Crimson Minos' },
        { x: 148, y: 213, quantityMobs: 5, mobLevels:{min: 0}, name: 'Crimson Lycan / Crimson Harpy / Crimson Minos' },
      ]
    },
    {
      imageSrc: subDark,
      coordinates: [
        { x: 56, y: 238, quantityMobs: 7, mobLevels:{min: 0}, name: 'Crimson Lycan / Crimson Harpy / Crimson Minos' },
      ]
    },
    {
      imageSrc: hsDark,
      coordinates: [
        { x: 18, y: 211, quantityMobs: 9, mobLevels:{min: 0}, name: 'Crimson Lycan / Crimson Harpy / Crimson Minos' },
        { x: 60, y: 207, quantityMobs: 9, mobLevels:{min: 0}, name: 'Crimson Lycan / Crimson Harpy / Crimson Minos' },
        // Elite Dark
        { x: 150, y: 179, quantityMobs: 10, mobLevels:{min: 0}, name: 'Crimson Lycan / Crimson Harpy / Crimson Minos' },
      ]
    },
    {
      imageSrc: spotEarth,
      coordinates: [
        { x: 188, y: 71, quantityMobs: 5, mobLevels:{min: 0}, name: 'Crimson Lycan / Crimson Harpy / Crimson Minos' },
        { x: 166, y: 58, quantityMobs: 5, mobLevels:{min: 0}, name: 'Crimson Lycan / Crimson Harpy / Crimson Minos' },
        { x: 164, y: 15, quantityMobs: 5, mobLevels:{min: 0}, name: 'Crimson Lycan / Crimson Harpy / Crimson Minos' },
        { x: 189, y: 16, quantityMobs: 5, mobLevels:{min: 0}, name: 'Crimson Lycan / Crimson Harpy / Crimson Minos' },
      ]
    },
    {
      imageSrc: subEarth,
      coordinates: [
        { x: 218, y: 40, quantityMobs: 7, mobLevels:{min: 0}, name: 'Crimson Lycan / Crimson Harpy / Crimson Minos' },
      ]
    },
    {
      imageSrc: hsEarth,
      coordinates: [
        { x: 237, y: 16, quantityMobs: 9, mobLevels:{min: 0}, name: 'Crimson Lycan / Crimson Harpy / Crimson Minos' },
        { x: 232, y: 62, quantityMobs: 9, mobLevels:{min: 0}, name: 'Crimson Lycan / Crimson Harpy / Crimson Minos' },
        // Elite Earth
        { x: 212, y: 66, quantityMobs: 10, mobLevels:{min: 0}, name: 'Crimson Lycan / Crimson Harpy / Crimson Minos' },
      ]
    },
  ],
}

const BurningKethotum = () => <MuMap {...mapConfigs} />

export default BurningKethotum