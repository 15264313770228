import React from "react"

import MuMap from '.';
import imageMap from '../../images/Mu/Data/Interface/GFx/NaviMap/Navimap57.png';

import hsNormal from '../../images/markers/hs-normal.png';
import subNormal from '../../images/markers/sub-normal.png';
import spotNormal from '../../images/markers/spot-normal.png';

const markersRight = [
  {
    imageSrc: spotNormal,
    coordinates: [
      { x: 203, y: 200, quantityMobs: 3, mobLevels:{min: 95}, name: "Sapi-Unus" },
      { x: 223, y: 214, quantityMobs: 3, mobLevels:{min: 95, max:96}, name: "Sapi-Unus / Sapi-Duo" },
    ]
  },
  {
    imageSrc: subNormal,
    coordinates: [
      { x: 175, y: 218, quantityMobs: 4, mobLevels:{min: 95, max:97}, name: "Sapi-Unus / Thunder Napin" },
      { x: 234, y: 233, quantityMobs: 4, mobLevels:{min: 97}, name: "Thunder Napin" },
      { x: 192, y: 110, quantityMobs: 4, mobLevels:{min: 135, max:137}, name: "Napin Ice / Shadow Master" },
    ]
  },
  {
    imageSrc: hsNormal,
    coordinates: [
      { x: 232, y: 199, quantityMobs: 6, mobLevels:{min: 95, max:96}, name: "Sapi-Unus / Sapi-Duo" },
      { x: 217, y: 145, quantityMobs: 7, mobLevels:{min: 131, max:135}, name: "Sapi Queen / Napin Ice" },
      { x: 178, y: 138, quantityMobs: 5, mobLevels:{min: 135, max:137}, name: "Napin Ice / Shadow Master" },
      { x: 179, y: 125, quantityMobs: 5, mobLevels:{min: 135, max:137}, name: "Napin Ice / Shadow Master" },
    ]
  },
];

const markersBotton = [
  {
    imageSrc: subNormal,
    coordinates: [
      { x: 133, y: 31, quantityMobs: 4, mobLevels:{min: 135, max:137}, name: "Napin Ice / Shadow Master" },
      { x: 156, y: 15, quantityMobs: 4, mobLevels:{min: 131, max:137}, name: "Sapi Queen / Napin Ice / Shadow Master" },
      { x: 165, y: 27, quantityMobs: 4, mobLevels:{min: 131, max:135}, name: "Sapi Queen / Napin Ice" },
      { x: 223, y: 26, quantityMobs: 4, mobLevels:{min: 98, max:106}, name: "Shadow Pawn / Shadow Knight / Ghost Napin" },
    ]
  },
  {
    imageSrc: hsNormal,
    coordinates: [
      { x: 155, y: 39, quantityMobs: 6, mobLevels:{min: 131, max:137}, name: "Sapi Queen / Napin Ice / Shadow Master" },
      { x: 233, y: 12, quantityMobs: 6, mobLevels:{min: 98, max:100}, name: "Shadow Pawn / Shadow Knight" },
      { x: 209, y: 32, quantityMobs: 5, mobLevels:{min: 98, max:106}, name: "Shadow Pawn / Shadow Knight / Ghost Napin" },
      { x: 220, y: 83, quantityMobs: 6, mobLevels:{min: 98, max:106}, name: "Shadow Pawn / Shadow Knight / Ghost Napin" },
      { x: 233, y: 82, quantityMobs: 6, mobLevels:{min: 98, max:106}, name: "Shadow Pawn / Shadow Knight / Ghost Napin" },
      { x: 233, y: 71, quantityMobs: 5, mobLevels:{min: 98, max:106}, name: "Shadow Pawn / Shadow Knight / Ghost Napin" },
    ]
  },
];

const markersLeft = [
  {
    imageSrc: subNormal,
    coordinates: [
      { x: 12, y: 48, quantityMobs: 4, mobLevels:{min: 100, max:102}, name: "Sapi-Tres / Shadow Knight" },
    ]
  },
  {
    imageSrc: hsNormal,
    coordinates: [
      { x: 19, y: 124, quantityMobs: 6, mobLevels:{min: 131, max:135}, name: "Sapi Queen / Napin Ice" },
      { x: 40, y: 117, quantityMobs: 6, mobLevels:{min: 131, max:137}, name: "Sapi Queen / Napin Ice / Shadow Master" },
      { x: 55, y: 108, quantityMobs: 5, mobLevels:{min: 131, max:137}, name: "Sapi Queen / Napin Ice / Shadow Master" },
      { x: 39, y: 45, quantityMobs: 5, mobLevels:{min: 100, max:102}, name: "Sapi-Tres / Shadow Knight" },
      { x: 19, y: 12, quantityMobs: 5, mobLevels:{min: 100, max:104}, name: "Shadow Knight / Shadow Look" },
      { x: 55, y: 20, quantityMobs: 5, mobLevels:{min: 100, max:104}, name: "Sapi-Tres / Shadow Knight / Shadow Look" },
    ]
  },
];

const markersTop = [
  {
    imageSrc: spotNormal,
    coordinates: [
      { x: 32, y: 232, quantityMobs: 3, mobLevels:{min: 106, max:107}, name: "Ghost Napin / Blaze Napin" },
    ]
  },
  {
    imageSrc: subNormal,
    coordinates: [
      { x: 29, y: 183, quantityMobs: 4, mobLevels:{min: 106, max:107}, name: "Ghost Napin / Blaze Napin" },
      { x: 84, y: 221, quantityMobs: 4, mobLevels:{min: 102, max:107}, name: "Sapi-Tres / Ghost Napin / Blaze Napin" },
      { x: 132, y: 169, quantityMobs: 4, mobLevels:{min: 131, max:137}, name: "Sapi Queen / Napin Ice / Shadow Master" },
    ]
  },
  {
    imageSrc: hsNormal,
    coordinates: [
      { x: 22, y: 211, quantityMobs: 5, mobLevels:{min: 107}, name: "Blaze Napin" },
      { x: 108, y: 228, quantityMobs: 6, mobLevels:{min: 131, max:135}, name: "Sapi Queen / Napin Ice" },
      { x: 134, y: 226, quantityMobs: 5, mobLevels:{min: 131, max:137}, name: "Sapi Queen / Napin Ice / Shadow Master" },
      { x: 108, y: 169, quantityMobs: 6, mobLevels:{min: 131, max:135}, name: "Sapi Queen / Napin Ice" },
    ]
  },
];

const mapConfigs = {
  name: "Swamp of Peace",
  url: imageMap,
  markers: [
    ...markersRight,
    ...markersBotton,
    ...markersLeft,
    ...markersTop,
  ],
}

const SwampOfPeace = () => <MuMap {...mapConfigs} />

export default SwampOfPeace