import React, { useState } from 'react';
import { AppBar as MuiAppBar, IconButton, Toolbar, Typography } from '@mui/material';
import { Menu as MenuIcon, MoreVert } from '@mui/icons-material';
import Menu from  './Menu';
import AppLogo from './AppLogo';

const AppBar = ({ title }) => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const openMenu = () => setMenuOpen(true);
  const onCloseMenu = () => setMenuOpen(false);
  const toggleMenu = (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setMenuOpen((state) => !state);
  };

  return (
    <MuiAppBar position="fixed">
      <Toolbar>
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          onClick={openMenu}
        >
          <MenuIcon />
        </IconButton>
        <Menu title={title} isOpen={isMenuOpen} onClose={onCloseMenu} toggle={toggleMenu} />
        <AppLogo sx={{ width: 64, height: 64 }} />
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          {title}
        </Typography>
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          onClick={openMenu}
        >
          <MoreVert />
        </IconButton>
      </Toolbar>
    </MuiAppBar>
  );
}

export default AppBar