import React from 'react';
import { ListItemText, ListItem, ListItemButton, ListItemIcon } from '@mui/material';
import Remove from '@mui/icons-material/Remove';
import { Link } from "react-router-dom";

const MenuItem = ({ name, path, icon=<Remove /> }) => {
  return (
    <ListItem disablePadding>
      <ListItemButton component={Link} to={path}>
        <ListItemIcon>
          {icon}
        </ListItemIcon>
        <ListItemText primary={name} />
      </ListItemButton>
    </ListItem>
  );
}

export default MenuItem